import openSocket from "socket.io-client";
import axios from "axios";
import config from "../config";

const debug = config.debug;

const jwt = localStorage.getItem("immutable-jwt");
let base = debug ? config.debugURL : config.productionURL;

const socket = openSocket(`${base}`);

const getJWT = () => {
  return {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
};

function writeForm(message) {
  socket.emit("write-form", message);
}

function connectWSS() {
  if (jwt && socket.id) {
    localStorage.setItem("iam_sessionId", socket.id);

    axios
      .post(`${base}/user/socket`, { sessionId: socket.id }, getJWT())
      .then((success) => {})
      .catch((err) => {});
  }
}

export { connectWSS, writeForm, socket };
