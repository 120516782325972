import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SelectMenu from "./selectMenu";
import isValidEmail from "is-valid-email";
import BinarySelect from "./binarySelect";
import axios from "axios";
import HelloSign from "hellosign-embedded";
import config from "../config";
import FileUpload from "./fileUpload";
import delay from "delay";
import { countries } from "../geography";
import { funds } from "../funds";
import fileIcon from "../images/fileIcon.svg";
import pdfIcon from "../images/pdfIcon.svg";
import excelIcon from "../images/excelIcon.svg";
import wordIcon from "../images/wordIcon.svg";
import { isMobile } from "react-device-detect";
import { StatefulTooltip } from "baseui/tooltip";
import { getFundHelper } from "../global";
import Notification from "./notification";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled } from "baseui";
import { PhoneInput, SIZE } from "baseui/phone-input";

const engine = new Styletron();

export default function InstitutionApp(props) {
  const {
    location,
    user,
    addedTX,
    portfolio,
    fund,
    scrollTop,
    form,
    setForm,
    canSubmit,
    handleForm,
    fundOverride,
  } = props;

  const history = useHistory();

  const [innerCheck, setInnerCheck] = useState(false);
  const [openFundLinks, setOpenFundLinks] = useState(false);

  const [notificationArray, setNotificationArray] = useState([]);

  const [optionalState, setOptionalState] = useState([]);

  const [fiveYearAccreditation, setFiveYearAccreditation] = useState(undefined);
  const [showApplication, setShowApplication] = useState(false);
  const [showPreviousPage, setShowPreviousPage] = useState(false);

  const [upload2, setUpload2] = useState(false);
  const [file2, setFile2] = useState([]);
  const [fileName2, setFileName2] = useState("");
  const [fileURL2, setFileURL2] = useState("");

  const [uploadObject, setUploadObject] = useState({});
  const [fileNameObject, setFileNameObject] = useState({});
  const [fileObject, setFileObject] = useState({});
  const [fileUrlObject, setFileUrlObject] = useState({});

  const [index, setIndex] = useState(0);

  const [submitting, setSubmit] = useState(false);
  const [loadingEmbeddedURL, setLoadingEmbeddedURL] = useState("");
  const [embeddedURL, setEmbeddedURL] = useState("");
  const client = new HelloSign({ hideHeader: true });

  const [executedSubDocURL, setExecutedSubDocURL] = useState("");

  const [submittingAlt, setSubmitAlt] = useState(false);
  const [error, setError] = useState("");

  const canSubmitAlt =
    form?.["invite-a"] &&
    form?.["invite-b"] &&
    form?.["invite-c"] &&
    form?.["invite-d"] &&
    isValidEmail(form?.["invite-c"]);

  function truncate(input) {
    if (input.length > 5 && isMobile) {
      return input.substring(0, 5) + "...";
    }
    return input;
  }

  const fileUploadJSX = (description, formID, multiple = false) => {
    return (
      <div className="form-grid">
        {form?.[formID] &&
        form?.[formID] !== "" &&
        JSON.parse(form?.[formID])?.url !== "" &&
        !uploadObject[formID] ? (
          <div className="flex flex-col">
            <div className="text-sm font-light">{description}:&nbsp;</div>
            <div className="flex items-center justify-between text-sm mb-3">
              <div className="flex items-center">
                <img
                  src={getFileIcon(
                    form?.[formID]?.name
                      ? JSON.parse(form?.[formID])
                          ?.name.split(".")
                          .pop()
                          .toLowerCase()
                      : ""
                  )}
                  alt={JSON.parse(form?.[formID])?.name}
                  style={{ marginRight: "10px", height: "20px" }}
                />
                <div
                  onClick={() =>
                    window.open(JSON.parse(form?.[formID])?.url, "_blank")
                  }
                  style={{ cursor: "pointer" }}
                  className="hover:underline hover:text-blue-600 text-blue-400"
                >
                  {truncate(JSON.parse(form?.[formID])?.name) ||
                    "Uploaded File"}
                </div>
              </div>
              {showApplication ? (
                <span />
              ) : (
                <div
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    fileObject[formID] = "";
                    uploadObject[formID] = true;
                    setIndex(index + 1);
                  }}
                  className="text-black hover:text-yellow-600"
                >
                  (change)
                </div>
              )}
            </div>
          </div>
        ) : (
          <React.Fragment>
            {form?.[formID] && JSON.parse(form?.[formID])?.url && (
              <div
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  uploadObject[formID] = false;
                  setIndex(index + 1);
                }}
                className="text-red-500 hover:text-red-600 text-sm"
              >
                (cancel)
              </div>
            )}
            <FileUpload
              jwt={jwt}
              label={description}
              required={true}
              multiple={multiple}
              setFile={(e) => {
                fileObject[formID] = e;
                setIndex(index + 1);
              }}
              file={fileObject[formID]}
              setFileName={(e) => {
                fileNameObject[formID] = e;
                setIndex(index + 1);
              }}
              setFileURL={(e) => {
                fileUrlObject[formID] = e;
                const fileObject = { name: fileNameObject[formID], url: e };

                handleForm(formID, JSON.stringify(fileObject));
                setIndex(index + 1);
              }}
              fileURL={fileUrlObject[formID]}
              fileName={fileNameObject[formID]}
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  const getAmlForms = () => {
    switch (form?.["1b"]) {
      case "Other":
      case "Corporation":
        return (
          <div>
            {fileUploadJSX(
              "Certificate of Incorporation or equivalent",
              "privateco-certificateOfIncorporation"
            )}
            {fileUploadJSX(
              "Memorandum and Articles of Association or equivalent",
              "privateco-memorandumAndAoA"
            )}
            {fileUploadJSX(
              "Register of Directors",
              "privateco-registerOfDirectors",
              true
            )}
            {fileUploadJSX(
              "Valid passport, national ID, driver's license or government issued ID with photograph for 2 directors (individual or entity)",
              "privateco-directorID",
              true
            )}
            {fileUploadJSX(
              `Valid passport, national ID, driver's license or government issued ID with photograph for any beneficial owner with more than 25% of the entity's equity`,
              "privateco-beneficialOwners",
              true
            )}
            {fileUploadJSX(
              `Proof of residential address dated within 12 months, such as a certified bank statement/utility bill (no mobile) for any beneficial owner more than 25% of the entity's equity`,
              "privateco-proofBeneficialOwners",
              true
            )}
          </div>
        );
      case "Fund":
        return (
          <div>
            {fileUploadJSX(`Memorandum / PPM or equivalent`, "fund-ppm")}
            {fileUploadJSX(`Certificate of Incorporation`, "fund-coi")}
            {fileUploadJSX(
              `ID"s for all investors holding more than 25% of the shares/units in the fund`,
              "fund-beneficialOwners",
              true
            )}
          </div>
        );
      case "Public Body":
        return (
          <div>
            {fileUploadJSX(
              `Name of the home state authority and the nature of its relationship with the public body`,
              "publicbody-homeStateAuthority"
            )}
            {fileUploadJSX(
              `Details of all persons who own or control more than 25% of the capital (name, residential address and date of birth)`,
              "publicbody-beneficialOwners",
              true
            )}
            {fileUploadJSX(
              `Copy of valid passport, national ID, driver's license or gov"t ID with photos of at least 2 officials`,
              "publicbody-officialID",
              true
            )}
            {fileUploadJSX(
              `Proof of current residential address within the last 12 months (must match address given for subscription) via an original or ceritified copy of a recent bank statement or utility bill (no mobile)`,
              "publicbody-proofOfResidence",
              true
            )}
          </div>
        );
      case "Pension Scheme":
        return (
          <div>
            {fileUploadJSX(
              `Formation Document (e.g. Trust Deed or equivalent)`,
              "pension-formation"
            )}
            {fileUploadJSX(
              `Details of Trustees / Directors / Board Members or equivalent`,
              "pension-trusteeDetails",
              true
            )}
            {fileUploadJSX(
              `Name and address of the Scheme Administrator`,
              "pension-admin"
            )}
          </div>
        );
      case "Private Trust":
        return (
          <div>
            {fileUploadJSX(
              `Copy of the trust deed or equivalent`,
              "privatetrust-trustDeed"
            )}
            {fileUploadJSX(
              `Details of all trustees (name, residential address and date of birth)`,
              "privatetrust-trustees"
            )}
            {fileUploadJSX(
              `ID of 2 trustees or legal entity`,
              "privatetrust-trusteesID",
              true
            )}
            {fileUploadJSX(`ID of the Settler`, "privatetrust-settlerID")}
            {fileUploadJSX(
              `ID of anyone who owns more than 25% of the trust`,
              "privatetrust-trustBeneficialOwners",
              true
            )}
          </div>
        );
      case "Partnership":
        return (
          <div>
            {fileUploadJSX(
              `Certificate of Limited Partnership or equivalent`,
              "partner-LP"
            )}
            {fileUploadJSX(
              `Partnership Agreement or equivalent`,
              "partner-agreement"
            )}
            {fileUploadJSX(
              `Details of all partners (name, residential address, and date of birth)`,
              "partner-partnerList",
              true
            )}
            {fileUploadJSX(
              `ID of the General Partner or 2 Partners`,
              "partner-ID",
              true
            )}
            {fileUploadJSX(
              `ID of all partners who own more than 25% of the partnership capital, profit or voting rights`,
              "partner-beneficialOwners",
              true
            )}
          </div>
        );
      case "Limited Liability Company":
        return (
          <div>
            {fileUploadJSX(
              `Certificate of Formation or equivalent`,
              "llc-formation"
            )}
            {fileUploadJSX(
              `Operating Agreement or equivalent`,
              "llc-operatingAgreement"
            )}
            {fileUploadJSX(`ID for the Managing Member(s)`, "llc-ID")}
            {fileUploadJSX(
              `Details of all Managing Members (name, residential address, and date of birth)`,
              "llc-managerDetails",
              true
            )}
            {fileUploadJSX(
              `ID for all Members receiving more than 25% of share capital, profit or voting rights`,
              "llc-beneficialOwners",
              true
            )}
          </div>
        );
      case "Registered Charity/Foundation":
        return (
          <div>
            {fileUploadJSX(
              `Confirmation on nature and purpose of charity including nature of funding`,
              "charity-overview"
            )}
            {fileUploadJSX(
              `Confirmation of approved charity status from relevant charities register`,
              "charity-register"
            )}
          </div>
        );
      case "School, College or University":
        return (
          <div>
            {fileUploadJSX(`ID for two officials`, "school-official")}
            {fileUploadJSX(
              `Details of main officials (name, residential address and date of birth)`,
              "school-details",
              true
            )}
            {fileUploadJSX(
              `ID of all person who own or control more than 25% of the entity"s share c apital, profit or voting rights`,
              "school-beneficialowners",
              true
            )}
          </div>
        );
      default:
        return <div className="text-sm font-normal">n/a</div>;
    }
  };

  const loadingJSX = (color = "white") => {
    return (
      <svg
        className={`animate-spin h-5 w-5 text-${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  const getFileIcon = (extension) => {
    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "docx":
      case "doc":
        return wordIcon;
      case "xls":
      case "xlsx":
        return excelIcon;
      default:
        return fileIcon;
    }
  };

  const accreditationJSX = () => {
    return (
      <React.Fragment>
        <div className="">
          <div role="group" aria-labelledby="label-email">
            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
              <div className="mt-4 sm:mt-0 sm:col-span-2">
                <div style={{ paddingTop: "50px" }}>
                  <section id="authorized-representatives">
                    <h2
                      style={{
                        fontSize: isMobile ? "20px" : "30px",
                        fontWeight: "700",
                        lineHeight: "1em",
                      }}
                    >
                      Accredited Investor Certification
                    </h2>
                    <div
                      style={{
                        content: "",
                        display: "block",
                        width: "75px",
                        height: "5px",
                        backgroundColor: "#3C81F6",
                        marginTop: "20px",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}
                    />

                    <div className="form-question">
                      Is the entity an an accredited investor?
                    </div>
                    <div className="text-sm py-2 font-normal">
                      Please indicate which of the following categories, if any,
                      apply to the entity:
                    </div>
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            checked={form?.["3a"]}
                            disabled={showApplication}
                            onChange={(e) => {
                              handleForm("3a", e.target.checked);

                              handleForm("3c", false);
                              handleForm("3b", false);
                              handleForm("3d", false);
                            }}
                            id="comments"
                            name="comments"
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            for="comments"
                            className="font-medium text-gray-700"
                          >
                            Sole equity owner is accredited
                          </label>
                        </div>
                      </div>
                      {form?.["3a"] && (
                        <div className="ml-7">
                          <div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  checked={form?.["3h"]}
                                  disabled={showApplication}
                                  onChange={(e) => {
                                    handleForm("3h", e.target.checked);

                                    handleForm("3i", false);
                                  }}
                                  id="candidates"
                                  name="candidates"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  for="candidates"
                                  className="font-medium text-gray-700"
                                >
                                  Owner earned income over US $200K (US $300K
                                  with spouse) in each of the last 2 years and
                                  has a reasonable expectation of earning at
                                  least US $200K (US $300K with spouse) in the
                                  current fiscal year.
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  checked={form?.["3i"]}
                                  disabled={showApplication}
                                  onChange={(e) => {
                                    handleForm("3i", e.target.checked);

                                    handleForm("3h", false);
                                  }}
                                  id="candidates"
                                  name="candidates"
                                  type="radio"
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  for="candidates"
                                  className="font-medium text-gray-700"
                                >
                                  Owner has a net worth (excluding primary
                                  residence) of at least US $1,000,000.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              checked={form?.["3b"]}
                              disabled={showApplication}
                              onChange={(e) => {
                                handleForm("3b", e.target.checked);

                                handleForm("3a", false);
                                handleForm("3c", false);
                                handleForm("3d", false);

                                handleForm("3h", false);
                                handleForm("3i", false);
                              }}
                              id="candidates"
                              name="candidates"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              for="candidates"
                              className="font-medium text-gray-700"
                            >
                              All equity owners are accredited investors
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              checked={form?.["3c"]}
                              disabled={showApplication}
                              onChange={(e) => {
                                handleForm("3c", e.target.checked);

                                handleForm("3a", false);
                                handleForm("3b", false);
                                handleForm("3d", false);

                                handleForm("3h", false);
                                handleForm("3i", false);
                              }}
                              id="offers"
                              name="offers"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              for="offers"
                              className="font-medium text-gray-700"
                            >
                              Entity with investments equal to more than US
                              $5,000,000 and not formed for the purpose of
                              investing in this product.
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              checked={form?.["3d"]}
                              disabled={showApplication}
                              onChange={(e) => {
                                handleForm("3d", e.target.checked);

                                handleForm("3a", false);
                                handleForm("3b", false);
                                handleForm("3c", false);

                                handleForm("3h", false);
                                handleForm("3i", false);
                              }}
                              id="offers"
                              name="offers"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              for="offers"
                              className="font-medium text-gray-700"
                            >
                              None of the above
                            </label>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              defaultChecked={form?.["3e"]}
                              onChange={(e) =>
                                handleForm("3e", e.target.checked)
                              }
                              id="offers"
                              name="offers"
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              for="offers"
                              className="font-medium text-gray-700"
                            >
                              I certify that the information provided by me is
                              true and accurate. I understand that I and the
                              entity I represent may be permanently banned from
                              this platform if I provide false information
                              regarding its status as an accredited investor.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {form &&
                    !form?.["3d"] &&
                    form?.["3e"] === true &&
                    (form?.["3a"]
                      ? form?.["3h"] || form?.["3i"]
                      : form?.["3b"] || form?.["3c"]) && (
                      <div style={{ paddingTop: "50px" }}>
                        <section id="authorized-representatives">
                          <h2
                            style={{
                              fontSize: isMobile ? "20px" : "30px",
                              fontWeight: "700",
                              lineHeight: "1em",
                            }}
                          >
                            Accreditation Verification
                          </h2>
                          <div
                            style={{
                              content: "",
                              display: "block",
                              width: "75px",
                              height: "5px",
                              backgroundColor: "#3C81F6",
                              marginTop: "20px",
                              borderRadius: "4px",
                              marginBottom: "20px",
                            }}
                          />
                          <div className="text-xs text-blue-500 mb-5">
                            {form?.["3a"] === true && form?.["3h"]
                              ? `Please upload the last 2 years of income proof (W-2, 1099, 1040) certifying the owners's accredited investor status or a letter from a licensed CPA attorney, investment advisor, or broker written in the last 90 days`
                              : `Please upload your bank statement, brokerage account balance, asset appraisals, or letter from an licensed CPA, attorney, investment advisor, or broker. All must be dated within the last 90 days certifying the entity's accredited investor status`}
                          </div>

                          <div className="form-grid">
                            {form?.["3a"] === true && form?.["3h"] ? (
                              <div>
                                {fileUploadJSX(
                                  `2020 income proof`,
                                  "accreditation-income-1"
                                )}
                                {fileUploadJSX(
                                  `2019 income proof`,
                                  "accreditation-income-2"
                                )}
                              </div>
                            ) : (
                              <div>
                                {fileUploadJSX(
                                  `Upload net worth proof`,
                                  "accreditation-networth"
                                )}
                              </div>
                            )}
                          </div>
                        </section>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const debug = config.debug;

  const jwt = localStorage.getItem("immutable-jwt");
  let base = debug ? config.debugURL : config.productionURL;

  const getJWT = () => {
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  };

  const handleSubmitAlt = async () => {
    try {
      let result = await axios.post(
        `${base}/user/institution/invite`,
        {},
        getJWT()
      );
    } catch (err) {
      console.log("error while submitting alt: ", err.response);

      const error = err.response.data.error.message;
      setError(error);
    }
  };

  // gets embedded URL by selection of fund
  const getEmbeddedURL = async () => {
    try {
      setLoadingEmbeddedURL(true);
      let appUrlSecond = `${base}/user/createSignatureRequest`;

      const response = await axios.post(
        appUrlSecond,
        {
          signerEmail: user.email,
          signerName: user.firstName,
          institutional: user.institutional,
          form: form,
          fundOverride: fundOverride,
          subject: `Your ${form?.["4a"]} Subscription Agreement`,
          message: "Please sign!",
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      setEmbeddedURL(response?.data?.helloSignUrl);
      setLoadingEmbeddedURL(false);

      return response?.data?.helloSignUrl;
    } catch (err) {
      setLoadingEmbeddedURL(false);
      console.log("error while retrieving hellosign: ", err);
    }
  };

  const getSignatureRequest = async (signatureID) => {
    try {
      if (executedSubDocURL === "") {
        let getSignatureRequestURL = `${base}/user/getSignatureRequest`;

        const response = await axios.post(
          getSignatureRequestURL,
          {
            signatureID: signatureID,
            type: "individual",
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const data = response.data;
        setExecutedSubDocURL(data.url);
      }
    } catch (err) {
      console.log("error while getting signature request: ", err);
    }
  };

  useEffect(() => {
    if (form?.["onboardingRequestId"]) {
      getSignatureRequest(form?.["onboardingRequestId"]);
    }
  }, [form]);

  // TODO: make sure to add tx for current app when it is approved by admin
  const getHelloSignJSX = () => {
    return form?.["signedDoc"] !== true ||
      !form?.["onboardingSubDoc"] ||
      (addedTX && portfolio) ? (
      <div
        onClick={async () => {
          let embeddedURL = await getEmbeddedURL();

          client.open(embeddedURL, {
            clientId: "c02728275347a4c89bd0b78e44d8cbfd",
            skipDomainVerification: true,
          });
        }}
        style={{ cursor: "pointer", width: "180px" }}
        className={`inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-green-600 bg-green-500`}
      >
        {loadingEmbeddedURL ? "opening..." : "Sign Subscription"}
      </div>
    ) : (
      <div className="text-sm font-light">
        Executed Document ID: {form?.["onboardingSubDoc"]}&nbsp;
        <span
          className="text-blue-500 hover:underline"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              form?.["executedSubDocURL"] || executedSubDocURL,
              "_blank"
            );
          }}
        >
          (download)
        </span>
      </div>
    );
  };

  const setSignedPost = async () => {
    try {
      let setSignURL = `${base}/user/setSigned`;

      const response = await axios.post(
        setSignURL,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      console.log("signed doc response: ", response.data);
    } catch (err) {
      console.log("error while setting doc as signed: ", err);
    }
  };

  const formatEIN = (text) => {
    if (text) {
      var numbers = text?.replace(/[^0-9]/g, "");
      if (numbers.length == 9) {
        text = numbers.substr(0, 2) + "-" + numbers.substr(2, 7);
      }
    }

    handleForm("1c", text);
  };

  const scrollBottom = () => {
    const divToScrollTo = document.getElementById("applicationBottom");
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView(true);
    }
  };

  // helloSign listener
  client.on("sign", async (data) => {
    // update backend via websocket
    await setSignedPost();
    await delay(1000);

    if (Number(form?.["status"]) === 3) {
      await delay(3000);
      alert("Success! Redirecting you to wiring instructions");
      history.push("/history");
    } else {
      alert("Successfully signed!");
      scrollBottom();
    }
  });

  const addNotification = (subject, text, seconds) => {
    notificationArray.push(
      <Notification subject={subject} text={text} seconds={seconds} />
    );
  };

  const accreditationFilled =
    form?.["3e"] === true && (form?.["3a"] || form?.["3b"] || form?.["3c"]);

  useEffect(() => {
    const optionStates = countries.filter((option, i) => {
      return option.name === form?.["1i"];
    });

    const optionsProp = optionStates?.[0]?.states;

    const optionalState = optionsProp?.length > 0 ? form?.["1j"] : true;
    setOptionalState(optionalState);
  }, [form?.["1i"]]);

  const part1_done =
    form?.["1a"] &&
    form?.["1b"] &&
    form?.["1c"] &&
    form?.["1d"] &&
    form?.["1e"] &&
    form?.["1f"] &&
    form?.["1g"] &&
    form?.["1h"] &&
    form?.["1i"] &&
    optionalState &&
    form?.["1k"];

  const part2_done =
    part1_done &&
    form?.["2a"] &&
    form?.["2c"] &&
    form?.["2d"] &&
    form?.["2e"] &&
    form?.["2f"] &&
    form?.["2g"] &&
    form?.["2h"] &&
    form?.["2i"] &&
    form?.["2j"] &&
    form?.["2k"] &&
    form?.["2l"] &&
    form?.["2m"];

  const part3_done =
    part2_done &&
    ((form?.["3a"] && (form?.["3h"] || form?.["3i"])) ||
      form?.["3b"] ||
      form?.["3c"] ||
      form?.["3d"]) &&
    form?.["3e"];

  const getFund = (fund) => {
    switch (fund) {
      case "BTC-Trust 1":
        return "btc-trust";
      case "HBAR-Trust 1":
        return "hbar-trust";
      default:
        return "n/a";
    }
  };

  const fundInfo = getFundHelper(getFund(form?.["4a"]));

  const getFundInfoText = () => {
    return fundInfo.info.map((item, index) => {
      return (
        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <a
                href="#"
                className="text-gray-900 font-medium hover:text-gray-600"
              >
                {item.first}
              </a>
              <p className="text-gray-500">{item.second}</p>
            </div>
            {item.third !== "" && (
              <StatefulTooltip
                content={() => <div>{item.third}</div>}
                returnFocus
                autoFocus
              >
                <div className="flex-shrink-0 pr-2">
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Open options</span>
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </StatefulTooltip>
            )}
          </div>
        </li>
      );
    });
  };

  const getFundLinks = () => {
    return fundInfo.links
      .filter((i, index) => {
        if (openFundLinks || !portfolio) return true;
        else {
          if (index < 2) return true;
          else return false;
        }
      })
      .map((item, index) => {
        return (
          <li>
            <div className="block">
              <div className="p-4 flex items-center">
                <div className="min-w-0 flex-1 flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className={`flex ${
                        isMobile ? "text-xs" : "text-sm"
                      } font-medium text-indigo-600`}
                    >
                      <p>{item.first}</p>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="mt-4 flex-shrink-0 sm:mt-0"
                  >
                    <div
                      onClick={() => {
                        window.open(item.third, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                      className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm ${
                        isMobile ? "text-xs" : "text-sm"
                      } font-medium text-green-600 bg-green-100 hover:bg-green-200 duration-200`}
                    >
                      {item.second !== "" ? item.second : "Download"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      });
  };

  const mainApp = (full = true) => {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <section id="basic-info">
            <h2
              style={{
                fontSize: isMobile ? "20px" : "30px",
                fontWeight: "700",
                lineHeight: "1em",
              }}
            >
              Investor Information
            </h2>
            <div
              style={{
                content: "",
                display: "block",
                width: "75px",
                height: "5px",
                backgroundColor: "#3C81F6",
                marginTop: "20px",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
            />

            <div className="form-grid">
              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-1 md:gap-4"
                style={{ width: "100%" }}
              >
                {Number(form?.["1k"]) !== 0 ? (
                  <div>
                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-3 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">
                          Legal Name of Entity
                        </div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1a"]}
                            onChange={(e) => handleForm("1a", e)}
                            type="text"
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <SelectMenu
                        label={"Type of Entity"}
                        placeholder={"Select an option"}
                        currentSelection={form?.["1b"]}
                        setSelection={(e) => {
                          handleForm("1b", e);
                        }}
                        options={[
                          {
                            name: "Private Trust",
                          },
                          {
                            name: "Limited Liability Company",
                          },
                          {
                            name: "Partnership",
                          },
                          {
                            name: "Corporation",
                          },
                          {
                            name: "Fund",
                          },
                          {
                            name: "Other",
                          },
                          // {
                          //   name: "Public Body",
                          // },
                          // {
                          //   name: "Pension Scheme",
                          // },
                          // {
                          //   name: "Registered Charity/Foundation",
                          // },
                          // {
                          //   name: "School, College or University",
                          // },
                        ]}
                      />

                      <div>
                        <div className="form-question">EIN / Entity Tax ID</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1c"]}
                            onBlur={() => formatEIN(form?.["1c"])}
                            onChange={(e) => handleForm("1c", e)}
                            type="text"
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">Corporate Address</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1d"]}
                            onChange={(e) => handleForm("1d", e)}
                            type="text"
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">City</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1e"]}
                            onChange={(e) => handleForm("1e", e)}
                            type="text"
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">State/Region</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1f"]}
                            onChange={(e) => handleForm("1f", e)}
                            type="text"
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">Zip Code</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["1g"]}
                            onChange={(e) => handleForm("1g", e)}
                            type="text"
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-3 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">Phone Number</div>
                        <div className="form-space" />
                        <div className="">
                          <PhoneInput
                            country={form?.["1h_country"]}
                            onCountryChange={({ option }) => {
                              handleForm("1h_country", option);

                              if (form?.["1h_country"] === undefined) {
                                handleForm("1h_country", {
                                  label: "United States",
                                  id: "US",
                                  dialCode: "+1",
                                });
                              }
                            }}
                            size={SIZE.mini}
                            text={form?.["1h"]}
                            onTextChange={(e) => handleForm("1h", e)}
                            overrides={{
                              CountrySelect: {
                                props: {
                                  overrides: {
                                    Root: {
                                      border: "none",
                                    },
                                    ControlContainer: {
                                      border: "none",
                                    },
                                  },
                                },
                              },
                              Input: {
                                props: {
                                  overrides: {
                                    InputContainer: {
                                      style: {
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "6px",
                                        border: "1px solid #D1D5DB",
                                        height: "35px",
                                      },
                                    },
                                    Input: {
                                      style: {
                                        fontSize: "14px",
                                        color: "#222222",
                                        border: "none",
                                        border: "1px solid #D1D5DB",
                                      },
                                    },
                                    Root: {
                                      style: {
                                        border: "none",
                                        boxShadow: "none",
                                        borderRadius: "6px",
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>

                      <SelectMenu
                        label={"Country of Incorporation"}
                        placeholder={"Select an option"}
                        currentSelection={form?.["1i"]}
                        setSelection={(e) => {
                          handleForm("1i", e);
                          handleForm("1j", "");
                        }}
                        options={countries}
                      />

                      <SelectMenu
                        regionBoolean
                        countryReference={form?.["1i"]}
                        label={"State of Incorporation"}
                        placeholder={"Select an option"}
                        currentSelection={form?.["1j"]}
                        setSelection={(e) => {
                          handleForm("1j", e);
                        }}
                        options={countries}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <BinarySelect
                  label={
                    "Are you an authorized representative of the Investor approved to execute investment documents on its behalf?"
                  }
                  required={true}
                  selected={form?.["1k"]}
                  setSelect={(e) => {
                    handleForm("1k", e);
                  }}
                />

                {Number(form?.["1k"]) === 1 && part1_done ? (
                  <div>
                    <h2
                      style={{
                        marginTop: "60px",
                        fontSize: isMobile ? "20px" : "30px",
                        fontWeight: "700",
                        lineHeight: "1em",
                      }}
                    >
                      Authorized Representative Information
                    </h2>
                    <div
                      style={{
                        content: "",
                        display: "block",
                        width: "75px",
                        height: "5px",
                        backgroundColor: "#3C81F6",
                        marginTop: "20px",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-3 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">First Name</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2a"]}
                            onChange={(e) => handleForm("2a", e)}
                            disabled={showApplication}
                            type="text"
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">Middle Name</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2b"]}
                            onChange={(e) => handleForm("2b", e)}
                            type="text"
                            disabled={showApplication}
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">Last Name</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2c"]}
                            onChange={(e) => handleForm("2c", e)}
                            type="text"
                            disabled={showApplication}
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">Title</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2d"]}
                            onChange={(e) => handleForm("2d", e)}
                            type="text"
                            disabled={showApplication}
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      <div>
                        <div className="form-question">Phone Number</div>
                        <div className="form-space" />
                        <div className="">
                          <PhoneInput
                            country={form?.["2e_country"]}
                            onCountryChange={({ option }) =>
                              handleForm("2e_country", option)
                            }
                            size={SIZE.mini}
                            text={form?.["2e"]}
                            onTextChange={(e) => {
                              handleForm("2e", e);

                              if (form?.["2e_country"] === undefined) {
                                handleForm("2e_country", {
                                  label: "United States",
                                  id: "US",
                                  dialCode: "+1",
                                });
                              }
                            }}
                            disabled={showApplication}
                            overrides={{
                              CountrySelect: {
                                props: {
                                  overrides: {
                                    Root: {
                                      border: "none",
                                    },
                                    ControlContainer: {
                                      border: "none",
                                    },
                                  },
                                },
                              },
                              Input: {
                                props: {
                                  overrides: {
                                    InputContainer: {
                                      style: {
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "6px",
                                        border: "1px solid #D1D5DB",
                                        height: "35px",
                                      },
                                    },
                                    Input: {
                                      style: {
                                        fontSize: "14px",
                                        color: "#222222",
                                        border: "none",
                                        border: "1px solid #D1D5DB",
                                      },
                                    },
                                    Root: {
                                      style: {
                                        border: "none",
                                        boxShadow: "none",
                                        borderRadius: "6px",
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">Home Street Address</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2f"]}
                            onChange={(e) => handleForm("2f", e)}
                            type="text"
                            disabled={showApplication}
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">City</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2g"]}
                            onChange={(e) => handleForm("2g", e)}
                            type="text"
                            disabled={showApplication}
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">State/Region</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2h"]}
                            onChange={(e) => handleForm("2h", e)}
                            type="text"
                            disabled={showApplication}
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">Zip Code</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2i"]}
                            onChange={(e) => handleForm("2i", e)}
                            type="text"
                            disabled={showApplication}
                            name="number"
                            id="number"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <SelectMenu
                      label={"Country of Residence"}
                      placeholder={"Select an option"}
                      currentSelection={form?.["2j"]}
                      disabled={showApplication}
                      setSelection={(e) => {
                        handleForm("2j", e);
                      }}
                      options={countries}
                    />

                    <div
                      className="max-w-6xl pb-2 mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <SelectMenu
                        label={"Country of Citizenship"}
                        placeholder={"Select an option"}
                        currentSelection={form?.["2k"]}
                        disabled={showApplication}
                        setSelection={(e) => {
                          handleForm("2k", e);
                        }}
                        options={countries}
                      />
                      <div>
                        <div className="form-question">Date of Birth</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["2l"]}
                            onChange={(e) => handleForm("2l", e)}
                            type="date"
                            name="number"
                            disabled={showApplication}
                            id="number"
                            style={{
                              height: "35px",
                              width: isMobile && "100%",
                            }}
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    {form && (
                      <div className="form-grid">
                        <div>
                          <div
                            className="form-question"
                            style={{ fontWeight: "normal", fontSize: "14px" }}
                          >
                            Upload a current valid passport or national ID card,
                            driver's license or government issued ID with
                            photograph
                          </div>
                        </div>

                        {form?.["2m"] &&
                        form?.["2m"] !== "" &&
                        JSON.parse(form?.["2m"])?.url !== "" &&
                        !upload2 ? (
                          <div className="flex items-center text-sm">
                            <img
                              src={getFileIcon(
                                form?.["2m"]?.name
                                  ? JSON.parse(form?.["2m"])
                                      ?.name.split(".")
                                      .pop()
                                      .toLowerCase()
                                  : ""
                              )}
                              alt={JSON.parse(form?.["2m"])?.name}
                              style={{ marginRight: "10px", height: "20px" }}
                            />
                            <div
                              onClick={() =>
                                window.open(
                                  JSON.parse(form?.["2m"])?.url,
                                  "_blank"
                                )
                              }
                              style={{ cursor: "pointer" }}
                              className="hover:underline hover:text-blue-600 text-blue-400"
                            >
                              {JSON.parse(form?.["2m"])?.name ||
                                "Uploaded File"}
                            </div>
                            {showApplication ? (
                              <span />
                            ) : (
                              <div
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setFile2([]);
                                  setUpload2(true);
                                }}
                                className="text-black hover:text-yellow-600"
                              >
                                (change)
                              </div>
                            )}
                          </div>
                        ) : (
                          <React.Fragment>
                            {form?.["2m"] && JSON.parse(form?.["2m"])?.url && (
                              <div
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setUpload2(false)}
                                className="text-red-500 hover:text-red-600 text-sm"
                              >
                                (cancel)
                              </div>
                            )}

                            <FileUpload
                              jwt={jwt}
                              required={true}
                              setFile={setFile2}
                              file={file2}
                              setFileName={setFileName2}
                              disabled={showApplication}
                              setFileURL={(e) => {
                                setFileURL2(e);
                                const fileObject = {
                                  name: fileName2,
                                  url: e,
                                };
                                handleForm("2m", JSON.stringify(fileObject));
                              }}
                              fileURL={fileURL2}
                              fileName={fileName2}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    )}

                    {accreditationJSX()}
                  </div>
                ) : (
                  ""
                )}

                {Number(form?.["1k"]) === 0 ? (
                  <div>
                    <p
                      className="font-light"
                      style={{
                        marginTop: "60px",
                        fontSize: "14px",
                        lineHeight: "1em",
                      }}
                    >
                      Based on the fact that you are not authorized to execute
                      investment documents on behalf of the Investor, please
                      provide us with contact information for at least one
                      person legally able to do so.
                    </p>
                    <div
                      style={{
                        content: "",
                        display: "block",
                        width: "75px",
                        height: "5px",
                        backgroundColor: "#3C81F6",
                        marginTop: "20px",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">First Name</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["invite-a"]}
                            onChange={(e) => handleForm("invite-a", e)}
                            type="text"
                            disabled={showApplication}
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="form-question">Last Name</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["invite-b"]}
                            onChange={(e) => handleForm("invite-b", e)}
                            type="text"
                            disabled={showApplication}
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div className="form-question">Email</div>
                        <div className="form-space" />
                        <div>
                          <input
                            value={form?.["invite-c"]}
                            onChange={(e) => handleForm("invite-c", e)}
                            type="text"
                            disabled={showApplication}
                            name="text"
                            id="text"
                            className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                          {!isValidEmail(form?.["invite-c"]) &&
                            form?.["invite-c"] && (
                              <div className="text-red-500 text-xs">
                                email is invalid
                              </div>
                            )}
                        </div>
                      </div>

                      <div>
                        <div className="form-question">Phone Number</div>
                        <div className="form-space" />
                        <div className="">
                          <PhoneInput
                            country={form?.["invite-d_country"]}
                            onCountryChange={({ option }) =>
                              handleForm("invite-d_country", option)
                            }
                            disabled={showApplication}
                            size={SIZE.mini}
                            text={form?.["invite-d"]}
                            onTextChange={(e) => {
                              handleForm("invite-d", e);

                              if (form?.["invite-d_country"] === undefined) {
                                handleForm("invite-d_country", {
                                  label: "United States",
                                  id: "US",
                                  dialCode: "+1",
                                });
                              }
                            }}
                            overrides={{
                              CountrySelect: {
                                props: {
                                  overrides: {
                                    Root: {
                                      border: "none",
                                    },
                                    ControlContainer: {
                                      border: "none",
                                    },
                                  },
                                },
                              },
                              Input: {
                                props: {
                                  overrides: {
                                    InputContainer: {
                                      style: {
                                        backgroundColor: "white",
                                        border: "none",
                                        borderRadius: "6px",
                                        border: "1px solid #D1D5DB",
                                        height: "35px",
                                      },
                                    },
                                    Input: {
                                      style: {
                                        fontSize: "14px",
                                        color: "#222222",
                                        border: "none",
                                        border: "1px solid #D1D5DB",
                                      },
                                    },
                                    Root: {
                                      style: {
                                        border: "none",
                                        boxShadow: "none",
                                        borderRadius: "6px",
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {error !== "" && (
                      <div className="text-red-500 text-xs py-2">{error}</div>
                    )}

                    <div
                      onClick={async () => {
                        if (canSubmitAlt) {
                          if (
                            window.confirm(
                              `Please confirm the info is correct. Continue?`
                            )
                          ) {
                            setError("");
                            setSubmitAlt(true);
                            handleSubmitAlt();
                            await delay(3500);
                            alert(
                              "We have invited your friend. Logging you out now..."
                            );
                            localStorage.removeItem("immutable-jwt");
                            window.location = "#";
                            setSubmitAlt(false);
                          }
                        } else {
                          alert("Form is not complete");
                        }
                      }}
                      style={{ cursor: "pointer", width: "140px" }}
                      className={`inline-flex items-center justify-center mt-5 px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-blue-600 ${
                        submittingAlt ? "bg-gray-400" : "bg-blue-500"
                      }`}
                    >
                      {submittingAlt ? loadingJSX() : "Invite"}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {!form?.["3d"] &&
                  form?.["1k"] === 1 &&
                  accreditationFilled &&
                  part2_done &&
                  part3_done && (
                    <React.Fragment>
                      <div style={{ paddingTop: "50px" }}>
                        <section id="aml-document">
                          <h2
                            style={{
                              fontSize: isMobile ? "20px" : "30px",
                              fontWeight: "700",
                              lineHeight: "1em",
                            }}
                          >
                            Required Anti-Money Laundering Documentation
                          </h2>
                          <div
                            style={{
                              content: "",
                              display: "block",
                              width: "75px",
                              height: "5px",
                              backgroundColor: "#3C81F6",
                              marginTop: "20px",
                              borderRadius: "4px",
                              marginBottom: "20px",
                            }}
                          />{" "}
                          <div
                            className="max-w-6xl pb-2 mx-auto md:grid md:grid-cols-1 md:gap-4"
                            style={{ width: "100%" }}
                          >
                            {getAmlForms()}
                          </div>
                        </section>
                      </div>

                      {!showApplication && form?.["1k"] === 1 && (
                        <div style={{ paddingTop: "50px" }}>
                          <section id="subscription-document">
                            <h2
                              style={{
                                fontSize: isMobile ? "20px" : "30px",
                                fontWeight: "700",
                                lineHeight: "1em",
                              }}
                            >
                              Subscription Agreement
                            </h2>
                            <div
                              style={{
                                content: "",
                                display: "block",
                                width: "75px",
                                height: "5px",
                                backgroundColor: "#3C81F6",
                                marginTop: "20px",
                                borderRadius: "4px",
                                marginBottom: "20px",
                              }}
                            />

                            <div
                              className="max-w-6xl mx-auto md:grid md:grid-cols-1 md:gap-4"
                              style={{ width: "100%" }}
                            >
                              <SelectMenu
                                label={
                                  "Which trust are you interested in investing in?"
                                }
                                placeholder={"Select an option"}
                                currentSelection={form?.["4a"]}
                                setSelection={(e) => {
                                  handleForm("4a", e);
                                }}
                                disabled={
                                  !(
                                    form?.["executedSubDocURL"] === undefined &&
                                    executedSubDocURL === ""
                                  ) || showApplication
                                }
                                options={funds}
                              />

                              {form?.["4a"] && (
                                <div>
                                  <div className="form-question">
                                    How much would you like to invest?
                                  </div>
                                  <div className="form-space" />
                                  <div style={{ marginBottom: "15px" }}>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 sm:text-sm">
                                          $
                                        </span>
                                      </div>
                                      <input
                                        value={form?.["investmentAmount"]}
                                        onChange={(e) =>
                                          handleForm("investmentAmount", e)
                                        }
                                        type="text"
                                        disabled={
                                          !(
                                            form?.["executedSubDocURL"] ===
                                              undefined &&
                                            executedSubDocURL === ""
                                          ) || showApplication
                                        }
                                        name="price"
                                        id="price"
                                        className={`${
                                          !(
                                            form?.["executedSubDocURL"] ===
                                              undefined &&
                                            executedSubDocURL === ""
                                          )
                                            ? "bg-gray-200"
                                            : "bg-white hover:border-blue-500"
                                        } border-2 form-input ${insufficientAmount(
                                          form?.["investmentAmount"]
                                        )} shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                        placeholder="0.00"
                                        aria-describedby="price-currency"
                                        style={{
                                          paddingLeft: "1.75rem",
                                          paddingRight: "3rem",
                                        }}
                                      />
                                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span
                                          className="text-gray-500 sm:text-sm"
                                          id="price-currency"
                                        >
                                          USD
                                        </span>
                                      </div>
                                    </div>
                                    {insufficientAmountText(
                                      form?.["investmentAmount"]
                                    )}
                                  </div>
                                </div>
                              )}

                              {form?.["4a"] && (
                                <div className="mt-5 mb-10">
                                  <div className="mb-5">
                                    <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                                      Trust Info
                                    </h2>
                                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                                      {getFundInfoText()}
                                    </ul>
                                  </div>

                                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                    <ul className="divide-y divide-gray-200">
                                      {getFundLinks()}
                                    </ul>
                                  </div>

                                  {portfolio && (
                                    <div
                                      onClick={() =>
                                        setOpenFundLinks(!openFundLinks)
                                      }
                                      className="hover:underline text-center text-xs mt-2 text-blue-500"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {openFundLinks ? "hide" : "see all"}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>

                            {sufficientAmount(form?.["investmentAmount"]) && (
                              <div className="flex mb-6" id="applicationBottom">
                                <label className="flex items-center">
                                  <input
                                    onChange={(e) => {
                                      handleForm("4b", e.target.checked);
                                    }}
                                    disabled={showApplication}
                                    defaultChecked={form?.["4b"]}
                                    type="checkbox"
                                    className="form-checkbox mr-3"
                                  />
                                  <span className="ml-2 text-xs text-gray-700">
                                    I certify that I have reviewed all of the
                                    documentation related to my potential
                                    investment and am aware of the risks
                                    associated with an investment in the trust.
                                  </span>
                                </label>
                              </div>
                            )}

                            {form?.["investmentAmount"] &&
                              full &&
                              getHelloSignJSX()}
                          </section>{" "}
                        </div>
                      )}

                      {portfolio && form?.["4b"] && full && (
                        <div
                          onClick={async () => {
                            if (canSubmit) {
                              if (
                                window.confirm(
                                  `Please confirm all the information is correct. After you submit, you cannot modify the application. Continue?`
                                )
                              ) {
                                setSubmit(true);
                                handleForm("status", 1);
                                await delay(1500);
                                scrollTop();
                                setSubmit(false);
                              }
                            } else {
                              alert("Form is not complete");
                            }
                          }}
                          style={{ cursor: "pointer", width: "140px" }}
                          className={`inline-flex items-center justify-center px-8 py-2 mt-10 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-blue-600 ${
                            submitting ? "bg-gray-400" : "bg-blue-500"
                          }`}
                        >
                          {submitting ? loadingJSX() : "Submit All"}
                        </div>
                      )}
                    </React.Fragment>
                  )}
              </div>
            </div>
          </section>
        </BaseProvider>
      </StyletronProvider>
    );
  };

  const sufficientAmount = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed >= 100000;
  };

  const insufficientAmount = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed < 100000 ? "border-red-500 text-red-500" : "";
  };

  const insufficientAmountText = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed < 100000 ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        Minimum investment amount not met
      </p>
    ) : (
      ""
    );
  };

  return (
    <React.Fragment>
      {notificationArray}
      <form>
        <div style={{ paddingBottom: "50px" }}>
          {portfolio && (showPreviousPage || fiveYearAccreditation !== 0) ? (
            <section>
              <h2
                style={{
                  fontSize: isMobile ? "20px" : "30px",
                  fontWeight: "700",
                  lineHeight: "1em",
                }}
              >
                Application
              </h2>
              <div
                style={{
                  content: "",
                  display: "block",
                  width: "75px",
                  height: "5px",
                  backgroundColor: "#3C81F6",
                  marginTop: "20px",
                  borderRadius: "4px",
                  marginBottom: "20px",
                }}
              />

              <BinarySelect
                label={
                  "Within the last year, I previously invested with Immutable.co and submitted documents attesting to my Accredited Investor Status as part of my Subscription Agreement. I represent that my Accredited Investor Status has not changed."
                }
                required={true}
                yesOverride={"Yes"}
                noOverride={"No, it has changed"}
                selected={fiveYearAccreditation}
                setSelect={(e) => {
                  setFiveYearAccreditation(e);
                  setShowPreviousPage(false);
                }}
              />

              {fiveYearAccreditation === 1 && (
                <div className="text-xs" style={{ marginTop: "50px" }}>
                  <div className="mt-10">
                    <section id="subscription-document">
                      <h2
                        style={{
                          fontSize: isMobile ? "20px" : "30px",
                          fontWeight: "700",
                          lineHeight: "1em",
                        }}
                      >
                        Subscription Agreement
                      </h2>
                      <div
                        style={{
                          content: "",
                          display: "block",
                          width: "75px",
                          height: "5px",
                          backgroundColor: "#3C81F6",
                          marginTop: "20px",
                          borderRadius: "4px",
                          marginBottom: "20px",
                        }}
                      />
                    </section>

                    {form?.["4a"] && (
                      <div className="mt-5 mb-10">
                        <div className="mb-5">
                          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                            Trust Info
                          </h2>
                          <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                            {getFundInfoText()}
                          </ul>
                        </div>

                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                          <ul className="divide-y divide-gray-200">
                            {getFundLinks()}
                          </ul>
                        </div>

                        <div
                          onClick={() => setOpenFundLinks(!openFundLinks)}
                          className="hover:underline text-center text-xs mt-2 text-blue-500"
                          style={{ cursor: "pointer" }}
                        >
                          {openFundLinks ? "hide" : "see all"}
                        </div>
                      </div>
                    )}

                    <div className="mb-5">
                      <div className="form-question">
                        How much would you like to invest?
                      </div>
                      <div className="form-space" />
                      <div>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            value={form?.["investmentAmount"]}
                            onChange={(e) => handleForm("investmentAmount", e)}
                            type="text"
                            name="price"
                            id="price"
                            className={`form-input focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${insufficientAmount(
                              form?.["investmentAmount"]
                            )}`}
                            placeholder="0.00"
                            aria-describedby="price-currency"
                            style={{
                              paddingLeft: "1.75rem",
                              paddingRight: "3rem",
                            }}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="price-currency"
                            >
                              USD
                            </span>
                          </div>
                        </div>
                        {insufficientAmountText(form?.["investmentAmount"])}
                      </div>
                    </div>

                    <div className="flex my-6">
                      <label className="flex items-center">
                        <input
                          onChange={(e) => {
                            setInnerCheck(e.target.checked);
                          }}
                          defaultChecked={innerCheck}
                          type="checkbox"
                          className="form-checkbox mr-3"
                        />
                        <span className="ml-2 text-xs text-gray-700">
                          I certify that I have reviewed all of the
                          documentation related to my potential investment and
                          am aware of the risks associated with an investment in
                          the fund.
                        </span>
                      </label>
                    </div>

                    {Number(form?.["investmentAmount"]) > 0 &&
                      innerCheck &&
                      getHelloSignJSX()}
                  </div>

                  <div
                    onClick={() => setShowApplication(!showApplication)}
                    className="hover:underline text-blue-500 py-5"
                    style={{ cursor: "pointer" }}
                  >
                    {showApplication ? "hide application" : "show application"}
                  </div>

                  {showApplication && mainApp(false)}
                </div>
              )}
            </section>
          ) : (
            <div>
              {portfolio && fiveYearAccreditation === 0 && (
                <div
                  onClick={() => setShowPreviousPage(true)}
                  className="text-sm text-blue-500 hover:underline transition duration-200 mb-5"
                  style={{ cursor: "pointer" }}
                >
                  ←&nbsp;go back
                </div>
              )}
              {mainApp(true)}
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
