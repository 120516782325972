import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import delay from "delay";
import moment from "moment";
import { Sparklines, SparklinesLine } from "react-sparklines";
import defi from "../images/defi.png";
import equity from "../images/equity.jpeg";
import hbar from "../images/hbar.png";
import btc from "../images/btc.png";
import eth from "../images/eth.png";
import { isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import { writeForm, socket, connectWSS } from "../utils/socket";
import config from "../config";
import { getFundHelper } from "../global";
import useSWR, { mutate } from "swr";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  linearGradient,
  AreaChart,
  ResponsiveContainer,
} from "recharts";
import InstitutionalOnboarding from "./institutionalOnboarding";
import IndividualOnboarding from "./individualOnboarding";

export default function MainPortfolio(props) {
  const { user } = props;

  const [modal, setModal] = useState(false);
  const [saving, setSave] = useState(false);

  const [form, setForm] = useState();
  const [formInt, setFormInt] = useState(0);

  const [openLoading, setOpenLoading] = useState(false);

  const [toolTipTime, setToolTipTime] = useState("");
  const [toolTipRate, setToolTipRate] = useState("");

  const [timePeriod, setTimePeriod] = useState("7d");

  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const fund = parsed?.fund || "";
  const step = parsed?.step || "";
  const hashRoute = location.hash;

  useEffect(() => {
    if (step === "") {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [step]);

  const fundLogo = (fund) => {
    switch (fund) {
      case "DEFI":
        return defi;
      case "EQUITY":
        return equity;
      case "HBAR":
        return hbar;
      case "BTC":
        return btc;
      case "ETH":
        return eth;
      default:
        return defi;
    }
  };

  const fundShares = (fund, portfolioRaw) => {
    return portfolioRaw?.details?.[fund.toLowerCase()]?.shares || 0;
  };

  const fundValue = (fund, portfolioRaw) => {
    return portfolioRaw?.details?.[fund.toLowerCase()]?.usdValue || 0;
  };

  const fundStatus = (fund, portfolioRaw) => {
    let result = Number(fundValue(fund, portfolioRaw));

    if (result === 0) {
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
          Open
        </span>
      );
    } else if (result > 0) {
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          Active
        </span>
      );
    } else {
      return (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
          n/a
        </span>
      );
    }
  };

  const fundPortfolio = (fund, portfolioRaw) => {
    return (
      prettify(
        (
          Number(
            portfolioRaw?.details?.[fund.toLowerCase()]?.percentPortfolio || 0
          ) * 100
        ).toFixed(2)
      ) + "%"
    );
  };

  const getSparkLineAsset = (fund) => {
    switch (fund) {
      case "defi-index-fund":
        return "";
      case "equity-index-fund":
        return "";
      case "hbar-trust":
        return "hbar";
      case "btc-trust":
        return "btc";
      case "ethereum-trust":
        return "eth";
      default:
        return "btc";
    }
  };

  const getSparkLineColor = (fund) => {
    switch (fund) {
      case "defi-index-fund":
        return "#046EE7";
      case "equity-index-fund":
        return "#388BC1";
      case "hbar-trust":
        return "black";
      case "btc-trust":
        return "#F7931A";
      case "ethereum-trust":
        return "#98A2D3";
      default:
        return "black";
    }
  };

  const fundUrl = (fund) => {
    switch (fund) {
      case "DEFI":
        return "defi-index-fund";
      case "EQUITY":
        return "equity-index-fund";
      case "HBAR":
        return "hbar-trust";
      case "BTC":
        return "btc-trust";
      case "ETH":
        return "ethereum-trust";
      default:
        return "";
    }
  };

  const fundName = (fund) => {
    switch (fund) {
      case "DEFI":
        return "DEFI-INDEX 10";
      case "EQUITY":
        return "TOP-BLOCKCHAIN 10";
      case "HBAR":
        return "HBAR TRUST";
      case "BTC":
        return "BTC TRUST";
      case "ETH":
        return "ETH TRUST";
      default:
        return "-";
    }
  };

  const queryParam = `fund=${fund}&step=${step}`;

  const funds = ["BTC", "HBAR"]; // , "ETH", "DEFI", "EQUITY"

  connectWSS();

  async function handleSave(val) {
    if (val) {
      setSave(val);
      await delay(500);
      setSave(false);
    }
  }

  socket.on("savingForm", (value) => {
    handleSave(value);
  });

  const jwt = localStorage.getItem("immutable-jwt");

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const getCurrentPortfolio = () => {
    return funds.map((current, _) => {
      return (
        <tr>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="h-10 w-10 rounded-full"
                  src={fundLogo(current)}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  {fundName(current)}
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {prettify(fundShares(current, portfolioRaw))} Shares
            </div>
            <div className="text-sm text-gray-500">
              {"$" + prettify(fundValue(current, portfolioRaw))}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            {fundStatus(current, portfolioRaw)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {fundPortfolio(current, portfolioRaw)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`portfolio?fund=${fundUrl(current)}`);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="text-indigo-600 hover:text-indigo-900"
            >
              View
            </div>
          </td>
        </tr>
      );
    });
  };

  const getFundViaParam = (fundInput) => {
    switch (fundInput) {
      case "defi-index-fund":
        return {
          name: "Defi Index 10",
          logo: defi,
          fund: fund,
        };
      case "equity-index-fund":
        return {
          name: "Top Blockchain 10",
          logo: equity,
          fund: fund,
        };
      case "hbar-trust":
        return {
          name: "HBAR Trust",
          logo: hbar,
          fund: fund,
        };
      case "btc-trust":
        return {
          name: "BTC Trust",
          logo: btc,
          fund: fund,
        };
      case "ethereum-trust":
        return {
          name: "ETH Trust",
          logo: eth,
          fund: fund,
        };
      default:
        return {
          name: "Portfolio",
          logo: undefined,
          fund: fund,
        };
    }
  };

  const fundOverviewInfo = () => {
    switch (fund) {
      case "defi-index-fund":
        return { __html: `<div>defi summary</div>` };
      case "equity-index-fund":
        return { __html: `<div>equity summary</div>` };
      case "hbar-trust":
        return {
          __html: `<div>Hedera is a decentralized public network where developers can build secure, fair applications with near real-time consensus. The platform is owned and governed by a council of global innovators including Avery Dennison, Boeing, Deutsche Telekom, DLA Piper, FIS (WorldPay), Google, IBM, LG Electronics, Magalu, Nomura, Swirlds, Tata Communications, University College London (UCL), Wipro, and Zain Group.</div>`,
        };
      case "btc-trust":
        return {
          __html: `<div>Bitcoin is the first successful internet money based on peer-to-peer technology; whereby no central bank or authority is involved in the transaction and production of the Bitcoin currency. It was created by an anonymous individual/group under the name, Satoshi Nakamoto. \n\n Bitcoin is designed to have only 21 million BTC ever created, thus making it a deflationary currency. Bitcoin uses the <a className='text-blue-500 hover:underline' href="https://www.coingecko.com/en?hashing_algorithm=SHA-256">SHA-256</a> hashing algorithm with an average transaction confirmation time of 10 minutes.</div>`,
        };
      case "ethereum-trust":
        return {
          __html: `<div>Ethereum is a <a className='text-blue-500 hover:underline' href="https://www.coingecko.com/en?category_id=29&view=market\">smart contract platform</a> that enables developers to build tokens and decentralized applications (dapps). ETH is the native currency for the Ethereum platform and also works as the transaction fees to miners on the Ethereum network.\r\n\r\nEthereum is the pioneer for blockchain based smart contracts. Smart contract is essentially a computer code that runs exactly as programmed without any possibility of downtime, censorship, fraud or third-party interference. It can facilitate the exchange of money, content, property, shares, or anything of value. </div>`,
        };
      default:
        return { __html: `<div>n/a</div>` };
    }
  };

  const getChartFormat = () => {
    switch (timePeriod) {
      case "1d":
        return "hA";
      case "7d":
        return "ddd D";
      case "1m":
        return "D";
      case "1y":
        return "MMM";
      case "all":
        return getChartAllFormat();
      default:
        return "hA";
    }
  };

  const fundTableJSX = () => {
    return (
      <div className="flex flex-col max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-sm">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Fund
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ownership
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Percent of Portfolio
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Percent</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getCurrentPortfolio()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const chartDataURL = `${base}/user/portfolioData/${timePeriod}`;
  const { data: chartDataRaw } = useSWR(chartDataURL, () =>
    fetch(chartDataURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const getChartInterval = () => {
    switch (timePeriod) {
      case "1d":
        return 2;
      case "7d":
        return 24;
      case "1m":
        return 24;
      case "1y":
        return 24;
      case "all":
        return Math.ceil(chartDataRaw?.daysOld * 30);
      default:
        return 30;
    }
  };

  const getChartAllFormat = () => {
    let daysOld = Math.ceil(chartDataRaw?.daysOld);
    if (daysOld <= 1) return "hA";
    else if (daysOld <= 7) return "ddd D";
    else if (daysOld <= 31) return "D";
    else if (daysOld < 365) return "MMM";
    else return "YYYY";
  };

  const sparkLineURL = `${base}/user/getSparkLine/${getSparkLineAsset()}`;
  const { data: sparkLineRaw } = useSWR(sparkLineURL, () =>
    fetch(sparkLineURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const portfolioURL = `${base}/user/portfolio`;
  const { data: portfolioRaw } = useSWR(portfolioURL, () =>
    fetch(portfolioURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const activeClass = "text-blue-500 px-3 py-2 uppercase text-sm";
  const nonActiveClass =
    "text-gray-500 hover:text-gray-700 px-3 py-2 uppercase text-sm";

  const dayClass = timePeriod === "1d" ? activeClass : nonActiveClass;
  const weekClass = timePeriod === "7d" ? activeClass : nonActiveClass;
  const monthClass = timePeriod === "1m" ? activeClass : nonActiveClass;
  const yearClass = timePeriod === "1y" ? activeClass : nonActiveClass;
  const allClass = timePeriod === "all" ? activeClass : nonActiveClass;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // @ts-ignore
  const prettify = (num) => {
    return numberWithCommas(Number(num).toFixed(2));
  };

  const getChartFormat2 = () => {
    switch (timePeriod) {
      case "1d":
        return "h:mm A";
      case "7d":
        return "D ddd, h:mm A";
      case "1m":
        return "MMM D, h:mm A";
      case "1y":
        return "MMM D, YYYY";
      case "all":
        return "MMM D, YYYY";
      default:
        return "h:mm A";
    }
  };

  const chartJSX = () => {
    return (
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-sm shadow pt-5 mb-7">
          <div className="flex items-start justify-between px-5">
            <div className="flex flex-col">
              <span
                style={{ color: "rgba(17, 51, 83, 0.6)", fontSize: "14px" }}
              >
                Portfolio balance
              </span>
              <span
                style={{
                  fontSize: isMobile ? "24px" : "32px",
                  fontWeight: "bold",
                }}
              >
                ${prettify((portfolioRaw?.details?.totalValue || 0).toFixed(2))}
              </span>
              {toolTipRate !== "" && !isNaN(toolTipRate) ? (
                <div className="flex items-end">
                  <p className="text-black" style={{ fontSize: "12px" }}>
                    ${numberWithCommas(Number(toolTipRate).toFixed(2))}
                  </p>
                  <p
                    className="text-gray-400"
                    style={{ marginLeft: "7px", fontSize: "12px" }}
                  >
                    {moment
                      .unix(new Date(toolTipTime).getTime() / 1000)
                      .format(getChartFormat2())}
                  </p>
                </div>
              ) : (
                <div style={{ fontSize: "12px", color: "white" }}>-</div>
              )}
            </div>
            <div>
              <div className="sm:hidden">
                <label for="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full text-xs focus:ring-indigo-500 border-gray-300 rounded-md"
                >
                  <option>1d</option>

                  <option>7d</option>

                  <option selected>1m</option>

                  <option>1y</option>
                  <option>all</option>
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setTimePeriod("1d")}
                    className={dayClass}
                  >
                    1d
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setTimePeriod("7d")}
                    className={weekClass}
                  >
                    7d
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setTimePeriod("1m")}
                    className={monthClass}
                  >
                    1m
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setTimePeriod("1y")}
                    className={yearClass}
                  >
                    1y
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setTimePeriod("all")}
                    className={allClass}
                  >
                    all
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px", zIndex: -10 }}>
            <ResponsiveContainer width="100%" height={320}>
              {!chartDataRaw?.data?.all ? (
                <div
                  className="flex items-center justify-center"
                  style={{ width: "100%", height: "300px" }}
                >
                  <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-6 w-6"></div>
                </div>
              ) : (
                <AreaChart
                  data={chartDataRaw?.data?.all}
                  margin={{ left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={"#3B82F6"}
                        stopOpacity={0.4}
                      />
                      <stop
                        offset="95%"
                        stopColor={"#3B82F6"}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <YAxis
                    type="number"
                    tick={false}
                    width={0}
                    domain={["auto", "auto"]}
                  />
                  <XAxis
                    dataKey="timestamp"
                    domain={["auto", "auto"]}
                    interval={getChartInterval()}
                    width={"95%"}
                    tickFormatter={(e) => moment(e).format(getChartFormat())}
                    tick={{ fill: "#6B7280", fontSize: "12px" }}
                  />
                  <Tooltip
                    content={(e) => {
                      let rate = e?.payload?.[0]?.payload?.rate;
                      let timestamp = e?.payload?.[0]?.payload?.timestamp || "";

                      setToolTipRate(rate);
                      setToolTipTime(timestamp);

                      return <div></div>;
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="rate"
                    stroke={"#3B82F6"}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  };

  const getAsset = () => {
    switch (fund) {
      case "defi-index-fund":
        return "btc";
      case "equity-index-fund":
        return "btc";
      case "hbar-trust":
        return "hbar";
      case "btc-trust":
        return "btc";
      case "ethereum-trust":
        return "eth";
      default:
        return "btc";
    }
  };

  const fundInfo = getFundHelper(fund);

  const loadingJSX = (color = "white") => {
    return (
      <svg
        className={`animate-spin h-5 w-5 text-${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  const getFundLinks = () => {
    return fundInfo.links.map((item, index) => {
      return (
        <li>
          <div className="block">
            <div className="p-4 flex items-center">
              <div className="min-w-0 flex-1 flex items-center justify-between">
                <div className="flex items-center">
                  <div
                    className={`flex ${
                      isMobile ? "text-xs" : "text-sm"
                    } font-medium text-indigo-600`}
                  >
                    <p>{item.first}</p>
                  </div>
                </div>
                <div
                  style={{ textAlign: "right" }}
                  className="mt-4 flex-shrink-0 sm:mt-0"
                >
                  <div
                    onClick={() => {
                      window.open(item.third, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                    className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm ${
                      isMobile ? "text-xs" : "text-sm"
                    } font-medium text-green-600 bg-green-100 hover:bg-green-200 duration-200`}
                  >
                    {item.second !== "" ? item.second : "Download"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });
  };

  const getFundInfoText = () => {
    return fundInfo.info.map((item, index) => {
      return (
        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <a
                href="#"
                className="text-gray-900 font-medium hover:text-gray-600"
              >
                {item.first}
              </a>
              <p className="text-gray-500">{item.second}</p>
            </div>
            <ReactTooltip />
            {item.third !== "" && (
              <div className="flex-shrink-0 pr-2" data-tip={item.third}>
                <button
                  onClick={(e) => e.preventDefault()}
                  className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Open options</span>
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </li>
      );
    });
  };

  const getChangeClass = (num) => {
    if (num === 0) {
      return "text-black";
    } else if (num > 0) {
      return "text-green-500";
    } else {
      return "text-red-500";
    }
  };

  const getChangeArrow = (num) => {
    if (num === 0) {
      return "";
    } else if (num > 0) {
      return " ↑";
    } else {
      return " ↓";
    }
  };

  const getChangeUsd = (summary) => {
    if (summary?.usd_24h_change === 0) {
      return "";
    } else if (summary?.usd_24h_change > 0) {
      return `+${numberWithCommas(
        Number(
          (
            (Number(summary?.usd_24h_change) / 100) *
            Number(summary?.usd)
          ).toFixed(3)
        )
      )}`;
    } else {
      return `${numberWithCommas(
        Number(
          (
            (Number(summary?.usd_24h_change) / 100) *
            Number(summary?.usd)
          ).toFixed(3)
        )
      )}`;
    }
  };

  const chartSummaryURL = `${base}/user/getChartSummary/${getAsset()}`;
  const { data: chartSummaryRaw } = useSWR(chartSummaryURL, () =>
    fetch(chartSummaryURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  useEffect(() => {
    // call DB to get the object
    if (form === undefined && step !== "" && user) {
      const parsedUrl = queryString.parse(queryParam);
      const fund = parsedUrl.fund;
      const step = parsedUrl.step;

      switch (fund) {
        case "defi-index-fund":
          setForm(user.defiApp?.[step] || {});
          return;
        case "btc-trust":
          setForm(user.btcApp?.[step] || {});
          return;
        case "hbar-trust":
          setForm(user.hbarApp?.[step] || {});
          return;
        default:
          setForm({});
          return;
      }
    }
  }, [form, user, step, formInt]);

  const modalJSX = () => {
    if (user.institutional) {
      return (
        <InstitutionalOnboarding
          closeModal={(e) => setModal(e)}
          user={user}
          portfolio={true}
          fundOverride={getFundViaParam(fund)}
        />
      );
    } else {
      return (
        <IndividualOnboarding
          closeModal={(e) => setModal(e)}
          user={user}
          portfolio={true}
          fundOverride={getFundViaParam(fund)}
        />
      );
    }
  };

  return (
    <div className="pt-10 pb-20 sm:px-6 lg:px-8">
      <ReactTooltip />
      <div className="relative max-w-lg mx-auto divide-gray-200 lg:max-w-7xl">
        <div>
          {fund !== "" ? (
            <p
              onClick={() => history.push("/portfolio")}
              style={{ cursor: "pointer" }}
              className="max-w-6xl pb-10 mx-auto px-4 leading-6 text-blue-500 sm:px-6 lg:px-8 hover:underline"
            >
              ←&nbsp;back
            </p>
          ) : (
            ""
          )}
          {fund !== "" ? (
            <div className="max-w-6xl pb-10 mx-auto px-4 leading-6 text-3xl font-extrabold text-gray-900 sm:px-6 lg:px-8 flex items-center justify-between">
              <img
                className="h-12 w-12 rounded-full"
                src={getFundViaParam(fund)?.logo}
                alt={getFundViaParam(fund)?.name}
              />
              {isMobile ? (
                <div className="text-center">
                  <p className="uppercase" style={{ fontSize: "24px" }}>
                    {getFundViaParam(fund)?.name}
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  <h2 className="uppercase">{getFundViaParam(fund)?.name}</h2>

                  <div className="flex items-end mt-4">
                    <p
                      className="leading-6 text-gray-900"
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    >
                      $
                      {numberWithCommas(
                        Number(chartSummaryRaw?.summary.usd).toFixed(3)
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                      className={getChangeClass(
                        Number(chartSummaryRaw?.summary.usd_24h_change)
                      )}
                    >
                      {getChangeUsd(chartSummaryRaw?.summary)}(
                      {Number(chartSummaryRaw?.summary.usd_24h_change).toFixed(
                        2
                      )}
                      %)
                      {getChangeArrow(
                        Number(chartSummaryRaw?.summary.usd_24h_change)
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                onClick={async () => {
                  setOpenLoading(true);
                  await delay(1000);
                  setModal(true);
                  setOpenLoading(false);
                }}
                style={{ cursor: "pointer" }}
                className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold shadow-sm text-sm text-white ${
                  openLoading
                    ? "bg-gray-400"
                    : "bg-gradient-to-r from-indigo-500 to-blue-500"
                } hover:from-indigo-600 hover:to-blue-600`}
              >
                {openLoading ? loadingJSX() : "Invest"}
              </div>
            </div>
          ) : (
            <h2 className="max-w-6xl capitalize pb-10 mx-auto px-4 leading-6 text-3xl font-extrabold text-gray-900 sm:px-6 lg:px-8">
              {getFundViaParam(fund)?.name}
            </h2>
          )}

          {fund !== "" ? (
            <div className="max-w-6xl pb-6 mx-auto px-4 sm:px-6 lg:px-8 md:grid md:grid-cols-2 md:gap-4">
              {isMobile ? (
                <div className="flex items-center justify-center mb-5">
                  <p
                    className="leading-6 text-gray-900"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    $
                    {numberWithCommas(
                      Number(chartSummaryRaw?.summary.usd).toFixed(3)
                    )}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                    className={getChangeClass(
                      Number(chartSummaryRaw?.summary.usd_24h_change)
                    )}
                  >
                    {getChangeUsd(chartSummaryRaw?.summary)}(
                    {Number(chartSummaryRaw?.summary.usd_24h_change).toFixed(2)}
                    %)
                    {getChangeArrow(
                      Number(chartSummaryRaw?.summary.usd_24h_change)
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  marginRight: isMobile ? "0px" : "10px",
                  marginTop: isMobile ? "6px" : "0px",
                  fontSize: "12px",
                }}
              >
                <div
                  id="container"
                  dangerouslySetInnerHTML={fundOverviewInfo()}
                ></div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Sparklines data={sparkLineRaw?.data || []}>
                  <SparklinesLine
                    style={{ fill: "none" }}
                    color={getSparkLineColor(fund)}
                  />
                </Sparklines>
              </div>
            </div>
          ) : (
            chartJSX()
          )}

          {modal && modalJSX()}

          {fund !== "" && (
            <div className="max-w-6xl pb-10 mx-auto px-4 leading-6 text-gray-900 sm:px-6 lg:px-8">
              <div className="mb-5">
                <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                  Fund Info
                </h2>
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                  {getFundInfoText()}
                </ul>
              </div>

              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">{getFundLinks()}</ul>
              </div>
            </div>
          )}

          {fund !== "" ? "" : fundTableJSX()}
        </div>
      </div>
    </div>
  );
}
