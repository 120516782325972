import React from "react";
import MainHome from "./mainHome";
import MainHistory from "./mainHistory";
import MainSettings from "./mainSettings";
import NotFound from "./notFound";
import MainReports from "./mainReports";
import MainPortfolio from "./mainPortfolio";

export default function Main(props) {
  const { path, user, portfolioData } = props;

  const getMain = () => {
    switch (path) {
      case "/dashboard":
        return <MainHome user={user} portfolioData={portfolioData} />;
      case "/history":
        return <MainHistory user={user} />;
      case "/portfolio":
        return <MainPortfolio user={user} />;
      case "/settings":
        return <MainSettings user={user} tab={"home"} />;
      case "/verification":
        return <MainSettings user={user} tab={"verification"} />;
      case "/statements":
        return <MainSettings user={user} tab={"statements"} />;
      case "/upload-form":
        return <MainSettings user={user} tab={"upload-form"} />;
      case "/password":
        return <MainSettings user={user} tab={"password"} />;
      case "/tax":
        return <MainSettings user={user} tab={"tax"} />;
      case "/ats":
        return <MainSettings user={user} tab={"ats"} />;
      default:
        return <NotFound />;
    }
  };

  return <React.Fragment>{getMain()}</React.Fragment>;
}
