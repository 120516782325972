import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import delay from "delay";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";
import seePW from "../images/passwordSee.svg";
import hiddenPW from "../images/passwordHidden.svg";
import config from "../config";
import PasswordStrengthBar from "react-password-strength-bar";

export default function Referral() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const referringAppId = parsed?.id;
  const { authCode } = useParams();

  const [verified, setVerified] = useState(0); // 0 = pending, 1 = success, 2 = fail
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [score, setScore] = useState(0);
  const [checked, setCheck] = useState(false);
  const [see, toggleSee] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [second, setSecond] = useState(false);

  const [selectAccountType, setAccountType] = useState(false);
  const [accountType, setAccountTypeNumber] = useState(1);

  const passwordNotSame = password !== password2;

  const canSubmit =
    Number(score) == 4 &&
    !passwordNotSame &&
    checked === true &&
    password != "";

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const handleRegister = async () => {
    try {
      setLoading(true);
      let url = `${base}/user/register-invite`;

      let header = {};
      let body = {
        password,
        authCode,
        referringAppId,
      };

      let data = await axios.post(url, body, header);

      let jwtToken = data.data.token;
      localStorage.setItem("immutable-jwt", jwtToken);

      await delay(1000);
      setLoading(false);

      return [true, ""];
    } catch (err) {
      console.log("error: ", err.response);
      setLoading(false);

      return [false, JSON.stringify(err.response.data?.message)];
    }
  };

  useEffect(async () => {
    try {
      let body = { authCode, refer: true };
      let header = {};

      if (!user) {
        let result = await axios.post(
          `${base}/user/validate-invite`,
          body,
          header
        );
        let user = result.data.user;

        console.log("user: ", user);

        setUser(user);

        await delay(4000);

        setVerified(1);
      }
    } catch (err) {
      setVerified(2);
    }
  }, [authCode, user, setUser]);

  const status = Number(verified);

  const signUpJSX = (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div
        className="flex flex-col justify-center"
        style={{ width: isMobile ? "90%" : "500px", margin: "0 auto" }}
      >
        <div
          style={{ marginBottom: "25px" }}
          className={`${isMobile ? "flex-col" : "flex"} items-center ${
            isMobile ? "justify-center" : "justify-between"
          }`}
        >
          <div>
            <div className="">
              <input
                value={user?.firstName || "loading..."}
                disabled={true}
                style={{
                  width: isMobile ? "100%" : "240px",
                  margin: isMobile && "0 auto",
                  borderWidth: "1px",
                  padding: "20px",
                }}
                type="text"
                name="First Name"
                id="First Name"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Legal First Name"
              />
            </div>
          </div>
          <div>
            <div className="">
              <input
                value={user?.lastName || "loading..."}
                disabled={true}
                style={{
                  width: isMobile ? "100%" : "240px",
                  margin: isMobile && "0 auto",
                  marginTop: isMobile ? "20px" : "0px",
                  borderWidth: "1px",
                  padding: "20px",
                }}
                type="text"
                name="Last Name"
                id="Last Name"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Legal Last Name"
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "25px" }}>
          <div className="mt-1 relative">
            <button
              disabled={true} // no selection because referrals are only institutional
              type="button"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
              style={{
                width: isMobile ? "100%" : "500px",
                margin: isMobile && "0 auto",
                borderWidth: "1px",
                padding: "20px",
                width: isMobile ? "100%" : "500px",
                margin: isMobile && "0 auto",
                borderWidth: "1px",
                padding: "20px",
              }}
            >
              <span
                className={`block truncate ${
                  accountType === -1 && "text-gray-400"
                }`}
              >
                {accountType === -1
                  ? "Select Account Type"
                  : accountType === 0
                  ? "Individual"
                  : "Institutional"}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </button>

            {selectAccountType && (
              <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                <ul
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-item-3"
                  className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <li
                    onClick={() => {
                      setAccountTypeNumber(0);
                      setAccountType(false);
                    }}
                    id="listbox-option-0"
                    role="option"
                    className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-200 transition duration-250"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="font-normal block truncate">
                      Individual
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      setAccountTypeNumber(1);
                      setAccountType(false);
                    }}
                    id="listbox-option-0"
                    role="option"
                    className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-200 transition duration-250"
                    style={{ cursor: "pointer" }}
                  >
                    <span className="font-normal block truncate">
                      Institutional
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: "25px" }}>
          <div className="">
            <input
              disabled={true}
              value={user?.email || "loading..."}
              style={{
                width: isMobile ? "100%" : "500px",
                margin: isMobile && "0 auto",
                borderWidth: "1px",
                padding: "20px",
                width: isMobile ? "100%" : "500px",
                margin: isMobile && "0 auto",
                borderWidth: "1px",
                padding: "20px",
              }}
              type="text"
              name="username"
              id="username"
              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Email"
            />
          </div>
        </div>
        <div style={{ marginBottom: "25px" }}>
          <div className="">
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: isMobile ? "100%" : "500px",
                  margin: isMobile && "0 auto",
                  borderWidth: "1px",
                  padding: "20px",
                  width: isMobile ? "100%" : "500px",
                  margin: isMobile && "0 auto",
                  borderWidth: "1px",
                  padding: "20px",
                }}
                type={see ? "text" : "password"}
                name="password"
                id="password"
                className={`shadow-sm z-0 focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm ${
                  passwordNotSame ? "border-red-500" : "border-gray-300"
                } rounded-md`}
                placeholder="Password"
              />
              <div className="absolute inset-y-0 z-100 right-0 pr-3 flex items-center">
                <img
                  onClick={() => toggleSee(!see)}
                  src={see ? seePW : hiddenPW}
                  alt="pw"
                  style={{ width: "25px", cursor: "pointer" }}
                />
              </div>
            </div>
            <PasswordStrengthBar
              scoreWordStyle={{ display: "none" }}
              onChangeScore={(score) => setScore(score)}
              password={password}
            />
            <div className="text-xs text-gray-600 text-left mt-2">
              Password must be at least eight characters and include at least
              one of a lowercase character [a-z], uppercase character [A-Z],
              special character (such as '!'), a number [0-9], and no spaces
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "25px" }}>
          <div className="">
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                onChange={(e) => setPassword2(e.target.value)}
                style={{
                  width: isMobile ? "100%" : "500px",
                  margin: isMobile && "0 auto",
                  borderWidth: "1px",
                  padding: "20px",
                  width: isMobile ? "100%" : "500px",
                  margin: isMobile && "0 auto",
                  borderWidth: "1px",
                  padding: "20px",
                }}
                type={see ? "text" : "password"}
                name="password"
                id="password"
                className={`shadow-sm z-0 focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm ${
                  passwordNotSame ? "border-red-500" : "border-gray-300"
                } rounded-md`}
                placeholder="Confirm Password"
              />
            </div>
            {passwordNotSame && (
              <div className="text-xs mt-1 text-red-500">
                Password must match
              </div>
            )}
          </div>
        </div>

        {errorMessage !== "" && (
          <div
            style={{
              fontSize: "12px",
              color: "red",
              overflowWrap: "anywhere",
              wordBreak: "break-word",
            }}
            className="my-2 text-center"
          >
            {errorMessage}
          </div>
        )}

        <div className="flex mt-6">
          <label className="flex items-center">
            <input
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-2 text-xs text-gray-700">
              I have read and agree to the{" "}
              <span
                style={{ cursor: "pointer" }}
                className="hover:underline text-blue-500"
              >
                Privacy Policy
              </span>{" "}
              and{" "}
              <span
                style={{ cursor: "pointer" }}
                className="hover:underline text-blue-500"
              >
                Terms of Use
              </span>
              , including consent to electronic delivery.
            </span>
          </label>
        </div>

        <div
          style={{ marginTop: "25px", marginBottom: "50px" }}
          className="flex items-center justify-between"
        >
          <span />
          <div
            onClick={async () => {
              if (canSubmit) {
                const result = await handleRegister();
                if (result[0]) {
                  setSecond(true);
                  alert("redirecting to dashboard...");
                  window.location = "/#/dashboard";
                } else {
                  setErrorMessage(result[1]);
                }
              }
            }}
            style={{ cursor: canSubmit && "pointer" }}
            className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r ${
              !canSubmit || loading
                ? "bg-gray-300"
                : "from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
            }`}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "Register"
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10"></nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                Have an account?
              </div>
              <a
                href="/#/sign-in"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div
              className="flex flex-col justify-center"
              style={{ width: isMobile ? "90%" : "500px", margin: "0 auto" }}
            >
              <div
                className={isMobile ? "text-center" : "text-left"}
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "700",
                  marginBottom: "50px",
                }}
              >
                Account Creation
              </div>
              <div style={{ marginBottom: "25px" }}>
                <div className="text-center">
                  {status === 0
                    ? "Initializing..."
                    : status === 1
                    ? signUpJSX
                    : "Invalid Token. Please contact support@immutable.co"}
                </div>
              </div>
            </div>

            <div
              className="flex items-center justify-center"
              style={{ width: isMobile ? "90%" : "700px", margin: "0 auto" }}
            >
              {isMobile ? (
                <React.Fragment>
                  <div className="flex flex-col items-center">
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      className="text-gray-700"
                      style={{
                        textAlign: "center",
                        fontSize: "8px",
                      }}
                    >
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={lock}
                    alt="secure"
                    style={{
                      height: "20px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="text-gray-700" style={{ fontSize: "10px" }}>
                    Your application, account, and device information and use
                    patterns will be shared with Immutable Holdings LLC,
                    financial partners, and service providers for application
                    processing, identity and entity verification, fraud
                    prevention, security, to tailor services to you, comply with
                    our legal obligations, and communicate with you.{" "}
                    <a className="text-blue-500" href="/#/privacy">
                      Privacy Policy
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
