import React from "react";

export default function MobileMenu(props) {
  const { ref } = props;
  return (
    <div
      ref={ref}
      style={{ top: "80px" }}
      className="absolute z-10 inset-x-0 transform shadow-lg"
    >
      <div className="bg-white">
        <div className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
          <a
            href="/#/"
            className="-m-3 p-3 flex flex-col justify-between hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <div className="flex items-center md:h-full lg:flex-col">
              <div className="w-full md:flex-1 md:flex md:flex-col md:justify-center lg:ml-0 lg:mt-4">
                <div>
                  <p className="text-center text-sm font-light uppercase text-gray-900">
                    Home
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="/#/products"
            className="-m-3 p-3 flex flex-col justify-between hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <div className="flex items-center md:h-full lg:flex-col">
              <div className="w-full md:flex-1 md:flex md:flex-col md:justify-center lg:ml-0 lg:mt-4">
                <div>
                  <p className="text-center text-sm font-light uppercase text-gray-900">
                    Products
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="/#/sign-in"
            className="-m-3 p-3 flex flex-col justify-between hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <div className="flex items-center md:h-full lg:flex-col">
              <div className="w-full md:flex-1 md:flex md:flex-col md:justify-center lg:ml-0 lg:mt-4">
                <div>
                  <p className="text-center text-sm font-light uppercase text-gray-900">
                    Login
                  </p>
                </div>
              </div>
            </div>
          </a>

          <a
            href="/#/onboard"
            className="-m-3 p-3 flex flex-col justify-between hover:bg-gray-50 transition ease-in-out duration-150"
          >
            <div className="flex items-center md:h-full lg:flex-col">
              <div className="w-full md:flex-1 md:flex md:flex-col md:justify-center lg:ml-0 lg:mt-4">
                <div>
                  <p className="text-center text-sm font-light uppercase text-gray-900">
                    Sign Up
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
