import React, { useState, useEffect } from "react";
import delay from "delay";
import InstitutionApp from "./institutionApp";
import { writeForm, socket, connectWSS } from "../utils/socket";
import immutable from "../images/immutable.svg";
import { isMobile } from "react-device-detect";
import { use100vh } from "react-div-100vh";
import Div100vh from "react-div-100vh";
import { countries } from "../geography";

export default function InstitutionalOnboarding(props) {
  const { user, location, portfolio, closeModal, fundOverride } = props;

  const fund = "institution-onboarding";

  const mobileHeight = use100vh();

  const [form, setForm] = useState();
  const [formInt, setFormInt] = useState(0);
  const [addedTX, setAddedTX] = useState();
  const [saving, setSave] = useState(false);
  const [submitting, setSubmit] = useState(false);
  const [optionsProp, setOptionsProp] = useState([]);

  connectWSS();

  async function handleSave(val) {
    if (val) {
      setSave(val);
      await delay(500);
      setSave(false);
    }
  }

  socket.on("savingForm", (value) => {
    handleSave(value);
  });

  useEffect(() => {
    // call DB to get the object
    if (form === undefined && user) {
      setForm(user.institutionOnboarding?.form || {});
      setAddedTX(user.institutionOnboarding.addedTx);
    }
  }, [form, user, formInt]);

  const handleForm = (key, e) => {
    if (form) {
      form[key] = e.target ? e.target.value : e;

      setFormInt(formInt + 1);
      writeForm(
        `{ "url": "?fund=institution-onboarding", "auth": "${
          user._id
        }", "form": ${JSON.stringify(form)} }`
      );
    }
  };

  const loadingJSX = (color = "white") => {
    return (
      <svg
        className={`animate-spin h-5 w-5 text-${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  useEffect(() => {
    const optionStates = countries.filter((option, i) => {
      return option.name === form?.["1i"];
    });

    const optionsProp = optionStates?.[0]?.states;

    setOptionsProp(optionsProp);
  }, [form?.["1i"]]);

  const getFormProgress = () => {
    // none of the above (not accredited)
    if (form?.["3d"]) return 100;

    let fields = 0;
    let totalFields = 0;
    let requiredKeys = [
      "1a",
      "1b",
      "1c",
      "1d",
      "1e",
      "1f",
      "1g",
      "1h",
      "1i",
      "1k",

      "2a",
      "2c",
      "2d",
      "2e",
      "2f",
      "2g",
      "2h",
      "2i",
      "2j",
      "2k",
      "2l",
      "2m",

      "onboardingRequestId",
      "investmentAmount",
      "4a",
    ];

    if (optionsProp?.length > 0) {
      requiredKeys.push("1j"); // only require if states is > 0
    }

    totalFields += requiredKeys.length;

    if (form?.["3a"]) {
      fields += 1;
      totalFields += 4;

      requiredKeys.push("accreditation-income-1");
      requiredKeys.push("accreditation-income-2");

      if (form?.["3i"]) {
        fields += 1;
      } else if (form?.["3h"]) {
        fields += 1;
      } else {
        // no valid selection here
      }
    } else if (form?.["3b"]) {
      fields += 1;
      totalFields += 2;

      requiredKeys.push("accreditation-networth");
    } else if (form?.["3c"]) {
      fields += 1;
      totalFields += 2;

      requiredKeys.push("accreditation-networth");
    } else {
      totalFields += 1;
    }

    // kyc/aml fields
    switch (form?.["1b"]) {
      case "Private Company":
        requiredKeys.push("privateco-certificateOfIncorporation");
        requiredKeys.push("privateco-memorandumAndAoA");
        requiredKeys.push("privateco-registerOfDirectors");
        requiredKeys.push("privateco-directorID");
        requiredKeys.push("privateco-beneficialOwners");
        requiredKeys.push("privateco-proofBeneficialOwners");

        totalFields += 6;

        break;
      case "Fund":
        requiredKeys.push("fund-ppm");
        requiredKeys.push("fund-coi");
        requiredKeys.push("fund-beneficialOwners");

        totalFields += 3;

        break;
      case "Public Body":
        requiredKeys.push("publicbody-homeStateAuthority");
        requiredKeys.push("publicbody-beneficialOwners");
        requiredKeys.push("publicbody-officialID");
        requiredKeys.push("publicbody-proofOfResidence");

        totalFields += 4;

        break;
      case "Pension Scheme":
        requiredKeys.push("pension-formation");
        requiredKeys.push("pension-trusteeDetails");
        requiredKeys.push("pension-admin");

        totalFields += 3;

        break;
      case "Private Trust":
        requiredKeys.push("privatetrust-trustDeed");
        requiredKeys.push("privatetrust-trustees");
        requiredKeys.push("privatetrust-trusteesID");
        requiredKeys.push("privatetrust-settlerID");
        requiredKeys.push("privatetrust-trustBeneficialOwners");

        totalFields += 5;

        break;
      case "Partnership":
        requiredKeys.push("partner-LP");
        requiredKeys.push("partner-agreement");
        requiredKeys.push("partner-partnerList");
        requiredKeys.push("partner-ID");
        requiredKeys.push("partner-beneficialOwners");

        totalFields += 5;

        break;
      case "Limited Liability Company":
        requiredKeys.push("llc-formation");
        requiredKeys.push("llc-operatingAgreement");
        requiredKeys.push("llc-ID");
        requiredKeys.push("llc-managerDetails");
        requiredKeys.push("llc-beneficialOwners");

        totalFields += 5;

        break;
      case "Registered Charity/Foundation":
        requiredKeys.push("charity-overview");
        requiredKeys.push("charity-register");

        totalFields += 2;

        break;
      case "School, College or University":
        requiredKeys.push("school-official");
        requiredKeys.push("school-details");
        requiredKeys.push("school-beneficialowners");

        totalFields += 3;

        break;
      default:
      // console.log("default: ");
    }

    let missingFields = [];
    for (let i = 0; i < requiredKeys.length; i++) {
      if (form?.[requiredKeys[i]]) {
        fields += 1;
      } else {
        missingFields.push(requiredKeys[i]);
      }
    }

    return (100 * (fields / totalFields)).toFixed(2);
  };

  const canSubmit = Number(getFormProgress()) === 100;

  const scrollTop = () => {
    const divToScrollTo = document.getElementById("applicationTop");
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView(true);
    }
  };

  const contentMain = () => {
    return (
      <InstitutionApp
        location={location}
        fund={fund}
        canSubmit={canSubmit}
        portfolio={portfolio}
        fundOverride={fundOverride}
        addedTX={addedTX}
        user={user}
        form={form}
        scrollTop={scrollTop}
        handleForm={handleForm}
        setForm={setForm}
      />
    );
  };

  const sufficientAmount = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed >= 100000;
  };

  const modalContent = () => {
    return (
      <div
        id="applicationTop"
        style={{
          margin: "0px auto",
          padding: "0 20px",
          maxWidth: "1124px",
          width: "100%",
          gridRowStart: "2",
          minHeight: form?.["status"] === 1 ? "200px" : "95vh",
          overFlowY: "auto",
        }}
      >
        <div
          style={
            !isMobile
              ? {
                  display: "grid",
                  gridTemplateColumns: "240px calc(100% - 280px)",
                  columnGap: "40px",
                  width: "100%",
                  marginTop: "20px",
                }
              : {
                  marginTop: "20px",
                }
          }
        >
          {!isMobile && (
            <div style={{ height: "100%" }}>
              <div
                style={{
                  position: "sticky",
                  top: "175px",
                  marginLeft: "25px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={immutable}
                  style={{ marginBottom: "35px", width: "75%" }}
                />
                <div
                  style={{ fontSize: "14px", lineHeight: "30px" }}
                  className="font-light"
                >
                  {portfolio
                    ? `Apply to invest in the ${fundOverride?.name}.`
                    : `We require this information in connection with our investor onboarding process.`}
                </div>
                {!portfolio && (
                  <div className="relative pt-8">
                    <div className="text-xs text-gray-700 font-normal pb-2 text-center flex items-center justify-between">
                      <span>Progress:</span>
                      <span>{getFormProgress()}%</span>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                      <div
                        style={{ width: `${getFormProgress()}%` }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              maxWidth: "600px",
              width: "100%",
              overFlowY: "auto",
              margin: isMobile && "0 auto",
            }}
          >
            {form?.["status"] === 4 ? (
              <React.Fragment>
                <div className="onboard-top py-4 text-sm">
                  Thank you! You have certified that you are not an accredited
                  investor. At this point, we are only allowing accredited
                  investors to invest in our offerings. To the extent that we
                  offer investment opportunities to non-accredited investors in
                  the future, we will let you know. Please email
                  support@immutable.co if you have any questions
                </div>
              </React.Fragment>
            ) : form?.["status"] === 1 ? (
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="onboard-top py-4 text-sm edit-application">
                  Your application is under review. We will update you with
                  results in 1 - 3 business days.
                </div>
              </div>
            ) : form?.["status"] === 3 &&
              user?.institutionOnboarding?.passAccreditation?.toString() ===
                "true" &&
              user?.institutionOnboarding?.passAML?.toString() !== "true" ? (
              <React.Fragment>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="onboard-top py-4 text-sm edit-application">
                  Your application passed investor accreditation. It is under
                  final KYC/AML and document review. We will update you with
                  results in 1 - 3 business days.
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {form?.["status"] === 2 && (
                  <div
                    className="flex items-center bg-blue-500 text-white text-xs font-normal px-4 py-3 mb-5"
                    role="alert"
                  >
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                    </svg>
                    <div className="inline-block">
                      {user.institutionOnboarding?.["message"] && (
                        <div>
                          <b>{user.institutionOnboarding?.["message"]}</b>
                        </div>
                      )}
                      <div
                        className={
                          user.institutionOnboarding?.["message"] && "mt-2"
                        }
                      >
                        Please resubmit and contact support@immutable.co with
                        any questions
                      </div>
                    </div>
                  </div>
                )}
                {contentMain()}

                {!portfolio &&
                form?.["4b"] &&
                sufficientAmount(form?.["investmentAmount"]) &&
                ((Number(form?.["1k"]) === 1 && form?.["onboardingSubDoc"]) ||
                  (form?.["3d"] && form?.["3e"])) ? (
                  <div
                    onClick={async () => {
                      if (
                        window.confirm(
                          `Please confirm all the information is correct. After you submit, you cannot modify the application. Continue?`
                        )
                      ) {
                        if (canSubmit) {
                          setSubmit(true);

                          if (form?.["10d"] === true) {
                            handleForm("status", 4);
                          } else {
                            handleForm("status", 1);
                          }

                          await delay(1500);
                          scrollTop();
                          setSubmit(false);
                        } else {
                          alert("Form is not complete");
                        }
                      }
                    }}
                    style={{ cursor: "pointer", width: "140px" }}
                    className={`inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-blue-600 ${
                      submitting ? "bg-gray-400" : "bg-blue-500"
                    }`}
                  >
                    {submitting ? loadingJSX() : "Submit All"}
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  };

  const modalFooter = () => {
    return (
      <footer
        style={{
          position: "sticky",
          bottom: "0",
          zIndex: isMobile ? "100" : "-100",
          backgroundColor: isMobile && "#F6F9FC",
          borderTop: isMobile && "2px solid white",
          marginTop: isMobile && "10px",
        }}
      >
        {!portfolio && isMobile && (
          <div className="relative pt-4 px-8">
            <div className="text-xs text-gray-700 font-normal pb-2 text-center flex items-center justify-between">
              <span>Progress:</span>
              <span>{getFormProgress()}%</span>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${getFormProgress()}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
              ></div>
            </div>
          </div>
        )}
        <div
          className={`max-w-7xl mx-auto ${
            isMobile ? "pb-2" : "py-12"
          } px-4 sm:px-6 flex items-center ${portfolio && "pt-4 pb-4"} ${
            isMobile ? "justify-center" : "justify-between"
          } lg:px-8`}
        >
          <div className="flex justify-center space-x-6 md:order-2">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Immutable Holdings, LLC</span>
              <img
                src={immutable}
                style={{ height: isMobile ? "20px" : "30px" }}
              />
            </a>
          </div>
          <div className="mt-8 md:mt-0 md:order-1"></div>
        </div>
      </footer>
    );
  };

  const modalHeader = () => {
    return isMobile ? (
      <div
        className="py-3 px-4 flex items-center justify-between"
        style={{ borderBottom: "2px solid white" }}
      >
        <div className="">
          <nav className="flex space-x-10">
            <div className="text-left font-bold text-sm">
              {portfolio
                ? `${fundOverride?.name} Application`
                : "Institution Onboarding"}
            </div>
          </nav>
          <div className="text-xs font-light">
            {saving ? "saving..." : "Changes saved."}
          </div>
        </div>
        <div
          onClick={async () => {
            setSave(true);
            await delay(1000);
            setSave(false);

            if (portfolio) {
              closeModal(false);
            } else {
              localStorage.removeItem("immutable-jwt");
              window.location = "#";
            }
          }}
          style={{ cursor: "pointer", width: "100px" }}
          className="ml-8 inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-gray-600 bg-gray-200 hover:bg-gray-300"
        >
          {portfolio ? "Save" : "Logout"}
        </div>
      </div>
    ) : (
      <div
        style={{
          position: "sticky",
          top: "0px",
          backgroundColor: "#F6F9FC",
          zIndex: 1000,
        }}
        className="relative"
      >
        <div className="relative z-20">
          <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
            <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
              <nav className="flex space-x-10">
                <div
                  style={{ fontSize: "18px" }}
                  className="text-center font-bold"
                >
                  {portfolio
                    ? `${fundOverride?.name} Application`
                    : "Institution Onboarding"}
                </div>
              </nav>
              <div className="flex items-center md:ml-12">
                <div className="text-sm font-light">
                  {saving ? "saving..." : "Changes saved."}
                </div>
                <div
                  onClick={async () => {
                    setSave(true);
                    await delay(1000);
                    setSave(false);

                    if (portfolio) {
                      closeModal(false);
                    } else {
                      localStorage.removeItem("immutable-jwt");
                      window.location = "#";
                    }
                  }}
                  style={{ cursor: "pointer", width: "100px" }}
                  className="ml-8 inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-gray-600 bg-gray-200 hover:bg-gray-300"
                >
                  {portfolio ? "Save" : "Logout"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modalJSX = () => {
    return (
      <Div100vh>
        <div className={`fixed z-10 inset-0 form-dialog`}>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div
                style={{ backgroundColor: "#174FE8" }}
                className="absolute inset-0 opacity-90"
              ></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              style={{
                minWidth: "95%",
                height: isMobile ? `${mobileHeight - 100}px` : "95vh",
                backgroundColor: "#F6F9FC",
                padding: 0,
              }}
              className={`inline-block align-bottom rounded-lg px-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6`}
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                {modalHeader()}
                <div
                  style={{ zIndex: 10 }}
                  className="flex items-center justify-center"
                >
                  {modalContent()}
                </div>
                {modalFooter()}
              </div>
            </div>
          </div>
        </div>
      </Div100vh>
    );
  };

  return (
    <div
      className="p-8 flex items-center justify-center"
      style={{
        height: isMobile ? mobileHeight : "80vh",
        maxHeight: isMobile ? mobileHeight : "80vh",
        fontSize: "40px",
        lineHeight: "10px",
        fontWeight: "600",
      }}
    >
      {modalJSX()}
    </div>
  );
}
