import React, { useEffect, useState } from "react";
import axios from "axios";

import Menu from "./menu";
import SideBar from "./sideBar";
import config from "../config";
import Main from "./mainContent";
import IndividualOnboarding from "./individualOnboarding";
import InstitutionalOnboarding from "./institutionalOnboarding";
import { useLocation } from "react-router-dom";
import publicIp from "public-ip";

export default function Dashboard() {
  const location = useLocation();

  const [user, setUser] = useState("");
  const [portfolioData, setPortfolioData] = useState("");
  const [openSideBar, setOpenSideBar] = useState(false);

  const jwt = localStorage.getItem("immutable-jwt");

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

  useEffect(async () => {
    try {
      if (jwt) {
        const ipData = await getClientIp();

        const header = {
          headers: {
            Authorization: `Bearer ${jwt}`,
            ipData: ipData,
          },
        };

        const data = await axios.get(`${base}/user`, header);

        setUser(data.data.user);
        setPortfolioData(data.data.portfolioData);
      } else {
        alert("unauthorized");
        window.location = "/#/";
      }
    } catch (err) {
      console.log("unauthorized: ", err);
      alert("unauthorized");
      window.location = "/#/";
    }
  }, [jwt]);

  const onSideBarChange = (val) => {
    setOpenSideBar(val);
  };

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {!user ? (
        ""
      ) : user.accountType === "admin" ? (
        <React.Fragment>
          <SideBar
            setOpenSideBar={onSideBarChange}
            openSideBar={openSideBar}
            user={user}
            path={location.pathname}
          />

          <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">
            <Menu
              user={user}
              path={location.pathname}
              setOpenSideBar={onSideBarChange}
              openSideBar={openSideBar}
            />
            <Main
              user={user}
              portfolioData={portfolioData}
              path={location.pathname}
            />
          </div>
        </React.Fragment>
      ) : (Number(user?.individualOnboarding?.status) === 3 ||
          Number(user?.institutionOnboarding?.status) === 3) &&
        (user.institutional
          ? user?.institutionOnboarding?.passAccreditation?.toString() ===
              "true" &&
            user?.institutionOnboarding?.passAML?.toString() === "true"
          : user?.individualOnboarding?.passAccreditation?.toString() ===
              "true" &&
            user?.individualOnboarding?.passAML?.toString() === "true") ? (
        <React.Fragment>
          <SideBar
            setOpenSideBar={onSideBarChange}
            openSideBar={openSideBar}
            user={user}
            path={location.pathname}
          />

          <div className="flex-1 overflow-auto focus:outline-none" tabindex="0">
            <Menu
              user={user}
              path={location.pathname}
              setOpenSideBar={onSideBarChange}
              openSideBar={openSideBar}
            />
            <Main
              user={user}
              portfolioData={portfolioData}
              path={location.pathname}
            />
          </div>
        </React.Fragment>
      ) : user.institutional ? (
        <InstitutionalOnboarding
          user={user}
          portfolio={false}
          path={location.pathname}
        />
      ) : (
        <IndividualOnboarding
          user={user}
          portfolio={false}
          path={location.pathname}
        />
      )}
    </div>
  );
}
