import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import delay from "delay";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";
import config from "../config";

export default function Disclaimer() {
  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10"></nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                Don't have an account?
              </div>
              <a
                href="/#/onboard"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className="flex flex-col justify-center"
              style={{ margin: "0 auto" }}
            >
              <div
                className="text-center"
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "700",
                  marginTop: "20px",
                }}
              >
                Disclaimer
              </div>
              <div className="mt-6 bg-white rounded-sm shadows">
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Carefully consider the risk factors, investment objectives,
                  fees, expenses, and other information associated with each
                  Immutable Fund before investing. All Funds are speculative
                  investments that involve a high degree of risk, uncertainty,
                  and are not suitable for investors that cannot afford loss of
                  the entire investment.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  This website is neither an offer to sell nor a solicitation to
                  buy shares in any Fund. Any such offer or solicitation will be
                  made solely through definitive offering documents, identified
                  as such, which will contain information about each Fund's
                  investment objectives and terms and conditions of an
                  investment and may also describe risks and tax information
                  related to an investment therein and which qualifies in its
                  entirety the information set forth on this website.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The shares of any Fund are not registered under the Securities
                  Act of 1933 (the “Securities Act”), the Securities Exchange
                  Act of 1934 (the “Securities Exchange Act”), the Investment
                  Company Act of 1940 (the “Investment Company Act”), or any
                  state securities commission or other regulatory body.
                  Immutable Asset Management LLC is not registered as an
                  Investment Adviser under the Investment Advisers Act of 1940
                  (the “Advisers Act”), and is not registered as a Commodity
                  Pool Operator or Commodity Trading Adviser under the Commodity
                  Exchange Act (the “Commodity Exchange Act”). Shares of each
                  Fund are offered in private placements pursuant to the
                  exemption from registration provided by Section 4(a)(2) of the
                  Securities Act of 1933 and Rule 506 of Regulation D
                  promulgated thereunder and other exemptions of similar import
                  in the laws of the states and jurisdictions where the offering
                  will be made and are only issued and sold directly by the Fund
                  to accredited investors. As a result, the shares of each Fund
                  when initially sold are restricted and subject to significant
                  limitations on transfer and resale. Prospective investors in
                  any Fund should very carefully consider such risks prior to
                  making any investment decision, including the fact that the
                  Funds do not intend to offer a redemption program.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The contents of this website should not be relied upon as
                  research, investment advice, or a recommendation regarding any
                  products, strategies, or any investment opportunity in
                  particular. This material is strictly for illustrative,
                  educational, or informational purposes and is subject to
                  change. Investors should not base an investment decision upon
                  the content herein and are strongly recommended to seek
                  independent financial advice upon any investment which they
                  are contemplating. The material contained or referred to
                  herein has been obtained, derived or is otherwise based upon
                  sources which are believed to be reliable. No guarantee,
                  however, can be (or is) provided in relation to the accuracy
                  or completeness of the same. To the extent permissible at law,
                  Immutable does not accept:
                  <ul className="pt-3 list-disc list-inside">
                    <li>
                      any liability arising from the use, misuse or non-use of
                      the material contained or referred to herein; or
                      <li>
                        responsibility for any financial loss incurred as a
                        result of a decision to invest in one or more of the
                        Immutable Funds.
                      </li>
                    </li>
                  </ul>
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The views and sentiments expressed or which are reflected in
                  this website, are subject to change from time to time and
                  without notice. Immutable may (and does intend), from to time,
                  to prepare and issue further information on this website. This
                  further information may be inconsistent with, and reach
                  different conclusions to, the information contained or
                  referred to herein. Please note that Immutable is under no
                  obligation to ensure that such information is brought to the
                  attention of any user of this website.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The content of this website is subject to copyright with all
                  rights reserved. No permission is granted to copy, distribute,
                  modify, post, or frame any text, graphics, video audio,
                  software code or user interface design or logos.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Unauthorized use or exploitation of such marks and names is
                  strictly prohibited. Further, unauthorized downloading,
                  re-transmission, storage in any medium, copying,
                  redistribution, reproduction, or republication for any purpose
                  is strictly prohibited.
                </div>
              </div>
            </div>
            <div
              className="flex items-center justify-center py-10"
              style={{ margin: "0 auto" }}
            >
              {isMobile ? (
                <React.Fragment>
                  <div className="flex flex-col items-center">
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      className="text-gray-700"
                      style={{
                        textAlign: "center",
                        fontSize: "8px",
                      }}
                    >
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={lock}
                    alt="secure"
                    style={{
                      height: "20px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="text-gray-700" style={{ fontSize: "10px" }}>
                    Your application, account, and device information and use
                    patterns will be shared with Immutable Holdings LLC,
                    financial partners, and service providers for application
                    processing, identity and entity verification, fraud
                    prevention, security, to tailor services to you, comply with
                    our legal obligations, and communicate with you.{" "}
                    <a className="text-blue-500" href="/#/privacy">
                      Privacy Policy
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
