import React, { useState, useRef, useEffect } from "react";
import { Select, SIZE } from "baseui/select";
import { isMobile } from "react-device-detect";

export default function SelectMenu(props) {
  const {
    options,
    currentSelection,
    setSelection,
    placeholder,
    label,
    regionBoolean,
    countryReference,
    disabled,
  } = props;

  const [openMenu, setOpenMenu] = useState(false);

  const optionStates = options.filter((option, i) => {
    return option.name === countryReference;
  });

  const disabledButton = disabled || false;

  const optionsProp = regionBoolean ? optionStates?.[0]?.states || [] : options;

  const selectRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleSelect = (option) => {
    setSelection(option?.name);
    setOpenMenu(false);
  };

  const getSelectedObject = (selection) => {
    for (let i = 0; i < optionsProp.length; i++) {
      let optionSelect = optionsProp[i];
      if (optionSelect?.name?.toLowerCase() === selection?.toLowerCase()) {
        return optionSelect;
      }
    }

    return { name: selection };
  };

  return (
    <React.Fragment>
      <div ref={selectRef} className="relative">
        <div className="form-question">{label}</div>
        <div className="form-space" />
        <Select
          size={SIZE.mini}
          options={optionsProp}
          value={getSelectedObject(currentSelection)}
          placeholder={
            !currentSelection
              ? optionsProp.length === 0
                ? "n/a"
                : placeholder
              : ""
          }
          labelKey="name"
          clearable
          valueKey="name"
          disabled={disabledButton}
          maxDropdownHeight={"288px"}
          onChange={(params) => handleSelect(params.option)}
          overrides={{
            Dropdown: {
              style: {
                borderRadius: "6px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            },
            Popover: {
              props: {
                overrides: {
                  Body: {
                    style: {
                      borderRadius: "6px",
                      boxShadow: "none",
                      marginTop: "6px",
                    },
                  },
                  Inner: {
                    style: {
                      borderRadius: "6px",
                      boxShadow: "none",
                    },
                  },
                },
              },
            },
            DropdownContainer: {
              style: {
                borderRadius: "6px",
              },
            },
            ControlContainer: {
              style: {
                backgroundColor: "white",
                marginTop: "2px",
                outline: "none",
                border: "none",
                borderRadius: "90px",
              },
            },
            SingleValue: {
              style: {
                backgroundColor: "white",
                borderRadius: "0.375rem",
              },
            },
            ValueContainer: {
              style: {
                backgroundColor: "white",
                borderRadius: "0.375rem",
              },
            },
            Root: {
              style: {
                fontSize: "14px",
                color: "#222222",
                height: "35px",
                borderRadius: "0.375rem",
                border: "1px solid #D1D5DB",
                backgroundColor: "white",
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
