// eslint-disable
export const getFundHelper = (fund) => {
  switch (fund) {
    case "defi-index-fund":
      return [];
    case "equity-index-fund":
      return [];
    case "hbar-trust":
    case "btc-trust":
    case "ethereum-trust":
      return {
        info: [
          {
            first: "Minimum Investment",
            second: "$100,000",
            third: "The minimum investment per subscription",
          },
          {
            first: "Next Subscription Date",
            second: "Every weekday at 4pm EST",
            third:
              "In order to subscribe, all investor funds must be received no later than 4pm EST on the day prior to the subscription",
          },
          {
            first: "Management Fee",
            second: "2.0%",
            third: "",
          },
          {
            first: "Performance Fee",
            second: "0%",
            third: "",
          },
        ],
        links: [
          {
            first: "Investor Fact Sheet",
            second: "",
            third: "https://google.com",
          },
          {
            first: "Private Placement Memorandum",
            second: "",
            third: "https://google.com",
          },
          {
            first: "Trust Agreement",
            second: "",
            third: "https://google.com",
          },
          {
            first: "Sample Subscription Document",
            second: "",
            third: "https://google.com",
          },
        ],
      };
    default:
      return [];
  }
};

export default {
  getFundHelper,
};
