import React, { useState, useEffect } from "react";
import isValidEmail from "is-valid-email";
import config from "../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Hotkeys from "react-hot-keys";
import delay from "delay";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";
import MobileMenu from "./mobileMenu";
import publicIp from "public-ip";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [checked, setCheck] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetConfirmationPage, setResetConfirmationPage] = useState(false);

  const history = useHistory();

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const canSubmit = email != "" && isValidEmail(email) && password != "";

  const jwt = localStorage.getItem("immutable-jwt");
  useEffect(async () => {
    try {
      if (jwt) {
        const header = {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        };

        await axios.get(`${base}/user`, header);
        window.location = "/#/dashboard";
      }
    } catch (err) {
      console.log("error fetching user");
    }
  }, []);

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      let url = `${base}/user/reset-password`;

      let header = {};
      let body = {
        email,
      };

      let data = await axios.post(url, body, header);

      return [true, ""];
    } catch (err) {
      return [false, JSON.stringify(err.response.data?.message)];
    }
  };

  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });

  const handleLogin = async () => {
    try {
      setLoading(true);
      let url = `${base}/user/login`;

      const ipData = await getClientIp();

      let header = {
        headers: {
          ipData: ipData,
        },
      };
      let body = {
        email,
        password,
        rememberMe: checked,
      };

      let data = await axios.post(url, body, header);

      let jwtToken = data.data.token;
      localStorage.setItem("immutable-jwt", jwtToken);

      return [true, ""];
    } catch (err) {
      return [false, JSON.stringify(err.response?.data?.message)];
    }
  };

  return (
    <Hotkeys
      keyName="command+a"
      onKeyDown={(keyName, e, handle) => {
        // prevent select all
        e.preventDefault();

        // console.log("keyName: ", keyName);
        // console.log("e: ", e);
        // console.log("handle: ", handle);

        history.push("/admin-sign-in");
      }}
    >
      <div className="bg-white">
        <header>
          <div className="relative bg-white">
            <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="#">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-8"
                    src={immutableLogo}
                    alt=""
                  />
                </a>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button
                  onClick={() => setMobileMenu(!mobileMenu)}
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                >
                  <span className="sr-only">Open menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              {mobileMenu && <MobileMenu />}

              <nav className="hidden md:flex space-x-10"></nav>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                  Don't have an account?
                </div>
                <a
                  href="/#/onboard"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div className="relative">
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div
                className="flex flex-col justify-center"
                style={{ width: isMobile ? "90%" : "500px", margin: "0 auto" }}
              >
                {resetConfirmationPage ? (
                  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div
                      className="flex flex-col justify-center"
                      style={{ width: "500px", margin: "0 auto" }}
                    >
                      <div
                        className="flex items-center justify-center"
                        style={{ width: "100%" }}
                      >
                        <img
                          src={immutableLogo}
                          style={{
                            width: "300px",
                            height: "auto",
                            marginBottom: "25px",
                            marginTop: "80px",
                          }}
                          alt="Immutable Asset Management"
                        />
                      </div>
                      <div
                        className="text-center"
                        style={{
                          fontSize: "16px",
                          lineHeight: "34px",
                          marginBottom: "50px",
                        }}
                      >
                        A reset link has been sent to this email: <b>{email}</b>
                        . Please check your inbox in a few minutes. If you don’t
                        see an email in your inbox, please check your spam
                        folder.
                      </div>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div
                      className={isMobile ? "text-center" : "text-left"}
                      style={{
                        fontSize: "24px",
                        lineHeight: "34px",
                        fontWeight: "700",
                        marginBottom: "50px",
                      }}
                    >
                      {forgotPassword ? "Reset your password" : "Sign In"}
                    </div>
                    <div style={{ marginBottom: "25px" }}>
                      <div className="">
                        {!isValidEmail(email) && email != "" && (
                          <div style={{ color: "red", fontSize: 12 }}>
                            invalid email format
                          </div>
                        )}
                        <input
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          style={{
                            width: isMobile ? "100%" : "500px",
                            margin: isMobile && "0 auto",
                            borderWidth: "1px",
                            padding: "20px",
                          }}
                          type="text"
                          name="username"
                          id="username"
                          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    {!forgotPassword ? (
                      <React.Fragment>
                        <div className="flex items-center justify-between">
                          <span />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => setForgotPassword(true)}
                            className="text-blue-500 hover:text-gray-700 font-medium text-sm mb-2"
                          >
                            Forgot your password?
                          </span>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          <div className="">
                            <input
                              tabIndex="0"
                              onChange={(e) => setPassword(e.target.value)}
                              onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                  if (canSubmit) {
                                    const result = await handleLogin();

                                    await delay(1000);

                                    setLoading(false);

                                    if (result[0]) {
                                      window.location = "/#/dashboard";
                                    } else {
                                      setErrorMessage(result[1]);
                                    }
                                  }
                                }
                              }}
                              style={{
                                width: isMobile ? "100%" : "500px",
                                margin: isMobile && "0 auto",
                                borderWidth: "1px",
                                padding: "20px",
                              }}
                              type="password"
                              name="password"
                              id="password"
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Password"
                            />
                          </div>
                        </div>

                        {errorMessage !== "" && (
                          <div
                            style={{
                              fontSize: "12px",
                              color: "red",
                              overflowWrap: "anywhere",
                              wordBreak: "break-word",
                            }}
                            className="my-2 text-center"
                          >
                            {errorMessage}
                          </div>
                        )}

                        <div
                          className="flex mb-2 mt-2"
                          style={{ width: "100%" }}
                        >
                          <label
                            style={{ width: "100%" }}
                            className="flex items-center justify-between"
                          >
                            <div className="flex items-center text-sm">
                              <input
                                defaultChecked={checked}
                                onChange={(e) => {
                                  setCheck(e.target.checked);
                                }}
                                type="checkbox"
                                className="form-checkbox"
                              />
                              <span className="ml-2 text-sm font-medium text-gray-700">
                                Remember me
                              </span>
                            </div>
                            <span />
                          </label>
                        </div>

                        <div
                          style={{ marginTop: "25px", marginBottom: "50px" }}
                          className="flex items-center justify-between"
                        >
                          <span />
                          <div
                            onClick={async () => {
                              if (canSubmit) {
                                const result = await handleLogin();

                                await delay(1000);

                                setLoading(false);

                                if (result[0]) {
                                  window.location = "/#/dashboard";
                                } else {
                                  setErrorMessage(result[1]);
                                }
                              }
                            }}
                            style={{ cursor: canSubmit && "pointer" }}
                            className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-center text-sm font-light uppercase text-white bg-gradient-to-r ${
                              !canSubmit || loading
                                ? "bg-gray-300"
                                : "from-indigo-500 to-blue-500 hover:from-indigo-700 hover:to-blue-700"
                            }`}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            ) : (
                              "Sign In"
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div
                        style={{ marginTop: "25px", marginBottom: "50px" }}
                        className="flex items-center justify-between"
                      >
                        <span />
                        <div
                          onClick={async () => {
                            let result = await handleResetPassword();

                            await delay(1000);

                            setLoading(false);

                            if (result[0]) {
                              setResetConfirmationPage(true);
                            } else {
                              alert("error while resetting password");
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-center text-sm font-light uppercase text-white bg-gradient-to-r ${
                            loading
                              ? "bg-gray-300"
                              : "from-indigo-500 to-blue-500 hover:from-indigo-700 hover:to-blue-700"
                          }`}
                        >
                          {loading ? (
                            <svg
                              className="animate-spin h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            "Reset"
                          )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

              <div
                className="flex items-center justify-center"
                style={{ width: isMobile ? "90%" : "700px", margin: "0 auto" }}
              >
                {isMobile ? (
                  <React.Fragment>
                    <div className="flex flex-col items-center">
                      <img
                        src={lock}
                        alt="secure"
                        style={{
                          height: "20px",
                          marginBottom: "10px",
                        }}
                      />
                      <div
                        className="text-gray-700"
                        style={{
                          textAlign: "center",
                          fontSize: "8px",
                        }}
                      >
                        Your application, account, and device information and
                        use patterns will be shared with Immutable Holdings LLC,
                        financial partners, and service providers for
                        application processing, identity and entity
                        verification, fraud prevention, security, to tailor
                        services to you, comply with our legal obligations, and
                        communicate with you.{" "}
                        <a className="text-blue-500" href="/#/privacy">
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginLeft: "20px",
                        marginRight: "10px",
                      }}
                    />
                    <div className="text-gray-700" style={{ fontSize: "10px" }}>
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </Hotkeys>
  );
}
