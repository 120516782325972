const funds = [
  {
    name: "BTC-Trust 1",
  },
  {
    name: "HBAR-Trust 1",
  },
];

module.exports = { funds };
