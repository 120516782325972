import React from "react";

export default function NotFound() {
  return (
    <div
      className="p-8 flex items-center justify-center"
      style={{
        height: "80vh",
        fontSize: "40px",
        lineHeight: "48px",
        fontWeight: "600",
      }}
    >
      <div className="text-center">
        <div className="my-4" style={{ fontSize: "60px" }}>
          404
        </div>
        <div style={{ color: "rgb(92, 92, 92)" }} className="my-10">
          Go back{" "}
          <a
            className="underline"
            style={{ color: "black" }}
            href="/#/dashboard"
          >
            home?
          </a>
        </div>
      </div>
    </div>
  );
}
