import "./App.css";
import React, { Suspense } from "react";
import { HashRouter, Route } from "react-router-dom";
import Home from "../components/home";
import Onboard from "../components/onboard";
import Dashboard from "../components/dashboard";
import Login from "../components/login";
import AdminLogin from "../components/adminLogin";
import Verify from "../components/verify";
import ChangeEmail from "../components/changeEmail";
import Referral from "../components/referral";
import Reset from "../components/reset";

import Terms from "../components/terms";
import Privacy from "../components/privacy";
import Disclaimer from "../components/disclaimer";
import FAQ from "../components/faq";

import Bitcoin from "../components/bitcoin";
import Hedera from "../components/hedera";

export default function App() {
  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/onboard" component={Onboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/reset-password/:authCode" component={Reset} />

        <Route exact path="/settings" component={Dashboard} />
        <Route exact path="/verification" component={Dashboard} />
        <Route exact path="/password" component={Dashboard} />
        <Route exact path="/statements" component={Dashboard} />
        <Route exact path="/upload-form" component={Dashboard} />
        <Route exact path="/tax" component={Dashboard} />
        <Route exact path="/ats" component={Dashboard} />

        <Route exact path="/profile" component={Dashboard} />
        <Route exact path="/help" component={Dashboard} />
        <Route exact path="/change-email/:authCode" component={ChangeEmail} />
        <Route exact path="/verify/:authCode" component={Verify} />

        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/faq" component={FAQ} />

        <Route exact path="/bitcoin" component={Bitcoin} />
        <Route exact path="/hedera" component={Hedera} />

        <Route exact path="/referral/:authCode" component={Referral} />
        <Route exact path="/sign-in" component={Login} />
        <Route exact path="/admin-sign-in" component={AdminLogin} />
        <Route exact path="/reports" component={Dashboard} />
        <Route exact path="/portfolio" component={Dashboard} />
        <Route exact path="/history" component={Dashboard} />
      </HashRouter>
    </Suspense>
  );
}
