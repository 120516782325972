import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";

export default function Privacy() {
  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10"></nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                Don't have an account?
              </div>
              <a
                href="/#/onboard"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className="flex flex-col justify-center"
              style={{ margin: "0 auto" }}
            >
              <div
                className="text-center"
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "700",
                  marginTop: "20px",
                }}
              >
                PRIVACY POLICY
              </div>
              <div
                className="text-center"
                style={{ marginBottom: "15px", fontSize: "14px" }}
              >
                Last Updated: February 20, 2021
              </div>
              <div className="mt-6 bg-white rounded-sm shadows">
                <div className="py-3" style={{ fontSize: "12px" }}>
                  This Privacy Policy governs the information Immutable collects
                  on its websites and through its online services (the
                  “Service”). This Privacy Policy is incorporated by reference
                  into the{" "}
                  <a className="text-blue-500" href="/#/terms">
                    Immutable Terms of Service
                  </a>{" "}
                  (the “Terms”). The terms “Immutable,” “we,” “us,” and “our”
                  include [Immutable Asset Management, LLC] and our affiliates
                  and subsidiaries. Any defined terms used and not defined
                  herein shall have the meanings given to such terms in the
                  Terms. This Privacy Policy explains how Immutable may collect,
                  use, and disclose information we obtain through the Service.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  By creating an account on the Immutable site (an “Immutable
                  Site”) or by otherwise submitting Personal Information to us
                  through an Immutable Site, you give your consent to the
                  collection and use of your Personal Information as explained
                  in this Policy. This Policy is part of our{" "}
                  <a className="text-blue-500" href="/#/terms">
                    Immutable Terms of Service
                  </a>
                  . and all capitalized terms used but not defined in this
                  Policy have the meaning given to them in the Terms of Service.
                  The term “Personal Information” refers to non-public
                  information that personally relates to or identifies you as an
                  individual or entity.
                </div>
                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  When We Collect your Information
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>When Submitting an Investor Information Request Form.</b>{" "}
                  If you submit an Investor Information Request Form through the
                  Service, then you must provide us with certain information,
                  such as your name, country, email address, and investor
                  accreditation status.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Registration.</b> If you register an Account on the
                  Service, then you must provide us with certain information,
                  such as your name, address, and contact email address.
                  Additionally, as a part of the subscription process, we may
                  collect certain other information from you, such as your tax
                  ID number, social security number, and information concerning
                  your financial history, income, and net worth. If you are
                  accessing the Service on behalf of an Organization, you may be
                  required to provide the Organization’s certificate of
                  incorporation and date of formation.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>In Connection with an Investment.</b> In order to process
                  payments of amounts you invest in the Fund, we collect certain
                  payment information, including your bank routing numbers, bank
                  account numbers, and billing address.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Using the Service.</b> We collect information about your
                  use of the Service. For example, we collect the information
                  that you provide to us in connection with accessing the
                  Investor Portal to view the performance of the Fund or your
                  investment.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Communications.</b> If you contact us directly, we may
                  receive additional information about you. For example, when
                  you contact us through our general investor email address, we
                  may receive your name, email address, phone number, the
                  contents of a message or attachments that you may send to us,
                  and other information you choose to provide.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Location Information.</b> We collect and store your
                  location information if you enable your device to send it to
                  us, such as by use of geolocation features in the device(s)
                  through which you access the Service, or by inference from
                  other information we collect (for example, your IP address
                  indicates the general geographic region from which you are
                  connecting to the Internet).
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>
                    Cookies, Automatic Data Collection, and Related
                    Technologies.
                  </b>{" "}
                  The Service collects and stores information that is generated
                  automatically as you use it, including your preferences and
                  anonymous usage statistics.
                  <br />
                  When you visit the Service, we and our third-party service
                  providers receive and record information on our server logs
                  from your browser, including your IP address, and from cookies
                  and similar technology. Our service providers may collect
                  information about your online activities over time and across
                  different online services. Cookies are small text files placed
                  on your computer browser when you visit a website. Most
                  browsers allow you to block and delete cookies. However, if
                  you do that, then the Service may not work properly.
                  <br />
                  We work with analytics providers who may use cookies and
                  similar technologies to collect and analyze information about
                  use of the Service and report on activities and trends. These
                  services may also collect information regarding the use of
                  other websites, apps and online resources. One of the service
                  providers that we use for data analytics is Google Analytics.
                  If you do not want Google Analytics to collect and use
                  information about your use of the Service, then you can
                  install an opt-out in your web browser
                  (https://tools.google.com/dlpage/gaoptout/). By using the
                  Service, you are authorizing us to gather, parse, and retain
                  data related to the provision of the Service.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  How We may use or Disclose your Information
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Internal and Service-Related Usage.</b> We use information,
                  including unique identifiers and your location for internal
                  and service-related purposes, such as data analytics, and may
                  share this information with service providers to allow us to
                  provide, maintain, and improve the Service.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Communications.</b> We may send email to the email address
                  you provide to us to verify your account and for informational
                  and operational purposes, such as account management, customer
                  service, system maintenance, or to inform you about additional
                  products or services that may be of interest to you.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Aggregate Data.</b> We may anonymize and aggregate data
                  collected through the Service and use it for any purpose.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Legal Rights.</b> We may use the information collected
                  through the Service to enforce our Terms or other legal
                  rights.Legal Rights. We may use the information collected
                  through the Service to enforce our Terms or other legal
                  rights.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>We Use Vendors and Service Providers.</b> We may share any
                  information that we receive through the Service with vendors
                  and service providers retained in connection with the
                  provision of the Service, including our payment processors,
                  auditors, and other vendors for data analytics purposes.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>As Required by Law and Similar Disclosures.</b> We may
                  access, preserve, and disclose your information and/or other
                  account information or content if we believe doing so is
                  required or appropriate to: (i) comply with law enforcement
                  requests and legal process, such as a court order or subpoena;
                  (ii) enforce this Privacy Policy or other agreements; (iii)
                  respond to your requests; or (iv) protect your, our, or
                  others’ rights, property, or safety.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Merger, Sale, or Other Asset Transfers.</b> If we are
                  involved in a merger, acquisition, financing due diligence,
                  reorganization, bankruptcy, receivership, sale of assets, or
                  transition of service to another provider, then your
                  information may be sold or transferred as part of such a
                  transaction as permitted by law and/or contract. The use of
                  your information following any of the foregoing events will be
                  governed by the provisions of the Privacy Policy in effect at
                  the time Immutable collected such information. We may also
                  share your information within our group of companies.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>With Your Consent.With Your Consent.</b> We may also
                  disclose your information with your permission.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  How We Protect Your Information
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Keeping Your Information Safe</b>
                  We limit access to the Personal Information you provide us to
                  those employees who need access to that information in order
                  to provide you with services related to the transactions you
                  are entering into through the Immutable Sites. In keeping with
                  industry standards and practices, we maintain appropriate
                  physical, electronic and procedural safeguards, and controls
                  designed to protect your Personal Information.
                  <br />
                  We have invested in software, systems and procedures aimed at
                  offering you a safe and secure transaction environment and
                  protect your personal, financial and historical transaction
                  information. While no security system is absolutely
                  impenetrable, we plan to constantly review, refine and upgrade
                  our security technology as new tools become available.
                  <br />
                  We will delete your Personal Information when it is no longer
                  needed for these purposes. If we cannot delete it, for
                  technical reasons, entirely from our systems, we will put in
                  place appropriate measures to prevent any further processing
                  or use of the data.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>The Importance of Being Accurate</b>
                  We have security standards and procedures in place designed to
                  prevent unauthorized access to your Personal Information. A
                  key part of this process helps ensure that the Personal
                  Information we have about you is accurate and up-to-date. If
                  you ever discover inaccuracies in our data or if your Personal
                  Information changes, we urge you to notify us immediately. We
                  only use your Personal Information as appropriate to provide
                  you with quality services and to ensure security. For example,
                  we may use the information collected from you to verify your
                  identity and contact information.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Your Rights Over Personal Information</b>
                  Subject to applicable local law, you have rights regarding
                  your Personal Information, including: access, rectification,
                  erasure and blocking, restriction of use, objection to use,
                  data portability, and the lodgement of a claim to your local
                  data protection authority.
                  <br />
                  If you would like to discuss or exercise the above rights,
                  please contact us using the details set out at the end of this
                  Privacy Policy.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>An Ongoing Commitment to Your Privacy</b>
                  We are just as committed to protecting the privacy of our
                  former clients as we are our current clients. If you choose to
                  become an inactive client, your Personal Information will
                  continue to be protected by the privacy policies and
                  principles described in the then-current version of this
                  Policy. Should you redeem or otherwise dispose of your
                  investments with us, Immutable will retain your information
                  for regulatory compliance, internal record-keeping and other
                  legal purposes.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Security of your Information</b>
                  We take steps to ensure that your information is treated
                  securely and in accordance with this Privacy Policy.
                  Unfortunately, the Internet cannot be guaranteed to be 100%
                  secure, and we cannot ensure or warrant the security of any
                  information you provide to us.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>CalOPPA Disclosures</b>
                  Some web browsers incorporate a “Do Not Track” feature.
                  Because there is not yet an accepted standard for how to
                  respond to Do Not Track signals, our website does not
                  currently respond to such signals.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Children’s Privacy</b>
                  We do not knowingly collect, maintain, or use personal
                  information, as defined by the Federal Trade Commission’s
                  Children’s Online Privacy Protection Act (“COPPA”) Rule, from
                  children under 13 years of age, and no part of the Service is
                  directed to children under the age of 13. If we learn that we
                  have collected any personal information from children under
                  13, then we will promptly take steps to delete such
                  information and terminate the child’s account.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>International Users</b>
                  If you visit the Service from the European Union or other
                  regions with laws governing data collection and use, then
                  please note that you are agreeing to the transfer of your
                  information to the United States and processing globally. By
                  providing your information to the Service, you consent to any
                  transfer and processing in accordance with this Privacy
                  Policy.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>Changes to Our Privacy Policy</b>
                  Please note that, in the event of a change in policy, a
                  revised Policy will promptly be posted to the Immutable Site.
                  You agree to accept posting of a revised Policy electronically
                  on the Immutable Site as actual notice to you. Any dispute
                  over our Policy is subject to this notice.
                </div>

                <div className="py-3" style={{ fontSize: "12px" }}>
                  <b>New Uses of Information.</b>
                  From time-to-time, we may desire to use information for uses
                  not previously disclosed in our Privacy Policy. If our
                  practices change regarding previously collected information in
                  a way that would be materially less restrictive than stated in
                  the version of this Privacy Policy in effect at the time we
                  collected the information, then we will make reasonable
                  efforts to provide notice and obtain consent to any such uses
                  as may be required by law.
                </div>
              </div>
            </div>
            <div
              className="flex items-center justify-center py-10"
              style={{ margin: "0 auto" }}
            >
              {isMobile ? (
                <React.Fragment>
                  <div className="flex flex-col items-center">
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      className="text-gray-700"
                      style={{
                        textAlign: "center",
                        fontSize: "8px",
                      }}
                    >
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={lock}
                    alt="secure"
                    style={{
                      height: "20px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="text-gray-700" style={{ fontSize: "10px" }}>
                    Your application, account, and device information and use
                    patterns will be shared with Immutable Holdings LLC,
                    financial partners, and service providers for application
                    processing, identity and entity verification, fraud
                    prevention, security, to tailor services to you, comply with
                    our legal obligations, and communicate with you.{" "}
                    <a className="text-blue-500" href="/#/privacy">
                      Privacy Policy
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
