import React, { useState, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import Avatar from "./avatar";
import { connectWSS } from "../utils/socket";

export default function Menu(props) {
  const { path, user, openSideBar } = props;

  const [openProfile, setOpenProfile] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [query, setQuery] = useState("");
  const [widthInput, setWidthInput] = useState("");

  useEffect(() => {
    connectWSS();
  }, []);

  const handleSearch = (e) => {
    try {
      setQuery(e.target.value);
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const searchBarRef = useRef(null);
  useEffect(() => {
    setWidthInput(searchBarRef.current.offsetWidth);
  }, [searchBarRef]);

  const searchRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setQuery("");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const profileMenuRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setOpenProfile(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const notificationMenuRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        notificationMenuRef.current &&
        !notificationMenuRef.current.contains(event.target)
      ) {
        setOpenNotifications(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationMenuRef]);

  const getQueryResults = () => {
    let results = [
      {
        title: "Calvin Hawkins",
        subText: "calvin.hawkins@example.com",
      },
      {
        title: "Calvin Hawkins",
        subText: "calvin.hawkins@example.com",
      },
      {
        title: "Calvin Hawkins",
        subText: "calvin.hawkins@example.com",
      },
      {
        title: "Calvin Hawkins",
        subText: "calvin.hawkins@example.com",
      },
      {
        title: "Calvin Hawkins",
        subText: "calvin.hawkins@example.com",
      },
    ];

    let cutoff = 4;

    results = results.filter((_, index) => Number(index) < cutoff);

    return results.length === 0 ? (
      <li
        onClick={() => {
          setQuery("");
          // redirect to search result
        }}
        className="py-4 flex px-3 bg-gray-100"
      >
        <div style={{ width: "100%" }} className="ml-3">
          <p className="text-sm font-medium text-gray-900 text-center">
            No Results
          </p>
        </div>
      </li>
    ) : (
      results.map((item, index) => {
        return (
          <React.Fragment>
            <li
              onClick={() => {
                setQuery("");
                // redirect to search result
              }}
              className="py-4 flex px-3 hover:bg-gray-100"
            >
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {item.title}
                </p>
                <p className="text-sm text-gray-500">{item.subText}</p>
              </div>
            </li>
            {index === cutoff - 1 && (
              <li
                onClick={() => {
                  setQuery("");
                  alert("redirect to correct search page");
                }}
                className="py-4 flex px-3"
              >
                <div style={{ width: "100%" }} className="ml-3">
                  <p className="text-sm font-medium text-gray-900 text-center">
                    View All Results
                  </p>
                </div>
              </li>
            )}
          </React.Fragment>
        );
      })
    );
  };

  const beta = true;

  return (
    <div
      className={`relative flex-shrink-0 flex h-16 bg-gray-100 border-b border-gray-200`}
    >
      <button
        onClick={() => props.setOpenSideBar(!openSideBar)}
        className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          />
        </svg>
      </button>

      <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="flex-1 flex">
          <form
            ref={searchBarRef}
            className="w-full flex md:ml-0"
            action="#"
            method="GET"
          >
            <label for="search_field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div
                className="absolute inset-y-0 left-0 flex items-center pointer-events-none"
                aria-hidden="true"
              >
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                value={query}
                onChange={(e) => handleSearch(e)}
                id="search_field"
                name="search_field"
                className="block w-full h-full pl-8 bg-gray-100 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                placeholder="Search..."
                type="search"
              />
            </div>
            {!beta && query !== "" && (
              <div
                ref={searchRef}
                className="bg-white border-sm shadow-md"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: isMobile ? "65px" : "66px",
                  width: `${widthInput === "" ? "100px" : `${widthInput}px`}`,
                }}
              >
                <ul className="divide-y divide-gray-200">
                  {getQueryResults()}
                </ul>
              </div>
            )}
          </form>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          <div className="relative" ref={notificationMenuRef}>
            <button
              onClick={() => {
                setOpenProfile(false);
                setOpenNotifications(!openNotifications);
              }}
              className="bg-gray-100 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button>
          </div>

          <div className="ml-3 relative">
            <div ref={profileMenuRef}>
              <button
                onClick={() => {
                  setOpenNotifications(false);
                  setOpenProfile(!openProfile);
                }}
                className="max-w-xs bg-gray-100 rounded-sm flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-sm lg:hover:bg-gray-50"
                id="user-menu"
                aria-haspopup="true"
              >
                {user.profilePictureUrl ? (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={user.profilePictureUrl}
                    alt={user.profilePictureUrl}
                  />
                ) : (
                  <Avatar
                    width={"30px"}
                    height={"30px"}
                    fontSize={"20px"}
                    user={user}
                  />
                )}
                <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                  <span className="sr-only">Open user menu for </span>
                  {user.firstName} {user.lastName}
                </span>
                <svg
                  className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              {openNotifications && (
                <div
                  className="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div
                    onClick={() => {
                      setOpenProfile(false);
                      setOpenNotifications(!openNotifications);
                    }}
                    className="block px-4 py-2 text-sm text-center text-gray-700"
                    role="menuitem"
                  >
                    No Notifications
                  </div>
                </div>
              )}
              {openProfile && (
                <div
                  className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <a
                    href="/#/settings"
                    onClick={() => {
                      setOpenProfile(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Your Profile
                  </a>
                  <a
                    href="/#/settings"
                    onClick={() => setOpenProfile(false)}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Settings
                  </a>
                  <div
                    onClick={() => {
                      localStorage.removeItem("immutable-jwt");
                      setOpenProfile(false);
                      window.location = "#";
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    Logout
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
