const config = {
  debug: false,
  debugURL: "http://localhost:9998",
  // productionURL: "https://immutable-server.herokuapp.com",
  productionURL: "https://staging-api.immutable.co",
  defiApp: {
    "entity-details": 21,
    funding: 1,
    tax: 1,
    subscriber: 1,
    aml: 1,
    docusign: 1,
  },
  btcApp: {
    "entity-details": 21,
    funding: 1,
    tax: 1,
    subscriber: 1,
    aml: 1,
    docusign: 1,
  },
  hbarApp: {
    "entity-details": 21,
    funding: 1,
    tax: 1,
    subscriber: 1,
    aml: 1,
    docusign: 1,
  },
  faqList: [
    {
      question: "Who is Immutable Asset Management?",
      answer:
        "You can learn more about us on the team page, but we are a group of cryptocurrency veterans working who put together some of the best finance professionals in the world.",
    },
    {
      question: "Where are the digital assets held?",
      answer: `All Immutable Digital Asset Funds are held by BitGo, the world's
      premiere custodian who holds billions of dollars in digital
      assets and insures each fund up to $100,000,000. Learn more on
      <a
        className="text-blue-500 hover:underline"
        ref="https://www.bitgo.com"
        target="_blank"
      >
        BitGo.com
      </a>
      .`,
    },
    {
      question: "What is the minimum amount to invest?",
      answer: `Terms like this can be found on each independent product listing
    page, but generally minimums are in the $25,000 USD - $100,000
    USD range.`,
    },
    {
      question: "What is an accredited investor?",
      answer: `Our friends over at Investopedia have the best article on this,
    titled
    <a
      className="text-blue-500 hover:underline"
      href="https://www.investopedia.com/terms/a/accreditedinvestor.asp"
      target="_blank"
    >
      “What Is an Accredited Investor?”
    </a>
    and they are constantly keeping it up to date. tldr? Simply,
    your annual income must be over $200,000 (or $300,000 with a
    spouse) for the past 2 years, or have a net worth exceeding $1
    million (individually or with a spouse).`,
    },
    {
      question: "What products do you offer?",
      answer: `Currently we are offering the HBAR Immutable Trust and Bitcoin
      Immutable Trust products.`,
    },
    {
      question: "What is your future roadmap of products and services?",
      answer: `We have a large amount of planned products in our roadmap. The
    best way to stay up to date is to sign up for our newsletter
    located here.`,
    },
  ],
};

export default config;
