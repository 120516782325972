import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import delay from "delay";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";
import config from "../config";

export default function ChangeEmail() {
  const { authCode } = useParams();

  const [verified, setVerified] = useState(0); // 0 = pending, 1 = success, 2 = fail

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  useEffect(async () => {
    try {
      let body = { authCode };
      let header = {};

      let validateResult = await axios.post(
        `${base}/user/change-email-step2`,
        body,
        header
      );

      let jwtToken = validateResult.data.token;
      localStorage.setItem("immutable-jwt", jwtToken);

      setVerified(1);

      await delay(4000);

      window.location = "/#/dashboard";
    } catch (err) {
      setVerified(2);
    }
  }, [authCode]);

  const status = Number(verified);

  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10"></nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                Don't have an account?
              </div>
              <a
                href="/#/onboard"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div
              className="flex flex-col justify-center"
              style={{ width: isMobile ? "100%" : "500px", margin: "0 auto" }}
            >
              <div
                className="text-center"
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "700",
                  marginBottom: "50px",
                  marginTop: "80px",
                }}
              >
                Verify Email
              </div>
              <div style={{ marginBottom: "25px" }}>
                <div className="text-center">
                  {status === 0
                    ? "Changing Email..."
                    : status === 1
                    ? `✅${" "}Changed Email! Redirecting to dashboard...`
                    : "Invalid Token. Please contact support@immutable.co"}
                </div>
              </div>
            </div>

            <div
              className="flex items-center justify-center"
              style={{ width: isMobile ? "90%" : "700px", margin: "0 auto" }}
            >
              {isMobile ? (
                <React.Fragment>
                  <div className="flex flex-col items-center">
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      className="text-gray-700"
                      style={{
                        textAlign: "center",
                        fontSize: "8px",
                      }}
                    >
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={lock}
                    alt="secure"
                    style={{
                      height: "20px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="text-gray-700" style={{ fontSize: "10px" }}>
                    Your application, account, and device information and use
                    patterns will be shared with Immutable Holdings LLC,
                    financial partners, and service providers for application
                    processing, identity and entity verification, fraud
                    prevention, security, to tailor services to you, comply with
                    our legal obligations, and communicate with you.{" "}
                    <a className="text-blue-500" href="/#/privacy">
                      Privacy Policy
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
