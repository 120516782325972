import React, { useState, useRef, useEffect } from "react";
import useSWR from "swr";
import copyIcon from "../images/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { isMobile } from "react-device-detect";
import config from "../config";
import delay from "delay";
import { ExclamationIcon } from "@heroicons/react/solid";

export default function MainHistory(props) {
  const { user } = props;

  const jwt = localStorage.getItem("immutable-jwt");

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const [copyIndex, setCopyIndex] = useState(0);
  const [historyPageNum, setHistoryPageNumber] = useState(1);
  const [showMisc, setShowMisc] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [confirmMemo, setConfirmMemo] = useState(false);
  const [confirmMemoError, setConfirmMemoError] = useState(false);

  const modalRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const fetchTxURL = `${base}/user/transactions/${historyPageNum}`;
  const { data: historyFetch } = useSWR(fetchTxURL, () =>
    fetch(fetchTxURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const historyArray = historyFetch?.history || [];

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // @ts-ignore
  const prettify = (num) => {
    return numberWithCommas(Number(num).toFixed(2));
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "pending":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 capitalize">
            Pending
          </span>
        );
      case "expired":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 capitalize">
            Expired
          </span>
        );
      case "cancelled":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize">
            Cancelled
          </span>
        );
      case "completed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
            Completed
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-black-100 text-black-800 capitalize">
            n/a
          </span>
        );
    }
  };

  const copyJSX = (text, index) => {
    return (
      <CopyToClipboard
        text={text}
        onCopy={async () => {
          setCopyIndex(index);

          await delay(1500);
          setCopyIndex(0);
        }}
      >
        <img
          src={copyIcon}
          alt="copy"
          style={{
            height: 15,
            marginLeft: 4,
            cursor: "pointer",
          }}
        />
      </CopyToClipboard>
    );
  };

  const modalJSX = () => {
    return (
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            ref={modalRef}
            className="inline-block align-center bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div>
              <div className="text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {modalItem.fund} Transaction
                </h3>
                {modalItem.status === "expired" && (
                  <div className="text-sm text-red-600 mt-5">
                    Your transaction expired due to this order not being
                    completed on time.
                  </div>
                )}
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="">Status:</p>
                  <div className="ml-2">{getStatusIcon(modalItem.status)}</div>
                </div>
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="">Amount:</p>
                  <div className="ml-2">
                    ${prettify(modalItem.amount)}&nbsp;
                    {modalItem.denomination.toUpperCase()}
                  </div>
                </div>
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="text-left">Transaction Initiated:</p>
                  <div className="ml-2 text-right">
                    {moment
                      .unix(Number(modalItem.createdAt) / 1000)
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </div>
                {modalItem.createdAt !== modalItem.updatedAt && (
                  <div className="text-sm text-gray-500 mt-2 flex items-center justify-between">
                    <p className="">Last Updated:</p>
                    <div className="ml-2">
                      {moment
                        .unix(Number(modalItem.updatedAt) / 1000)
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </div>
                  </div>
                )}

                <div className="text-sm mt-5 flex items-center justify-between">
                  <p className="text-gray-500">
                    Executed Subscription Document:
                  </p>
                  <div
                    className="ml-2 text-blue-500 hover:underline"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(modalItem.executedSubDocURL, "_blank")
                    }
                  >
                    Download
                  </div>
                </div>

                <div
                  className="flex mt-5 items-center justify-between text-sm text-blue-500 hover:underline"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowMisc(!showMisc);
                  }}
                >
                  <span />
                  <span>{showMisc ? "Hide" : "Show Misc."}</span>
                </div>

                {showMisc && (
                  <React.Fragment>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Sub. Document ID</p>
                      <div className="ml-2">{modalItem.subDocID}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Transaction ID</p>
                      <div className="ml-2">{modalItem._id}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">User ID</p>
                      <div className="ml-2">{modalItem.userId._id}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Sig. Request ID</p>
                      <div className="ml-2">{modalItem.signatureRequestID}</div>
                    </div>
                    {modalItem.silvergateId && (
                      <div className="text-sm text-gray-500 flex items-center justify-between">
                        <p className="">Silvergate TXID</p>
                        <div className="ml-2">{modalItem.silvergateId}</div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {modalItem.status !== "expired" && (
                  <div>
                    <div className="mt-10 text-gray-500 font-bold text-left text-md">
                      Wire Instructions
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Bank Name</p>
                      <div className="ml-2">Silvergate Bank</div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Bank Address</p>
                      <div className="ml-2 text-right">
                        <div>128 N Broadway</div>
                        <div>Escondido, CA 92025, USA</div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">ABA / Routing Number</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 mt-3 ${
                            copyIndex === 1 && "text-green-500 font-bold"
                          }`}
                        >
                          Routing: 123123123
                          {copyJSX("123123123", 1)}
                        </div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Account Number</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 ${
                            copyIndex === 2 && "text-green-500 font-bold"
                          }`}
                        >
                          Account: 01929412
                          {copyJSX("01929412", 2)}
                        </div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Memo</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 ${
                            copyIndex === 3 && "text-green-500 font-bold"
                          }`}
                        >
                          Memo: {modalItem.memo}
                          {copyJSX(modalItem.memo, 3)}
                        </div>
                      </div>
                    </div>

                    <div className="border-l-4 border-yellow-400 px-4 py-2 my-2 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <div
                            className={`text-gray-600 ${
                              isMobile ? "text-xs" : "text-sm"
                            } flex items-center`}
                          >
                            <div className="font-bold flex items-center">
                              Memo must include&nbsp;
                              <div className="bg-yellow-100 text-yellow-800">
                                &nbsp;{modalItem.memo}&nbsp;
                              </div>
                            </div>
                            &nbsp;—&nbsp;
                          </div>
                          <div
                            className={`${
                              isMobile ? "text-xs" : "text-sm"
                            } text-gray-600 text-left text-light`}
                          >
                            Including the full memo helps us accurately
                            attribute your investment.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col my-6">
                      <label className="flex items-center">
                        <input
                          onChange={(e) => setConfirmMemo(e.target.checked)}
                          type="checkbox"
                          className="form-checkbox mr-3"
                        />
                        <span className="ml-2 text-xs text-gray-700">
                          I confirm the memo is entered as shown above
                        </span>
                      </label>
                      {confirmMemoError && (
                        <span className="text-xs mt-3 text-left font-bold text-red-600">
                          Confirm before contiuing
                        </span>
                      )}
                    </div>

                    <div
                      onClick={() => {
                        if (confirmMemo) {
                          setOpenModal(false);
                          setConfirmMemoError(false);
                        } else {
                          setConfirmMemoError(true);
                        }
                      }}
                      style={{ cursor: "pointer", width: "100%" }}
                      className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-center text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-700 hover:to-blue-700`}
                    >
                      Done
                    </div>
                  </div>
                )}

                {modalItem.status === "completed" && (
                  <React.Fragment>
                    <div className="text-sm mt-5 flex items-center justify-between">
                      <p className="text-gray-500">Shares Purchased</p>
                      <div className="ml-2 text-gray-500">
                        {prettify(Number(modalItem.sharesPurchased))} Shares
                      </div>
                    </div>
                    <div className="text-sm flex items-center justify-between">
                      <p className="text-gray-500">
                        {moment
                          .unix(modalItem.sharesPurchasedOn / 1000)
                          .format("MMMM Do YYYY, h:mm:ss a")}
                      </p>
                      <div className="ml-2 text-gray-500"></div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const historyJSX = () => {
    return historyArray.map((item, index) => {
      return (
        <li>
          <div
            onClick={() => {
              setModalItem(item);
              setOpenModal(true);
            }}
            className="block px-4 py-4 bg-white hover:bg-gray-50"
          >
            <span className="flex items-center space-x-4">
              <span className="flex-1 flex space-x-2 truncate">
                <span className="flex flex-col text-gray-500 text-sm truncate">
                  <span className="truncate">
                    {item.fund} - ({item.status})
                  </span>
                  <span>
                    <span className="text-gray-900 font-medium">
                      ${prettify(item.amount)}
                    </span>{" "}
                    {item.denomination.toUpperCase()}
                  </span>
                  <span>
                    {moment
                      .unix(Number(item.createdAt) / 1000)
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </span>
              </span>
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </li>
      );
    });
  };

  const historyJSX2 = () => {
    return historyArray.map((item, index) => {
      return (
        <tr
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalItem(item);
            setOpenModal(true);
          }}
          className="bg-white hover:bg-gray-50 transition duration-200"
        >
          <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            <div className="flex">
              <div className="group inline-flex space-x-2 truncate text-sm">
                <p className="text-gray-500 truncate group-hover:text-gray-900">
                  {item.fund}
                </p>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-medium">
              ${prettify(item.amount)}
            </span>
            &nbsp;{item.denomination.toUpperCase()}
          </td>
          <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
            {getStatusIcon(item.status)}
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            {moment
              .unix(Number(item.createdAt) / 1000)
              .format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </td>
        </tr>
      );
    });
  };

  const historyParentJSX = () => {
    return (
      <React.Fragment>
        <div className="sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden sm:hidden">
            {historyJSX()}
          </ul>

          {historyFetch?.history?.length === 0 ? (
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div
                className="flex items-center text-gray-500"
                style={{ width: "100%" }}
              >
                No Transactions
              </div>
            </nav>
          ) : (
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex-1 flex justify-between">
                <div
                  onClick={() => {
                    setHistoryPageNumber(
                      historyPageNum >= 2 ? historyPageNum - 1 : 1
                    );
                  }}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
                >
                  Previous
                </div>
                <div
                  onClick={() => {
                    setHistoryPageNumber(
                      historyPageNum <= Number(historyFetch?.pages) - 1
                        ? historyPageNum + 1
                        : Number(historyFetch?.pages)
                    );
                  }}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
                >
                  Next
                </div>
              </div>
            </nav>
          )}
        </div>

        <div className="hidden sm:block">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-sm">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Transaction
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                        Status
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {historyJSX2()}
                  </tbody>
                </table>
                {historyFetch?.history?.length === 0 ? (
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div
                      className="flex items-center text-gray-500"
                      style={{ width: "100%" }}
                    >
                      No Transactions
                    </div>
                  </nav>
                ) : (
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium px-1">
                          {(Number(historyFetch?.currentPage) - 1) * 5 + 1}
                        </span>
                        to
                        <span className="font-medium px-1">
                          {historyFetch?.history?.length}
                        </span>
                        of
                        <span className="font-medium px-1">
                          {historyFetch?.numOfResults}
                        </span>
                        results
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <div
                        onClick={() => {
                          setHistoryPageNumber(
                            historyPageNum >= 2 ? historyPageNum - 1 : 1
                          );
                        }}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Previous
                      </div>
                      <div
                        onClick={() => {
                          setHistoryPageNumber(
                            historyPageNum <= Number(historyFetch?.pages) - 1
                              ? historyPageNum + 1
                              : Number(historyFetch?.pages)
                          );
                        }}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Next
                      </div>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="pt-10 pb-20 sm:px-6 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="max-w-6xl mx-auto px-4 leading-6 text-3xl font-extrabold text-gray-900 sm:px-6 lg:px-8">
            Transaction History
          </h2>

          {openModal && modalJSX()}

          <div className="py-5">{historyParentJSX()}</div>
        </div>
      </div>
    </div>
  );
}
