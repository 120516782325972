import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import useSWR, { mutate } from "swr";
import axios from "axios";
import config from "../config";
import Avatar from "./avatar";
import { isMobile } from "react-device-detect";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import queryString from "query-string";
import greetingTime from "greeting-time";
import delay from "delay";
import copyIcon from "../images/copy.svg";
import { ExclamationIcon } from "@heroicons/react/solid";
import { countryCodeEmoji } from "country-code-emoji";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  linearGradient,
  AreaChart,
  ResponsiveContainer,
} from "recharts";

import { StatefulTooltip } from "baseui/tooltip";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled } from "baseui";

const engine = new Styletron();

export default function MainHome(props) {
  const { user } = props;
  const history = useHistory();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const jwt = localStorage.getItem("immutable-jwt");

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const fetchPortfolioURL = `${base}/user/portfolioData`;

  const dataFetch = fetch(fetchPortfolioURL, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  }).then((res) => res.json());

  const [historyPageNum, setHistoryPageNumber] = useState(1);

  const [showMisc, setShowMisc] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [confirmMemo, setConfirmMemo] = useState(false);
  const [confirmMemoError, setConfirmMemoError] = useState(false);

  const [copyBoolean, setCopied] = useState(false);

  const [currentSubDoc, setCurrentSubDocURL] = useState("");
  const [copyIndex, setCopyIndex] = useState(0);

  const [pageNum, setPageNumber] = useState(1);
  const [pageNum2, setPageNumber2] = useState(1);
  const [openApplicant, setOpenApplicant] = useState(false);
  const [applicant, setApplicant] = useState();
  const [override, setOverride] = useState(false);

  const [attachmentText, setAttachmentText] = useState("");

  const [success_check, setSuccessCheck] = useState(true);
  const [review_check, setReviewCheck] = useState(true);
  const [pending_check, setPendingCheck] = useState(true);
  const [fail_check, setFailCheck] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const [success_check2, setSuccessCheck2] = useState(true);
  const [review_check2, setReviewCheck2] = useState(true);
  const [pending_check2, setPendingCheck2] = useState(true);
  const [fail_check2, setFailCheck2] = useState(true);
  const [showFilter2, setShowFilter2] = useState(false);

  const [toolTipTime, setToolTipTime] = useState("");
  const [toolTipRate, setToolTipRate] = useState("");

  const [imgURL, setImgURL] = useState("");
  const [pdfURL, setPdfURL] = useState("");
  const [imgName, setImgName] = useState("");
  const [viewAttachment, setViewAttachment] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pagePdfNumber, setPagePdfNumber] = useState(1);

  const [loadingPost, setLoadingPost] = useState(-1);

  const [openAsset, setOpenAsset] = useState(false);
  const [asset, setAsset] = useState("btc");
  const [timePeriod, setTimePeriod] = useState("1y");

  const modalRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenModal(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const fetchTxURL = `${base}/user/transactions/${historyPageNum}`;
  const { data: historyFetch } = useSWR(fetchTxURL, () =>
    fetch(fetchTxURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const getChartColor = () => {
    switch (asset) {
      case "btc":
        return "#f2a900";
      case "eth":
        return "#019ECB";
      case "hbar":
        return "#222222";
      default:
        return "#f2a900";
    }
  };

  const getChartFormat2 = () => {
    switch (timePeriod) {
      case "1d":
        return "h:mm A";
      case "7d":
        return "D ddd, h:mm A";
      case "1m":
        return "MMM D, h:mm A";
      case "1y":
        return "MMM D, YYYY";
      case "all":
        return "MMM D, YYYY";
      default:
        return "h:mm A";
    }
  };

  const getChartFormat = () => {
    switch (timePeriod) {
      case "1d":
        return "hA";
      case "7d":
        return "ddd D";
      case "1m":
        return "D";
      case "1y":
        return "MMM";
      case "all":
        return "YYYY";
      default:
        return "hA";
    }
  };

  const assetRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (assetRef.current && !assetRef.current.contains(event.target)) {
        setOpenAsset(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assetRef]);

  const chartSummaryURL = `${base}/user/getChartSummary/${asset}`;
  const { data: chartSummaryRaw } = useSWR(chartSummaryURL, () =>
    fetch(chartSummaryURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const chartDataURL = `${base}/user/getChartData/${asset}/${timePeriod}`;
  const { data: chartDataRaw } = useSWR(chartDataURL, () =>
    fetch(chartDataURL, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPagePdfNumber(1);
  }

  const getAdminParams1 = () => {
    let parsed = {};
    parsed.success_check = success_check.toString();
    parsed.review_check = review_check.toString();
    parsed.pending_check = pending_check.toString();
    parsed.fail_check = fail_check.toString();
    parsed.theorem = user.theorem.toString();

    const stringified = queryString.stringify(parsed);

    return `?${stringified}`;
  };

  const getAdminParams2 = () => {
    let parsed = {};
    parsed.success_check = success_check2.toString();
    parsed.review_check = review_check2.toString();
    parsed.pending_check = pending_check2.toString();
    parsed.fail_check = fail_check2.toString();
    parsed.theorem = user.theorem.toString();

    const stringified = queryString.stringify(parsed);

    return `?${stringified}`;
  };

  let appUrl = `${base}/user/apps/${pageNum}${getAdminParams1()}`;
  const key = appUrl + user.accountType;

  const { data: appData } = useSWR(key, () =>
    fetch(appUrl, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  let appUrl2 = `${base}/user/apps-institutional/${pageNum2}${getAdminParams2()}`;
  const key2 = appUrl2 + user.accountType + "-institutional";

  const { data: appData2 } = useSWR(key2, () =>
    fetch(appUrl2, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }).then((res) => res.json())
  );

  const viewImageRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        viewImageRef.current &&
        !viewImageRef.current.contains(event.target)
      ) {
        setViewAttachment(false);
        setImgName("");
        setPagePdfNumber(1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewImageRef]);

  const statusRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (statusRef.current && !statusRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusRef]);

  const statusRef2 = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (statusRef2.current && !statusRef2.current.contains(event.target)) {
        setShowFilter2(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusRef2]);

  const appArray = appData?.apps || [];
  const appArray2 = appData2?.apps || [];

  const historyArray = historyFetch?.history || [];

  function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // @ts-ignore
  const prettify = (num) => {
    return numberWithCommas(Number(num).toFixed(2));
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "pending":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 capitalize">
            Pending
          </span>
        );
      case "expired":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 capitalize">
            Expired
          </span>
        );
      case "cancelled":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 capitalize">
            Cancelled
          </span>
        );
      case "completed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 capitalize">
            Completed
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-black-100 text-black-800 capitalize">
            n/a
          </span>
        );
    }
  };

  const historyJSX = () => {
    return historyArray.map((item, index) => {
      return (
        <li>
          <div
            onClick={() => {
              setModalItem(item);
              setOpenModal(true);
            }}
            className="block px-4 py-4 bg-white hover:bg-gray-50"
          >
            <span className="flex items-center space-x-4">
              <span className="flex-1 flex space-x-2 truncate">
                <span className="flex flex-col text-gray-500 text-sm truncate">
                  <span className="truncate">
                    {item.fund} - ({item.status})
                  </span>
                  <span>
                    <span className="text-gray-900 font-medium">
                      ${prettify(item.amount)}
                    </span>{" "}
                    {item.denomination.toUpperCase()}
                  </span>
                  <span>
                    {" "}
                    {moment
                      .unix(Number(item.createdAt) / 1000)
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </span>
                </span>
              </span>
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </li>
      );
    });
  };

  const historyJSX2 = () => {
    return historyArray.map((item, index) => {
      return (
        <tr
          onClick={() => {
            setModalItem(item);
            setOpenModal(true);
          }}
          style={{ cursor: "pointer" }}
          className="bg-white hover:bg-gray-50 transition duration-200"
        >
          <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            <div className="flex">
              <div className="group inline-flex space-x-2 truncate text-sm">
                <p className="text-gray-500 truncate group-hover:text-gray-900">
                  {item.fund}
                </p>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-medium">
              ${prettify(item.amount)}
            </span>
            &nbsp;{item.denomination.toUpperCase()}
          </td>
          <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
            {getStatusIcon(item.status)}
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            {moment
              .unix(Number(item.createdAt) / 1000)
              .format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </td>
        </tr>
      );
    });
  };

  const copyJSX = (text, index) => {
    return (
      <CopyToClipboard
        text={text}
        onCopy={async () => {
          setCopyIndex(index);

          await delay(1500);
          setCopyIndex(0);
        }}
      >
        <img
          src={copyIcon}
          alt="copy"
          style={{
            height: 15,
            marginLeft: 4,
            cursor: "pointer",
          }}
        />
      </CopyToClipboard>
    );
  };

  const modalJSX = () => {
    return (
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            ref={modalRef}
            className="inline-block align-center bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => setOpenModal(false)}
                type="button"
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div>
              <div className="text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {modalItem.fund} Transaction
                </h3>
                {modalItem.status === "expired" && (
                  <div className="text-sm text-red-600 mt-5">
                    Your transaction expired due to this order not being
                    completed on time.
                  </div>
                )}
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="">Status:</p>
                  <div className="ml-2">{getStatusIcon(modalItem.status)}</div>
                </div>
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="">Amount:</p>
                  <div className="ml-2">
                    ${prettify(modalItem.amount)}&nbsp;
                    {modalItem.denomination.toUpperCase()}
                  </div>
                </div>
                <div className="text-sm text-gray-500 mt-5 flex items-center justify-between">
                  <p className="text-left">Transaction Initiated:</p>
                  <div className="ml-2 text-right">
                    {moment
                      .unix(Number(modalItem.createdAt) / 1000)
                      .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </div>
                {modalItem.createdAt !== modalItem.updatedAt && (
                  <div className="text-sm text-gray-500 mt-2 flex items-center justify-between">
                    <p className="">Last Updated:</p>
                    <div className="ml-2">
                      {moment
                        .unix(Number(modalItem.updatedAt) / 1000)
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </div>
                  </div>
                )}

                <div className="text-sm mt-5 flex items-center justify-between">
                  <p className="text-gray-500">
                    Executed Subscription Document:
                  </p>
                  <div
                    className="ml-2 text-blue-500 hover:underline"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(modalItem.executedSubDocURL, "_blank")
                    }
                  >
                    Download
                  </div>
                </div>

                <div
                  className="flex mt-5 items-center justify-between text-sm text-blue-500 hover:underline"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowMisc(!showMisc);
                  }}
                >
                  <span />
                  <span>{showMisc ? "Hide" : "Show Misc."}</span>
                </div>

                {showMisc && (
                  <React.Fragment>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Sub. Document ID</p>
                      <div className="ml-2">{modalItem.subDocID}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Transaction ID</p>
                      <div className="ml-2">{modalItem._id}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">User ID</p>
                      <div className="ml-2">{modalItem.userId._id}</div>
                    </div>
                    <div className="text-sm text-gray-500 flex items-center justify-between">
                      <p className="">Sig. Request ID</p>
                      <div className="ml-2">{modalItem.signatureRequestID}</div>
                    </div>
                    {modalItem.silvergateId && (
                      <div className="text-sm text-gray-500 flex items-center justify-between">
                        <p className="">Silvergate TXID</p>
                        <div className="ml-2">{modalItem.silvergateId}</div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {modalItem.status !== "expired" && (
                  <div>
                    <div className="mt-10 text-gray-500 font-bold text-left text-md">
                      Wire Instructions
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Bank Name</p>
                      <div className="ml-2">Silvergate Bank</div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Bank Address</p>
                      <div className="ml-2 text-right">
                        <div>128 N Broadway</div>
                        <div>Escondido, CA 92025, USA</div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">ABA / Routing Number</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 mt-3 ${
                            copyIndex === 1 && "text-green-500 font-bold"
                          }`}
                        >
                          Routing: 123123123
                          {copyJSX("123123123", 1)}
                        </div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Account Number</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 ${
                            copyIndex === 2 && "text-green-500 font-bold"
                          }`}
                        >
                          Account: 01929412
                          {copyJSX("01929412", 2)}
                        </div>
                      </div>
                    </div>

                    <div className="text-sm text-gray-500 mt-5 flex items-start justify-between">
                      <p className="">Memo</p>
                      <div className="ml-2">
                        <div
                          className={`flex items-center justify-end transition duration-200 ${
                            copyIndex === 3 && "text-green-500 font-bold"
                          }`}
                        >
                          Memo: {modalItem.memo}
                          {copyJSX(modalItem.memo, 3)}
                        </div>
                      </div>
                    </div>

                    <div className="border-l-4 border-yellow-400 px-4 py-2 my-2 mt-5">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <div
                            className={`text-gray-600 ${
                              isMobile ? "text-xs" : "text-sm"
                            } flex items-center`}
                          >
                            <div className="font-bold flex items-center">
                              Memo must include&nbsp;
                              <div className="bg-yellow-100 text-yellow-800">
                                &nbsp;{modalItem.memo}&nbsp;
                              </div>
                            </div>
                            &nbsp;—&nbsp;
                          </div>
                          <div
                            className={`${
                              isMobile ? "text-xs" : "text-sm"
                            } text-gray-600 text-left text-light`}
                          >
                            Including the full memo helps us accurately
                            attribute your investment.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col my-6">
                      <label className="flex items-center">
                        <input
                          onChange={(e) => setConfirmMemo(e.target.checked)}
                          type="checkbox"
                          className="form-checkbox mr-3"
                        />
                        <span className="ml-2 text-xs text-gray-700">
                          I confirm the memo is entered as shown above
                        </span>
                      </label>
                      {confirmMemoError && (
                        <span className="text-xs mt-3 text-left font-bold text-red-600">
                          Confirm before contiuing
                        </span>
                      )}
                    </div>

                    <div
                      onClick={() => {
                        if (confirmMemo) {
                          setOpenModal(false);
                          setConfirmMemoError(false);
                        } else {
                          setConfirmMemoError(true);
                        }
                      }}
                      style={{ cursor: "pointer", width: "100%" }}
                      className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-center text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-700 hover:to-blue-700`}
                    >
                      Done
                    </div>
                  </div>
                )}

                {modalItem.status === "completed" && (
                  <React.Fragment>
                    <div className="text-sm mt-5 flex items-center justify-between">
                      <p className="text-gray-500">Shares Purchased</p>
                      <div className="ml-2 text-gray-500">
                        {prettify(Number(modalItem.sharesPurchased))} Shares
                      </div>
                    </div>
                    <div className="text-sm flex items-center justify-between">
                      <p className="text-gray-500">
                        {moment
                          .unix(modalItem.sharesPurchasedOn / 1000)
                          .format("MMMM Do YYYY, h:mm:ss a")}
                      </p>
                      <div className="ml-2 text-gray-500"></div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const historyParentJSX = () => {
    return (
      <React.Fragment>
        <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          Recent activity
        </h2>

        {openModal && modalJSX()}

        <div className="sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden sm:hidden">
            {historyJSX()}
          </ul>
          {historyFetch?.history?.length === 0 ? (
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div
                className="flex items-center text-gray-500"
                style={{ width: "100%" }}
              >
                No Transactions
              </div>
            </nav>
          ) : (
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
              aria-label="Pagination"
            >
              <div className="flex-1 flex justify-between">
                <div
                  onClick={() => {
                    setHistoryPageNumber(
                      historyPageNum >= 2 ? historyPageNum - 1 : 1
                    );
                  }}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
                >
                  Previous
                </div>
                <div
                  onClick={() => {
                    setHistoryPageNumber(
                      historyPageNum <= Number(historyFetch?.pages) - 1
                        ? historyPageNum + 1
                        : Number(historyFetch?.pages)
                    );
                  }}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
                >
                  Next
                </div>
              </div>
            </nav>
          )}
        </div>

        <div className="hidden sm:block">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-sm">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Transaction
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                        Status
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {historyJSX2()}
                  </tbody>
                </table>
                {historyFetch?.history?.length === 0 ? (
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div
                      className="flex items-center text-gray-500"
                      style={{ width: "100%" }}
                    >
                      No Transactions
                    </div>
                  </nav>
                ) : (
                  <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                    aria-label="Pagination"
                  >
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium px-1">
                          {(Number(historyFetch?.currentPage) - 1) * 5 + 1}
                        </span>
                        to
                        <span className="font-medium px-1">
                          {historyFetch?.history?.length}
                        </span>
                        of
                        <span className="font-medium px-1">
                          {historyFetch?.numOfResults}
                        </span>
                        results
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <div
                        onClick={() =>
                          setPageNumber(pageNum >= 2 ? pageNum - 1 : 1)
                        }
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Previous
                      </div>
                      <div
                        onClick={() =>
                          setPageNumber(
                            pageNum <= Number(appData?.pages) - 1
                              ? pageNum + 1
                              : Number(appData?.pages)
                          )
                        }
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Next
                      </div>
                    </div>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const accreditationList = (array) => {
    return array.map((item, index) => {
      return (
        <li>
          <div
            onClick={async () => await handleOpenApplicant(item, index)}
            className="block px-4 py-4 bg-white hover:bg-gray-50"
          >
            <span className="flex items-center space-x-4">
              <span className="flex-1 flex space-x-2 truncate">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="flex flex-col text-gray-500 text-sm truncate">
                  <span className="truncate">
                    {getAppName(item)} <b>({getAppType(item)})</b>
                  </span>
                  <span>{getDate(item)}</span>
                </span>
              </span>
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </div>
        </li>
      );
    });
  };

  const fileExt = (fileName) => {
    return fileName ? fileName.split(".").pop().toLowerCase() : "";
  };

  const getAppIdTheorem = (item) => {
    if (item.type === "individual") {
      return item?.applier?.theoremId;
    } else {
      return item?.applier?.theoremId;
    }
  };

  const getAppName = (item) => {
    if (item.type === "individual") {
      let middleInitial = item.form["1b"]?.[0]
        ? " " + item.form["1b"][0] + "." + " "
        : " ";

      return item.form["1a"] + middleInitial + item.form["1c"];
    } else {
      return item.form["1a"];
    }
  };

  const getAppType = (item) => {
    if (item.type === "individual") {
      return item.type;
    } else {
      return item.form["1b"];
    }
  };

  const viewType = (type) => {
    switch (type) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
      case "svg":
      case "pdf":
        return true;
      default:
        return false;
    }
  };

  const getAuthorizedName = (item) => {
    if (item.type === "individual") {
      let middleInitial = item.form["7b"]?.[0]
        ? " " + item.form["7b"][0] + "." + " "
        : " ";

      return item.form["7a"] + middleInitial + item.form["7c"];
    } else {
      let middleInitial = item.form["2b"]?.[0]
        ? " " + item.form["2b"][0] + "." + " "
        : " ";

      return item.form["2a"] + middleInitial + item.form["2c"];
    }
  };

  const getAttachments = (item) => {
    if (item.type === "individual") {
      return (
        <React.Fragment>
          {item.form?.["accreditation-income-1"] && (
            <React.Fragment>
              {attachmentDetail(
                "2020 Income Accreditation",
                applicant,
                "accreditation-income-1"
              )}
              {attachmentDetail(
                "2019 Income Accreditation",
                applicant,
                "accreditation-income-2"
              )}
            </React.Fragment>
          )}
          {item.form?.["accreditation-networth"] && (
            <React.Fragment>
              {attachmentDetail(
                "Net Worth Accreditation",
                applicant,
                "accreditation-networth"
              )}
            </React.Fragment>
          )}
          {item.form?.["individual-ID"] &&
            attachmentDetail(
              "Identity Document (KYC/AML)",
              applicant,
              "individual-ID"
            )}
          {item.form?.["individual-residential"] &&
            attachmentDetail(
              "Proof of Residence (KYC/AML)",
              applicant,
              "individual-residential"
            )}
          {item.form?.["executedSubDocURL"] && (
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate">
                  Executed {item.form["3a"]} Subscription Document
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      currentSubDoc || item.form["executedSubDocURL"],
                      "_blank"
                    );
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  {"Download Subscription Document"}
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {item.form?.["2m"] &&
            attachmentDetail("Identification", applicant, "2m")}
        </React.Fragment>
      );
    }
  };

  const getAppDOB = (item) => {
    if (item.type === "individual") {
      return `${item.form["1d"]}`;
    } else {
      return `${item.form["2l"]}`;
    }
  };

  const selfAccreditation = (item) => {
    let status = [];
    if (item.type === "individual") {
      if (item?.form?.["2a"])
        status.push(
          "Income over $200K ($300K with spouse) in each of the last 2 years"
        );
      if (item?.form?.["2b"])
        status.push("Net worth (exclusing primary residence) over $1,000,000");
      if (item?.form?.["2c"]) status.push("not self-accredited");
    } else {
      if (item?.form?.["3a"]) status.push("Sole equity owner is accredited");
      if (item?.form?.["3h"])
        status.push(
          "Income over $200K ($300K with spouse) in each of the last 2 years"
        );
      if (item?.form?.["3i"])
        status.push("Net worth (excluding primary residence) over $1,000,000");
      if (item?.form?.["3b"])
        status.push("Multiple equity owners are accredited");
      if (item?.form?.["3c"])
        status.push(
          `${item?.form?.["1b"] || "Entity"} with assets over
          $5,000,000`
        );
      if (item?.form?.["3d"]) status.push("not self-accredited");
    }

    return status.map((point, _) => {
      return (
        <li>
          <span style={point === "not self-accredited" ? { color: "red" } : {}}>
            {point}
          </span>
        </li>
      );
    });
  };

  const getAppAddress = (item) => {
    if (item.type === "individual") {
      return `${item.form["1g"]}, ${item.form["1h"]}, ${item.form["1i"]}, ${item.form["1j"]}, ${item.form["1k"]}`;
    } else {
      return `${item.form["1d"]}, ${item.form["1e"]}, ${item.form["1f"]}, ${item.form["1g"]}`;
    }
  };

  const getAppPhone = (item) => {
    if (item.type === "individual") {
      return `${
        item.form["1f_country"]?.id &&
        countryCodeEmoji(item.form["1f_country"]?.id)
      } ${"(" + item.form["1f_country"]?.dialCode + ") " || ""}${
        item.form["1f"]
      }`;
    } else {
      return `${
        item.form["1h_country"]?.id &&
        countryCodeEmoji(item.form["1h_country"]?.id)
      } ${"(" + item.form["1h_country"]?.dialCode + ") " || ""}${
        item.form["1h"]
      }`;
    }
  };

  const getAppPhoneToopTip = (item) => {
    if (item.type === "individual") {
      return item.form["1f_country"]?.label;
    } else {
      return item.form["1h_country"]?.label;
    }
  };

  const getFundApplying = (item) => {
    if (item.type === "individual") {
      return item.form["3a"] || "None Selected";
    } else {
      return item.form["4a"] || "None Selected";
    }
  };

  // TODO: do some fancier magic later to pull up actual PDF
  const getSubDoc = (item) => {
    if (item.type === "individual") {
      return item.form["onboardingSubDoc"] || "Not Signed";
    } else {
      return item.form["onboardingSubDoc"] || "Not Signed";
    }
  };

  const getTaxNumberSubject = (item) => {
    if (item.type === "individual") {
      return "SSN / Tax ID";
    } else {
      return "EIN / Tax ID";
    }
  };

  const getTaxNumber = (item) => {
    if (item.type === "individual") {
      return item.form["1e"];
    } else {
      return item.form["1c"];
    }
  };

  const loadingJSX = (color = "white") => {
    return (
      <svg
        className={`animate-spin h-5 w-5 text-${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  const getAppEmail = (item) => {
    if (item.type === "individual") {
      return item.applier.email;
    } else {
      return item?.secondaryApplier?.email || item?.applier?.email;
    }
  };

  const getDateCreated = (item) => {
    return moment
      .unix(Number(item.createdAt) / 1000)
      .format("dddd, MMMM Do YYYY, h:mm:ss a");
  };

  const getDate = (item) => {
    return moment
      .unix(Number(item.updatedAt) / 1000)
      .format("dddd, MMMM Do YYYY, h:mm:ss a");
  };

  const getNote = (item) => {
    if (item.type === "individual") {
      return item.message;
    } else {
      return item.message;
    }
  };

  const getUpdatedDate = (item) => {
    return moment.unix(Number(item.updatedAt) / 1000).fromNow();
  };

  const getAccreditationStatus = (item) => {
    switch (Number(item.status)) {
      case 0:
        return "unsubmitted";
      case 1:
        return "needs review";
      case 2:
        return "pending user review";
      case 3:
        if (item.passAML.toString() === "true") {
          return "success";
        } else {
          return "needs review";
        }
      case 4:
        return "failed";
      default:
        return "n/a";
    }
  };

  const getSignatureRequest = async (signatureID, item) => {
    try {
      // reset
      setCurrentSubDocURL("");
      let getSignatureRequestURL = `${base}/user/getFileUrl/${signatureID}/${item.applier._id}`;

      const response = await axios.get(getSignatureRequestURL, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      const data = response.data;

      setCurrentSubDocURL(data.url);
    } catch (err) {
      console.log("error while getting signature request: ", err);
    }
  };

  const handleOpenApplicant = async (item, index) => {
    setOpenApplicant(true);
    setApplicant(item);

    await getSignatureRequest(item?.form?.["onboardingRequestId"], item);
  };

  const accreditationListDesktop = (array) => {
    return array.map((item, index) => {
      return (
        <tr
          className="bg-white hover:bg-gray-200"
          style={{ cursor: "pointer" }}
          onClick={async () => await handleOpenApplicant(item, index)}
        >
          <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
            <div className="flex">
              <div
                className="group inline-flex space-x-2 truncate text-sm"
                style={{ cursor: "pointer" }}
              >
                <p className="text-gray-500 truncate group-hover:text-gray-900 capitalize">
                  {getAppName(item)}
                </p>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-900 font-medium capitalize">
              {getAppType(item)}
            </span>
          </td>
          <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                getAccreditationStatus(item) === "pending user review"
                  ? "bg-gray-400 text-white"
                  : getAccreditationStatus(item) === "needs review"
                  ? "bg-yellow-400 text-white"
                  : getAccreditationStatus(item) === "failed"
                  ? "bg-red-100 text-red-800"
                  : "bg-green-100 text-green-800"
              } capitalize`}
            >
              {getAccreditationStatus(item)}
            </span>
          </td>
          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
            {getDate(item)}
          </td>
        </tr>
      );
    });
  };

  const accreditateTableJSX = () => {
    return (
      <React.Fragment>
        <div className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          <div className="rounded-md bg-blue-50 p-4 shadow-sm">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-blue-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  {user.theorem
                    ? `All users here have been accredited by Immutable's Legal Team.`
                    : `Please review the following user applications for accreditation verification`}
                </p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  {user.theorem ? (
                    <a
                      href="/#/faq"
                      target="_blank"
                      rel="noreferrer"
                      className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                    >
                      Learn more <span aria-hidden="true">&rarr;</span>
                    </a>
                  ) : (
                    <a
                      href="/#/faq"
                      target="_blank"
                      rel="noreferrer"
                      className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                    >
                      Learn more <span aria-hidden="true">&rarr;</span>
                    </a>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
          {user.theorem
            ? "KYC/AML & Subscription Document Checks"
            : "Investor Accreditation"}
        </h2>

        <div className="sm:hidden">
          <ul className="mt-2 divide-y divide-gray-200 overflow-hidden sm:hidden">
            {accreditationList(appArray)}
          </ul>

          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <div
                onClick={() => setPageNumber(pageNum >= 2 ? pageNum - 1 : 1)}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </div>
              <div
                onClick={() =>
                  setPageNumber(
                    pageNum <= Number(appData?.pages) - 1
                      ? pageNum + 1
                      : Number(appData?.pages)
                  )
                }
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </div>
            </div>
          </nav>
        </div>

        <div className="mt-10 sm:hidden" style={{ marginBottom: "100px" }}>
          <ul className="divide-y divide-gray-200 overflow-hidden sm:hidden">
            {accreditationList(appArray2)}
          </ul>

          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="flex-1 flex justify-between">
              <div
                onClick={() => setPageNumber2(pageNum2 >= 2 ? pageNum2 - 1 : 1)}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
              >
                Previous
              </div>
              <div
                onClick={() =>
                  setPageNumber2(
                    pageNum2 <= Number(appData2?.pages) - 1
                      ? pageNum2 + 1
                      : Number(appData2?.pages)
                  )
                }
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </div>
            </div>
          </nav>
        </div>

        <div className="hidden sm:block">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-sm">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Applicant
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th
                        ref={statusRef}
                        className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                      >
                        <div
                          className="flex items-center hover:font-bold transition duration-200"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowFilter(!showFilter);
                          }}
                        >
                          <div>Status</div>
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        {showFilter && (
                          <div
                            className="absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 z-10 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div className="py-1 ml-3" role="none">
                              <div className="flex items-center">
                                <input
                                  id="success"
                                  name="success"
                                  type="checkbox"
                                  defaultChecked={success_check}
                                  onChange={(e) => {
                                    setSuccessCheck(e.target.checked);
                                    setShowFilter(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="success"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Success
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="needs_review"
                                  name="needs_review"
                                  type="checkbox"
                                  defaultChecked={review_check}
                                  onChange={(e) => {
                                    setReviewCheck(e.target.checked);
                                    setShowFilter(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="needs_review"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Needs Review
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="pending_review"
                                  name="pending_review"
                                  type="checkbox"
                                  defaultChecked={pending_check}
                                  onChange={(e) => {
                                    setPendingCheck(e.target.checked);
                                    setShowFilter(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="pending_review"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Pending User Review
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="fail"
                                  name="fail"
                                  type="checkbox"
                                  defaultChecked={fail_check}
                                  onChange={(e) => {
                                    setFailCheck(e.target.checked);
                                    setShowFilter(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="fail"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Failed
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {accreditationListDesktop(appArray)}
                  </tbody>
                </table>

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    {appData?.apps.length === 0 ? (
                      <p className="text-sm text-gray-700">
                        {user.theorem
                          ? "No accredited applicants yet"
                          : "No applicants"}
                      </p>
                    ) : (
                      <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium px-1">
                          {(Number(appData?.currentPage) - 1) * 5 + 1}
                        </span>
                        to
                        <span className="font-medium px-1">
                          {appData?.apps.length}
                        </span>
                        of
                        <span className="font-medium px-1">
                          {appData?.numOfResults}
                        </span>
                        results
                      </p>
                    )}
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <div
                      onClick={() =>
                        setPageNumber(pageNum >= 2 ? pageNum - 1 : 1)
                      }
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </div>
                    <div
                      onClick={() =>
                        setPageNumber(
                          pageNum <= Number(appData?.pages) - 1
                            ? pageNum + 1
                            : Number(appData?.pages)
                        )
                      }
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block mt-10 mb-20">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-sm">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Applicant
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th
                        ref={statusRef2}
                        className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
                      >
                        <div
                          className="flex items-center hover:font-bold transition duration-200"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowFilter2(!showFilter2);
                          }}
                        >
                          <div>Status</div>
                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        {showFilter2 && (
                          <div
                            className="absolute mt-2 w-56 rounded-md z-10 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div className="py-1 ml-3" role="none">
                              <div className="flex items-center">
                                <input
                                  id="success"
                                  name="success"
                                  type="checkbox"
                                  defaultChecked={success_check2}
                                  onChange={(e) => {
                                    setSuccessCheck2(e.target.checked);
                                    setShowFilter2(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="success"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Success
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="needs_review"
                                  name="needs_review"
                                  type="checkbox"
                                  defaultChecked={review_check2}
                                  onChange={(e) => {
                                    setReviewCheck2(e.target.checked);
                                    setShowFilter2(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="needs_review"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Needs Review
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="pending_review"
                                  name="pending_review"
                                  type="checkbox"
                                  defaultChecked={pending_check2}
                                  onChange={(e) => {
                                    setPendingCheck2(e.target.checked);
                                    setShowFilter2(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="pending_review"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Pending User Review
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="fail"
                                  name="fail"
                                  type="checkbox"
                                  defaultChecked={fail_check2}
                                  onChange={(e) => {
                                    setFailCheck2(e.target.checked);
                                    setShowFilter2(false);
                                  }}
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label
                                  for="fail"
                                  className="block font-normal text-xs capitalize px-4 py-2 text-gray-700 hover:text-gray-900"
                                >
                                  Failed
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </th>
                      <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {accreditationListDesktop(appArray2)}
                  </tbody>
                </table>

                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    {Number(appData2?.apps.length) === 0 ? (
                      <p className="text-sm text-gray-700">
                        {user.theorem
                          ? "No accredited entities yet"
                          : "No entities"}
                      </p>
                    ) : (
                      <p className="text-sm text-gray-700">
                        Showing
                        <span className="font-medium px-1">
                          {(Number(appData2?.currentPage) - 1) * 5 + 1}
                        </span>
                        to
                        <span className="font-medium px-1">
                          {appData2?.apps.length}
                        </span>
                        of
                        <span className="font-medium px-1">
                          {appData2?.numOfResults}
                        </span>
                        results
                      </p>
                    )}
                  </div>
                  <div className="flex-1 flex justify-between sm:justify-end">
                    <div
                      onClick={() =>
                        setPageNumber2(pageNum2 >= 2 ? pageNum2 - 1 : 1)
                      }
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </div>
                    <div
                      onClick={() =>
                        setPageNumber2(
                          pageNum2 <= Number(appData2?.pages) - 1
                            ? pageNum2 + 1
                            : Number(appData2?.pages)
                        )
                      }
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-sm text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Next
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handlePost = async (type) => {
    try {
      let statusNumber =
        type === "approve"
          ? 3
          : type === "deny"
          ? 4
          : type === "modify"
          ? 2
          : 1;

      let message = "";

      if (statusNumber === 2) {
        message = prompt(
          "Please enter a quick note to the user. We recommend continuing the conversation via email"
        );

        if (message === null) {
          return;
        }
      } else {
        if (
          window.confirm(
            `Please confirm your decision${
              statusNumber === 3
                ? user.theorem
                  ? ` to approve this application`
                  : ` to verify this investor's accreditation status`
                : ""
            }. Continue?`
          )
        ) {
          // continue
        } else {
          return;
        }
      }

      let data = {
        applicationId: applicant._id,
        type: applicant.type,
        status: Number(statusNumber),
        theorem: user.theorem,
        message,
      };

      setLoadingPost(statusNumber);

      let result = await axios.post(`${base}/user/apps`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      await delay(1000);

      setLoadingPost(-1);
      setOpenApplicant(false);
      setOverride(false);

      window.location.reload();
    } catch (err) {
      alert(`error: ${err.response.data.message}`);
      console.log("error: ", err.response.data);

      setLoadingPost(-1);
      setOpenApplicant(false);
      setOverride(false);
    }
  };

  const viewAttachmentJSX = () => {
    return (
      <div className="fixed z-20 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            ref={viewImageRef}
            className="inline-block align-center bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="font-bold text-sm text-center">
                {attachmentText}
              </div>
              <div className="px-4 py-2 text-center flex items-center justify-center sm:mt-5">
                {pdfURL !== "" ? (
                  <div className="px-4 py-2">
                    <Document
                      loading={
                        <div className="flex item-center">
                          Loading...&nbsp;{loadingJSX("blue")}
                        </div>
                      }
                      file={pdfURL}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page width={440} pageNumber={pagePdfNumber} />
                    </Document>
                    <div className="flex items-center justify-center py-2 mt-5 text-gray-600">
                      <div
                        style={{ cursor: "pointer", fontSize: 16 }}
                        className="mr-4 hover:text-blue-500"
                        onClick={() => {
                          if (pagePdfNumber > 1) {
                            setPagePdfNumber(pagePdfNumber - 1);
                          }
                        }}
                      >
                        &nbsp;←&nbsp;
                      </div>
                      <div>
                        Page {pagePdfNumber} of {numPages}
                      </div>
                      <div
                        style={{ cursor: "pointer", fontSize: 16 }}
                        className="ml-4 hover:text-blue-500"
                        onClick={() => {
                          if (pagePdfNumber < numPages) {
                            setPagePdfNumber(pagePdfNumber + 1);
                          }
                        }}
                      >
                        &nbsp;→&nbsp;
                      </div>
                    </div>
                  </div>
                ) : (
                  <img src={imgURL} style={{ height: "300px" }} />
                )}
              </div>
            </div>

            <div className="mt-5 flex items-center justify-between">
              <button
                onClick={() => {
                  setViewAttachment(false);
                }}
                type="button"
                className="mt-3 mr-5 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Close
              </button>
              <button
                onClick={() => {
                  window.open(pdfURL !== "" ? pdfURL : imgURL, "_blank");
                }}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const attachmentDetail = (text, item, key) => {
    if (item.form[key]) {
      return (
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div className="w-0 flex-1 flex items-center">
            <svg
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="ml-2 flex-1 w-0 truncate">
              {JSON.parse(item.form[key])?.name}
            </span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (viewType(fileExt(JSON.parse(item.form[key])?.name))) {
                  setViewAttachment(true);
                }

                if (fileExt(JSON.parse(item.form[key])?.name) === "pdf") {
                  setPdfURL(JSON.parse(item.form[key])?.url);
                  setImgURL("");
                } else if (
                  viewType(fileExt(JSON.parse(item.form[key])?.name))
                ) {
                  setImgURL(JSON.parse(item.form[key])?.url);
                  setPdfURL("");
                } else {
                  window.open(JSON.parse(item.form[key])?.url, "_blank");
                }

                setImgName(JSON.parse(item.form[key])?.name);

                setAttachmentText(text);
              }}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {viewType(fileExt(JSON.parse(item.form[key])?.name))
                ? `View ${text}`
                : `Download ${text}`}
            </div>
          </div>
        </li>
      );
    }
  };

  const getEntityAML = (entityType) => {
    switch (entityType) {
      case "Other":
      case "Corporation":
        return [
          {
            key: "privateco-certificateOfIncorporation",
            text: "Certificate of Incorpoartion",
          },
          {
            key: "privateco-memorandumAndAoA",
            text: "Memorandum & Articles of Association",
          },
          {
            key: "privateco-registerOfDirectors",
            text: "Register of Directors",
          },
          { key: "privateco-directorID", text: "Director(s) ID" },
          { key: "privateco-beneficialOwners", text: "Beneficial Owners" },
          {
            key: "privateco-proofBeneficialOwners",
            text: "Proof of Beneficial Ownership",
          },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Fund":
        return [
          { key: "fund-ppm", text: "Private Placement Memorandum" },
          { key: "fund-coi", text: "Certificate of Incorporation" },
          { key: "fund-beneficialOwners", text: "Beneficial Owners" },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Public Body":
        return [
          {
            key: "publicbody-homeStateAuthority",
            text: "Home State Authority",
          },
          { key: "publicbody-beneficialOwners", text: "Beneficial Owners" },
          {
            key: "publicbody-officialID",
            text: "Official ID of 2 Officials",
          },
          {
            key: "publicbody-proofOfResidence",
            text: "Proof of Residence of 2 Officials",
          },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Pension Scheme":
        return [
          { key: "pension-formation", text: "Formation Document" },
          {
            key: "pension-trusteeDetails",
            text: "Trustees or equivalent details",
          },
          { key: "pension-admin", text: "Name/Address of Administrator(s)" },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Private Trust":
        return [
          { key: "privatetrust-trustDeed", text: "Trust Deed" },
          { key: "privatetrust-trustees", text: "Details of trustees" },
          {
            key: "privatetrust-trusteesID",
            text: "ID of 2+ trustees",
          },
          {
            key: "privatetrust-settlerID",
            text: "ID of the Settler",
          },
          {
            key: "privatetrust-trustBeneficialOwners",
            text: "ID of Beneficial Owners",
          },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Partnership":
        return [
          { key: "partner-LP", text: "Certificate of Limited Partnership" },
          { key: "partner-agreement", text: "Partnership Agreement" },
          { key: "partner-partnerList", text: "Details of all partners" },
          {
            key: "partner-ID",
            text: "ID of the General Partner(s)",
          },
          { key: "partner-beneficialOwners", text: "Beneficial Owners" },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Limited Liability Company":
        return [
          { key: "llc-formation", text: "Certificate of Formation" },
          { key: "llc-operatingAgreement", text: "Operating Agreement" },
          { key: "llc-ID", text: "ID for Managing Member(s)" },
          { key: "llc-managerDetails", text: "Details of Managing Member(s)" },
          {
            key: "llc-beneficialOwners",
            text: "ID of Beneficial Owners",
          },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "Registered Charity/Foundation":
        return [
          { key: "charity-overview", text: "Nature/Purpose of Charity" },
          { key: "charity-register", text: "Register of Charity" },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      case "School, College or University":
        return [
          { key: "school-official", text: "ID of 2+ Officials" },
          { key: "school-details", text: "Details of Official(s)" },
          {
            key: "school-beneficialowners",
            text: "ID of Beneficial Owners",
          },
        ].map((item) => attachmentDetail(item.text, applicant, item.key));
      default:
        return "";
    }
  };

  const getAttachmentsEntity = (item) => {
    return (
      <React.Fragment>
        {item.form["3h"] && (
          <React.Fragment>
            {attachmentDetail(
              "2020 Income Proof",
              applicant,
              "accreditation-income-1"
            )}
            {attachmentDetail(
              "2019 Income Proof",
              applicant,
              "accreditation-income-2"
            )}
          </React.Fragment>
        )}
        {item.form["3i"] &&
          attachmentDetail(
            "Net Worth Proof",
            applicant,
            "accreditation-networth"
          )}
        {getEntityAML(item.form["1b"])}
        {item.form?.["executedSubDocURL"] && (
          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="ml-2 flex-1 w-0 truncate">
                Executed {item.form["4a"]} Subscription Document
              </span>
            </div>
            <div className="ml-4 flex-shrink-0">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    currentSubDoc || item.form["executedSubDocURL"],
                    "_blank"
                  );
                }}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                {"Download Subscription Document"}
              </div>
            </div>
          </li>
        )}
      </React.Fragment>
    );
  };

  // displays info of individual vs entity for review to look at
  // includes email of user to email
  // includes message box to write feedback
  const applicantDetails = () => {
    return (
      <div>
        <main className="py-10">
          <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {getAppName(applicant)}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  created on {getDateCreated(applicant)}
                </p>
                {user.theorem && (
                  <React.Fragment>
                    <div className={`flex items-center mt-1`}>
                      <div
                        className={`text-sm ${
                          copyBoolean
                            ? "text-green-500 font-normal"
                            : "font-light text-gray-500"
                        }`}
                      >
                        Theorem ID: {getAppIdTheorem(applicant)}
                      </div>
                      <CopyToClipboard
                        text={getAppIdTheorem(applicant)}
                        onCopy={async () => {
                          setCopied(true);
                          await delay(2500);
                          setCopied(false);
                        }}
                      >
                        <img
                          src={copyIcon}
                          alt="copy"
                          style={{
                            height: 15,
                            marginLeft: 4,
                            cursor: "pointer",
                          }}
                        />
                      </CopyToClipboard>
                      {copyBoolean ? (
                        <div className="ml-3 text-green-500 text-sm">
                          Copied!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                )}
                {getNote(applicant) !== undefined &&
                  getNote(applicant) !== "" && (
                    <p className="text-sm font-normal text-yellow-600">
                      notes: {getNote(applicant)}
                    </p>
                  )}
              </div>
            </div>
            {Number(applicant.status) === 1 ||
            override ||
            (Number(applicant.status) === 3 &&
              applicant.passAML.toString() !== "true") ? (
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button
                  onClick={() => handlePost("deny")}
                  type="button"
                  className={`inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md ${
                    loadingPost === 4 ? "bg-gray-400" : "bg-white"
                  } hover:bg-gray-50 text-gray-700 focus:outline-none`}
                >
                  {loadingPost === 4 ? "Denying..." : "Deny"}
                </button>
                <button
                  onClick={() => handlePost("approve")}
                  type="button"
                  className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                    loadingPost === 3 ? "bg-green-200" : "bg-green-500"
                  } hover:bg-green-600 focus:outline-none`}
                >
                  {loadingPost === 3 ? "Approving..." : "Approve"}
                </button>
              </div>
            ) : (
              <button
                onClick={() => {
                  if (
                    window.confirm(
                      `You can override this user's status. Proceed?`
                    )
                  ) {
                    setOverride(true);
                  }
                }}
                type="button"
                className={`${
                  isMobile ? "mt-3" : ""
                } inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-blue-500 bg-white focus:outline-none`}
              >
                Override
              </button>
            )}
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="flex items-center justify-between">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Applicant Information
                      </h2>
                      <p className="mt-1 max-w-2xl text-xs text-gray-500">
                        Last updated {getUpdatedDate(applicant)}
                      </p>
                    </div>
                    <div className="px-4 py-5 sm:px-6">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          getAccreditationStatus(applicant) ===
                          "pending user review"
                            ? "bg-gray-400 text-white"
                            : getAccreditationStatus(applicant) ===
                              "needs review"
                            ? "bg-yellow-400 text-white"
                            : getAccreditationStatus(applicant) === "failed"
                            ? "bg-red-100 text-red-800"
                            : "bg-green-100 text-green-800"
                        } capitalize`}
                      >
                        {getAccreditationStatus(applicant)}
                      </span>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Application Type
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 capitalize">
                          {getAppType(applicant)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {getAppEmail(applicant)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {getAppAddress(applicant)}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Phone
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <StatefulTooltip
                            content={() => (
                              <div>{getAppPhoneToopTip(applicant)}</div>
                            )}
                            returnFocus
                            autoFocus
                            overrides={{
                              Body: {
                                style: {
                                  width: "150px",
                                  textAlign: "center",
                                },
                              },
                            }}
                          >
                            {getAppPhone(applicant)}
                          </StatefulTooltip>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Fund of Interest
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="list-disc list-inside">
                            {getFundApplying(applicant)}
                          </ul>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Subscription Document ID
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="list-disc list-inside">
                            {getSubDoc(applicant)}
                          </ul>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          {getTaxNumberSubject(applicant)}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="list-disc list-inside">
                            {getTaxNumber(applicant)}
                          </ul>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Self-Accreditation Status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="list-disc list-inside">
                            {selfAccreditation(applicant)}
                          </ul>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Desired Investment Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="list-disc list-inside">
                            $
                            {prettify(
                              Number(
                                applicant.form["investmentAmount"]?.replace(
                                  /,/g,
                                  ""
                                )
                              )
                            )}
                          </ul>
                        </dd>
                      </div>
                      {applicant.type === "individual" && (
                        <React.Fragment>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Date of Birth
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {getAppDOB(applicant)}
                            </dd>
                          </div>
                          <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Attachments
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                {getAttachments(applicant)}
                              </ul>
                            </dd>
                          </div>
                          {viewAttachment && viewAttachmentJSX()}
                        </React.Fragment>
                      )}
                    </dl>
                    {applicant.type !== "individual" &&
                      (applicant.form?.["10d"] ? (
                        <div>
                          <div className="sm:col-span-2 mt-10">
                            <dt className="text-sm font-medium text-gray-500">
                              No Attachments
                            </dt>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="sm:col-span-2 mt-10">
                            <dt className="text-sm font-medium text-gray-500">
                              Attachments
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                {getAttachmentsEntity(applicant)}
                              </ul>
                            </dd>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>

              {applicant.type !== "individual" && (
                <section aria-labelledby="applicant-information-title">
                  <div className="mt-10 bg-white shadow sm:rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="px-4 py-5 sm:px-6">
                        <h2
                          id="applicant-information-title"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Authorized Representative Info
                        </h2>
                      </div>
                      <div className="px-4 py-5 sm:px-6">
                        <span />
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Full Legal Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 capitalize">
                            {getAuthorizedName(applicant)}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Work Title
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.form?.["2d"]}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {`${applicant.form["2f"]}, ${applicant.form["2g"]}, ${applicant.form["2h"]}, ${applicant.form["2i"]}, ${applicant.form["2j"]}`}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Phone
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <StatefulTooltip
                              content={() => (
                                <div>{applicant.form["2e_country"]?.label}</div>
                              )}
                              returnFocus
                              autoFocus
                              overrides={{
                                Body: {
                                  style: {
                                    width: "150px",
                                    textAlign: "center",
                                  },
                                },
                              }}
                            >
                              {`${
                                applicant.form["2e_country"]?.id &&
                                countryCodeEmoji(
                                  applicant.form["2e_country"]?.id
                                )
                              }
                              ${
                                "(" +
                                  applicant.form?.["2e_country"]?.dialCode +
                                  ") " || ""
                              }
                              ${applicant.form?.["2e"]}`}
                            </StatefulTooltip>
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Citizenship
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {applicant.form?.["2k"]}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Date of Birth
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {getAppDOB(applicant)}
                          </dd>
                        </div>
                      </dl>

                      <div className="sm:col-span-2 mt-10">
                        <dt className="text-sm font-medium text-gray-500">
                          Attachments
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                            {getAttachments(applicant)}
                          </ul>
                        </dd>
                      </div>
                      {viewAttachment && viewAttachmentJSX()}
                    </div>
                  </div>
                </section>
              )}

              {(Number(applicant.status) === 1 ||
                override ||
                (Number(applicant.status) === 3 &&
                  applicant.passAML.toString() !== "true")) && (
                <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
                  <span />
                  <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <button
                      onClick={() => handlePost("modify")}
                      type="button"
                      className={`inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                        loadingPost === 2 ? "bg-blue-200" : "bg-blue-600"
                      } hover:bg-blue-700 focus:outline-none`}
                    >
                      {loadingPost === 2
                        ? "Requesting..."
                        : "Request Modifications"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    );
  };

  const activeClass = "text-blue-500 px-3 py-2 uppercase text-sm";
  const nonActiveClass =
    "text-gray-500 hover:text-gray-700 px-3 py-2 uppercase text-sm";

  const dayClass = timePeriod === "1d" ? activeClass : nonActiveClass;
  const weekClass = timePeriod === "7d" ? activeClass : nonActiveClass;
  const monthClass = timePeriod === "1m" ? activeClass : nonActiveClass;
  const yearClass = timePeriod === "1y" ? activeClass : nonActiveClass;
  const allClass = timePeriod === "all" ? activeClass : nonActiveClass;

  const activeClass2 = "text-blue-500 px-3 py-2 text-sm";
  const nonActiveClass2 = "text-gray-500 hover:text-gray-700 px-3 py-2 text-sm";

  const btcClass = asset === "btc" ? activeClass2 : nonActiveClass2;
  const ethClass = asset === "eth" ? activeClass2 : nonActiveClass2;
  const hbarClass = asset === "hbar" ? activeClass2 : nonActiveClass2;

  const getChangeClass = (num) => {
    if (num === 0) {
      return "font-light text-black";
    } else if (num > 0) {
      return "font-light text-green-500";
    } else {
      return "font-light text-red-500";
    }
  };

  const getChangeArrow = (num) => {
    if (num === 0) {
      return "";
    } else if (num > 0) {
      return " ↑";
    } else {
      return " ↓";
    }
  };

  const getChangeUsd = (summary) => {
    if (summary?.usd_24h_change === 0) {
      return "";
    } else if (summary?.usd_24h_change > 0) {
      return `+${numberWithCommas(
        Number(
          (
            (Number(summary?.usd_24h_change) / 100) *
            Number(summary?.usd)
          ).toFixed(3)
        )
      )}`;
    } else {
      return `${numberWithCommas(
        Number(
          (
            (Number(summary?.usd_24h_change) / 100) *
            Number(summary?.usd)
          ).toFixed(3)
        )
      )}`;
    }
  };

  const selectAsset = (line = true) => {
    return line ? (
      <div>
        <div className="sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setAsset("btc")}
              className={btcClass}
            >
              BTC
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => setAsset("eth")}
              className={ethClass}
            >
              ETH
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => setAsset("hbar")}
              className={hbarClass}
            >
              HBAR
            </div>
          </nav>
        </div>
      </div>
    ) : (
      <div>
        <label
          id="listbox-label"
          className="block text-sm font-medium text-gray-700"
        >
          Assigned to
        </label>
        <div ref={assetRef} className="mt-1 relative">
          <button
            onClick={() => setOpenAsset(!openAsset)}
            type="button"
            className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
          >
            <span className="flex items-center">
              <img
                src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                alt=""
                className="flex-shrink-0 h-6 w-6 rounded-full"
              />
              <span className="ml-3 block truncate">Bitcoin - BTC</span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>

          {openAsset && (
            <ul
              className="absolute mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black z-1000 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li
                onClick={() => {
                  setAsset("btc");
                  setOpenAsset(false);
                }}
                className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                id="listbox-option-0"
                role="option"
              >
                <div className="flex items-center">
                  <img
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
                    alt=""
                    className="flex-shrink-0 h-6 w-6 rounded-full"
                  />
                  <span className="ml-3 block truncate">Bitcoin - BTC</span>
                </div>
              </li>
              <li
                onClick={() => {
                  setAsset("hbar");
                  setOpenAsset(false);
                }}
                className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                id="listbox-option-0"
                role="option"
              >
                <div className="flex items-center">
                  <img
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/4642.png"
                    alt=""
                    className="flex-shrink-0 h-6 w-6 rounded-full"
                  />
                  <span className="ml-3 block truncate">
                    Hedera Hashgraph - HBAR
                  </span>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <main className="flex-1 relative pb-8 overflow-y-auto">
          <div className="">
            <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              {openApplicant ? (
                <div className="py-6 pt-10 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <div
                      onClick={() => {
                        setOpenApplicant(false);
                        setOverride(false);
                      }}
                      className="flex items-center hover:text-blue-500 hover:underline"
                      style={{ cursor: "pointer" }}
                    >
                      ← back
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {/* {verifySnackbar()} */}
                  <div className="py-6 md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center">
                        <div className="hidden sm:block">
                          {user.profilePictureUrl ? (
                            <img
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                              }}
                              src={user.profilePictureUrl}
                              alt={user.profilePictureUrl}
                            />
                          ) : (
                            <Avatar
                              width={"60px"}
                              height={"60px"}
                              fontSize={"35px"}
                              user={user}
                            />
                          )}
                        </div>
                        <div>
                          <div className="flex items-center">
                            <div className="sm:hidden">
                              {user.profilePictureUrl ? (
                                <img
                                  style={{
                                    width: isMobile ? "30px" : "50px",
                                    height: isMobile ? "30px" : "50px",
                                    borderRadius: "50%",
                                  }}
                                  src={user.profilePictureUrl}
                                  alt={user.profilePictureUrl}
                                />
                              ) : (
                                <Avatar
                                  width={isMobile ? "30px" : "50px"}
                                  height={isMobile ? "30px" : "50px"}
                                  fontSize={isMobile ? "20px" : "30px"}
                                  user={user}
                                />
                              )}
                            </div>
                            <h1
                              className={`ml-3 ${
                                isMobile ? "text-md" : "text-2xl"
                              } font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate`}
                            >
                              {greetingTime(new Date())}, {user.firstName}{" "}
                              {user.lastName}
                            </h1>
                          </div>
                          {user.institutional && (
                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                              <dt className="sr-only">Company</dt>
                              <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                {user.institutionOnboarding.form["1a"]}
                              </dd>
                              {user.accreditedStatus && (
                                <React.Fragment>
                                  <dt className="sr-only">Account status</dt>
                                  <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                    <svg
                                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                    Verified account
                                  </dd>
                                </React.Fragment>
                              )}
                            </dl>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          {openApplicant ? (
            <div className="">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                {applicantDetails()}
              </div>
            </div>
          ) : user.accountType === "admin" ? (
            accreditateTableJSX()
          ) : (
            <div className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-sm shadow pt-5 mt-2">
                  <div className="flex items-center justify-between px-5">
                    <div>{selectAsset()}</div>
                    <div>
                      <div className="sm:hidden">
                        <label for="tabs" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="tabs"
                          name="tabs"
                          className="block w-full text-xs focus:ring-indigo-500 border-gray-300 rounded-md"
                        >
                          <option>1d</option>

                          <option>7d</option>

                          <option selected>1m</option>

                          <option>1y</option>
                          <option>all</option>
                        </select>
                      </div>
                      <div className="hidden sm:block">
                        <nav className="flex space-x-4" aria-label="Tabs">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setTimePeriod("1d")}
                            className={dayClass}
                          >
                            1d
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setTimePeriod("7d")}
                            className={weekClass}
                          >
                            7d
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setTimePeriod("1m")}
                            className={monthClass}
                          >
                            1m
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setTimePeriod("1y")}
                            className={yearClass}
                          >
                            1y
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setTimePeriod("all")}
                            className={allClass}
                          >
                            all
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between mt-1 px-7">
                    {!chartSummaryRaw?.summary ? (
                      <span />
                    ) : (
                      <div
                        className={`flex ${
                          isMobile ? "flex-col items-start" : "items-end"
                        }`}
                      >
                        <p
                          className="leading-6 text-gray-900"
                          style={{ fontSize: "22px", fontWeight: "bold" }}
                        >
                          $
                          {numberWithCommas(
                            Number(chartSummaryRaw?.summary.usd).toFixed(3)
                          )}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                          className={getChangeClass(
                            Number(chartSummaryRaw?.summary.usd_24h_change)
                          )}
                        >
                          {getChangeUsd(chartSummaryRaw?.summary)}(
                          {Number(
                            chartSummaryRaw?.summary.usd_24h_change
                          ).toFixed(2)}
                          %)
                          {getChangeArrow(
                            Number(chartSummaryRaw?.summary.usd_24h_change)
                          )}
                        </p>
                      </div>
                    )}{" "}
                    {toolTipRate !== "" && (
                      <div
                        className={`flex items-end ${isMobile && "flex-col"}`}
                      >
                        <p
                          className="font-light text-black"
                          style={{ fontSize: "13px" }}
                        >
                          ${numberWithCommas(Number(toolTipRate).toFixed(2))}
                        </p>
                        <p
                          className="font-light text-gray-400"
                          style={{ marginLeft: "7px", fontSize: "13px" }}
                        >
                          {moment
                            .unix(new Date(toolTipTime).getTime() / 1000)
                            .format(getChartFormat2())}
                        </p>
                      </div>
                    )}
                  </div>

                  <div style={{ marginTop: "10px", zIndex: -10 }}>
                    <ResponsiveContainer width="100%" height={320}>
                      {!chartDataRaw?.data ? (
                        <div
                          className="flex items-center justify-center"
                          style={{ width: "100%", height: "300px" }}
                        >
                          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-6 w-6"></div>
                        </div>
                      ) : (
                        <AreaChart
                          data={chartDataRaw?.data}
                          margin={{ left: 0, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor={getChartColor()}
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor={getChartColor()}
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <YAxis
                            type="number"
                            tick={false}
                            width={0}
                            domain={["auto", "auto"]}
                          />
                          <XAxis
                            interval={timePeriod === "all" ? 350 : 30}
                            dataKey="timestamp"
                            domain={["auto", "auto"]}
                            tickFormatter={(e) =>
                              moment(e).format(getChartFormat())
                            }
                            tick={{ fill: "#6B7280", fontSize: "12px" }}
                          />
                          <Tooltip
                            content={(e) => {
                              let rate = e?.payload?.[0]?.payload?.rate || "";
                              let timestamp =
                                e?.payload?.[0]?.payload?.timestamp || "";

                              setToolTipRate(rate);
                              setToolTipTime(timestamp);

                              return <div></div>;
                            }}
                          />
                          <Area
                            type="monotone"
                            dataKey="rate"
                            stroke={getChartColor()}
                            fillOpacity={1}
                            fill="url(#colorUv)"
                          />
                        </AreaChart>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              {historyParentJSX()}
            </div>
          )}
        </main>
      </BaseProvider>
    </StyletronProvider>
  );
}
