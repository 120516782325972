import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import delay from "delay";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import lock from "../images/lock.svg";
import config from "../config";

export default function Terms() {
  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Workflow</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10"></nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <div className="whitespace-nowrap text-sm font-light uppercase text-gray-500">
                Don't have an account?
              </div>
              <a
                href="/#/onboard"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-600 hover:to-blue-600"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className="flex flex-col justify-center"
              style={{ margin: "0 auto" }}
            >
              <div
                className="text-center"
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "700",
                  marginTop: "20px",
                }}
              >
                Terms &amp; Conditions
              </div>
              <div
                className="text-center"
                style={{ marginBottom: "15px", fontSize: "14px" }}
              >
                Last Updated: February 20, 2021
              </div>
              <div className="mt-6 bg-white rounded-sm shadows">
                <div className="py-3" style={{ fontSize: "12px" }}>
                  These Terms of Service, together with our Privacy Policy,
                  govern your access to and use of the websites and social media
                  (the “Immutable Site”) of Immutable Asset Management, LLC, and
                  those of its subsidiaries and affiliates (collectively,
                  “Immutable”, “we”, “our”, or “us”), and your use of any of the
                  services provided through these Sites. These Terms of Service
                  and any additional terms and conditions, policies, agreements
                  and disclosures to which you have agreed are hereafter
                  referred to collectively as the “Agreement”. Please read these
                  Terms of Service carefully.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Your use of the Immutable Site is governed by the version of
                  the Terms of Service in effect on the date of use. Immutable
                  may modify the Terms of Service at any time and without prior
                  notice. By using and accessing any Immutable Site, you
                  acknowledge and agree to review the most current version of
                  these Terms of Service prior to each such use. Your continued
                  use of and access to any of the Immutable Site constitutes
                  your acknowledgement of, and agreement to, the then current
                  Terms of Service. Please also note that the terms and
                  conditions of these Terms of Service are in addition to any
                  other agreements between you and Immutable and/or its
                  affiliates and agents, including any customer agreements, and
                  any other agreements that govern your use of products,
                  services, content, tools, and information available on the
                  Immutable Sites.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Immutable reserves the right, in its sole discretion, without
                  any obligation and without any notice requirement, to change,
                  improve or correct the information, materials and descriptions
                  on the Immutable Sites and/or to suspend and/or deny access to
                  any Immutable Site for scheduled or unscheduled maintenance,
                  upgrades, improvements or corrections. The information and
                  materials on the Immutable Site may contain typographical
                  errors or inaccuracies. Any dated information is published as
                  of its date only, and Immutable does not undertake any
                  obligation or responsibility to update or amend any such
                  information. Immutable may discontinue or change any product
                  or service described in or offered on Immutable Site at any
                  time without prior notice. Immutable further reserves the
                  right, in its sole discretion, to block or otherwise
                  discontinue your access and use of Immutable Site at any time
                  and for any reason. You agree that Immutable and its
                  subsidiaries and affiliates will not be liable to you or to
                  any third party for any such modification, suspension or
                  discontinuance.
                </div>
                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  AUTHORIZED USER
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Some of our services, and certain pages of the Immutable Site,
                  are available only to clients or users who have been
                  authorized by us to access those services and web pages. Such
                  authorization may require completion of an accredited investor
                  questionnaire and satisfactory background information
                  screening.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Unauthorized use of any Immutable Site and/or our systems,
                  including, but not limited to, unauthorized entry into and/or
                  any attempted access of Immutable’s systems and/or any
                  restricted areas of any of the Immutable Site, misuse or
                  sharing of passwords or misuse of any other information, is
                  strictly prohibited. You may not use any Immutable Site in any
                  manner that could damage, disable, overburden, or impair any
                  Immutable Site or service or interfere with any other party’s
                  use and enjoyment of the Immutable Site or service. You may
                  not attempt to gain unauthorized access to the Immutable Site
                  or service, computer systems or networks connected to the
                  Immutable Site or service, through hacking, password mining or
                  any other means. You may not screen-scrape, data scrape and/or
                  use any automated means to acquire data and/or information
                  from our Sites. You agree that you will not engage in any
                  activities related to the Immutable Site that are contrary to
                  these Terms of Service and/or any applicable laws or
                  regulations. You agree to notify us immediately in the event
                  that you learn or suspect that the security of your password
                  may have been compromised. You further agree that you are
                  responsible for any unauthorized use of your password that is
                  made before you have notified us and we have had a reasonable
                  opportunity to act on that notice. We reserve the right to
                  suspend or cancel your password, even without receiving such
                  notice from you, if we suspect that it is being used in an
                  unauthorized or fraudulent manner.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Notwithstanding the above, you are responsible for monitoring
                  your use of the Immutable Site and should promptly report any
                  unauthorized or suspicious activity to us at the contact email
                  provided below.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The information on these sites is provided "AS IS". Immutable
                  does not warrant the accuracy of the materials provided
                  herein, either expressly or implied, for any particular
                  purpose and expressly disclaims any warranties of
                  merchantability or fitness for a particular purpose. Immutable
                  will not be responsible for any loss or damage that could
                  result from interception by third parties of any information
                  made available to you via the Immutable Site. Although the
                  information provided to you on these sites is obtained or
                  compiled from sources, we believe to be reliable, Immutable
                  cannot and does not guarantee the accuracy, validity,
                  timeliness or completeness of any information or data made
                  available to you for any particular purpose. Neither
                  Immutable, nor any of its affiliates, directors, officers or
                  employees, nor any third-party vendor will be liable or have
                  any responsibility of any kind for any loss or damage that you
                  incur in the event of any failure or interruption of the
                  Immutable Site, or resulting from the act or omission of any
                  other party involved in making the Immutable Site or the data
                  contained therein available to you, or from any other cause
                  relating to your access to, inability to access, or use of the
                  sites or these materials, whether or not the circumstances
                  giving rise to such cause may have been within the control of
                  Immutable or of any vendor providing software or services
                  support. In no event will Immutable, its affiliates or any
                  such parties be liable to you for any direct, special,
                  indirect, consequential, incidental damages or any other
                  damages of any kind even if Immutable or any other party have
                  been advised of the possibility thereof.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Immutable is under no obligation to update or correct any
                  information provided on the Immutable Site. Statements and
                  opinions are subject to change without notice. No content on
                  these sites is intended to provide tax or legal advice. You
                  are advised to seek advice on these matters from separately
                  retained professionals.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  USE OF LINKS
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Should the viewer leave this site via a link contained herein,
                  and view content that is not provided by Immutable, the viewer
                  does so at its own risk. The content to which you link will
                  not have been developed, checked for accuracy or otherwise
                  reviewed by Immutable. Immutable is not responsible for
                  damages or losses caused by any delays, defects or omissions
                  that may exist in the services, information or other content
                  provided in such site, whether actual, alleged, consequential
                  or punitive. Immutable makes no guarantees or representations
                  as to, and shall have no liability for, any electronic content
                  delivered by any third party, including, without limitation,
                  the accuracy, subject matter, quality or timeliness of any
                  electronic content.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  THIRD PARTY CONTENT
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Immutable works with third-party providers of information in
                  connection with the Services. Immutable does not endorse or
                  guarantee any such third-party information for any purpose,
                  including but not limited to accuracy, legality,
                  non-infringement or fitness for any particular purpose.
                  Immutable is not responsible for any third-party products and
                  services or third-party websites accessed through the
                  Services.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  SOCIAL MEDIA DISCLOSURE
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Social media posts (including but not limited to, Facebook,
                  LinkedIn and Twitter) of Immutable and Immutable’s employees
                  (“Social Media”) are not, and should not be considered to be
                  recommendations, solicitations or offers by Immutable or its
                  affiliates to buy or sell any securities, futures, options or
                  other financial instruments or other assets or provide any
                  investment advice or service. Immutable makes all reasonable
                  efforts to ensure that the information contained on Social
                  Media is accurate and reliable; however, errors sometimes
                  occur. You should note that the materials on Social Media are
                  provided “as is” without any express or implied warranties.
                  Immutable does not warrant or represent that the materials on
                  Social Media are accurate, valid, timely or complete.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  CONSENT TO ELECTRONIC COMMUNICATIONS
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  By using the Service, you consent to receiving certain
                  electronic communications from us as further described in the
                  Privacy Policy. Please read the Privacy Policy to learn more
                  about your choices regarding our electronic communications
                  practices. You agree that any notices, agreements,
                  disclosures, or other communications relating to the Service
                  or these Terms of Service that we send to you electronically
                  will satisfy any legal communication requirements, including
                  that such communications be in writing.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  SECURITIES PRODUCTS
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  None of the information displayed on or downloadable from, nor
                  any of the applications and services available via the
                  publicly accessible portions of the Immutable Site represent
                  an offer to buy or sell, or the solicitation of an offer to
                  buy or sell any security, nor does it constitute an offer to
                  provide investment advice or service.
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  Investing in private, unregistered securities is speculative
                  and involves a high degree of risk. You must be prepared to
                  withstand a total loss of your investment. You are strongly
                  encouraged to complete your own independent due diligence
                  before investing in private, unregistered securities,
                  including obtaining additional information, opinions,
                  financial projections, and legal or other investment advice.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  INDEMNITY AND RELEASE
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  You agree to release, indemnify and hold Immutable harmless
                  from any from any and all losses, damages, expenses, including
                  reasonable attorneys’ fees, rights, claims, actions of any
                  kind and injury (including death) arising out of or relating
                  to your use of the Immutable Sites. If you are a California
                  resident, you waive California Civil Code Section 1542, which
                  says: “A general release does not extend to claims which the
                  creditor does not know or suspect to exist in his favor at the
                  time of executing the release, which if known by him must have
                  materially affected his settlement with the debtor.” If you
                  are a resident of another jurisdiction, you waive any
                  comparable statute or doctrine.
                </div>

                <div
                  className=""
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  CHOICE OF LAW
                </div>
                <div className="py-3" style={{ fontSize: "12px" }}>
                  The Agreement shall be governed by and construed and enforced
                  in accordance with the laws of the State of Delaware, without
                  regard to conflicts of laws provisions. Unless otherwise
                  agreed in writing by you and us, any dispute arising out of or
                  relating to the Agreement, or the breach hereof, shall be
                  finally resolved by arbitration administered by the American
                  Arbitration Association under its Commercial Arbitration
                  Rules, or such arbitration body as required by law, rule or
                  regulation, and judgment upon the award rendered by the
                  arbitrator may be entered in any court having jurisdiction.
                  The arbitration will be conducted in the English language
                  before a single arbitrator in the City of Wilmington,
                  Delaware. Such arbitration must be commenced within one (1)
                  year after the claim or cause of action arises. If for any
                  reason any provision of this Agreement, or a portion thereof,
                  shall be unenforceable, that provision shall be enforced to
                  the maximum extent permissible so as to effect the intent of
                  this Agreement, and the remainder of this Agreement shall
                  continue in full force and effect. This Agreement constitutes
                  the entire agreement between us and you with respect to these
                  sites and it supersedes all prior or contemporaneous
                  communications, agreements and understandings between
                  Immutable and you with respect to the subject matter hereof. A
                  printed version of this Agreement shall be admissible in
                  judicial or administrative proceedings.
                </div>
              </div>
            </div>

            <div
              className="flex items-center justify-center py-10"
              style={{ margin: "0 auto" }}
            >
              {isMobile ? (
                <React.Fragment>
                  <div className="flex flex-col items-center">
                    <img
                      src={lock}
                      alt="secure"
                      style={{
                        height: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <div
                      className="text-gray-700"
                      style={{
                        textAlign: "center",
                        fontSize: "8px",
                      }}
                    >
                      Your application, account, and device information and use
                      patterns will be shared with Immutable Holdings LLC,
                      financial partners, and service providers for application
                      processing, identity and entity verification, fraud
                      prevention, security, to tailor services to you, comply
                      with our legal obligations, and communicate with you.{" "}
                      <a className="text-blue-500" href="/#/privacy">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src={lock}
                    alt="secure"
                    style={{
                      height: "20px",
                      marginLeft: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="text-gray-700" style={{ fontSize: "10px" }}>
                    Your application, account, and device information and use
                    patterns will be shared with Immutable Holdings LLC,
                    financial partners, and service providers for application
                    processing, identity and entity verification, fraud
                    prevention, security, to tailor services to you, comply with
                    our legal obligations, and communicate with you.{" "}
                    <a className="text-blue-500" href="/#/privacy">
                      Privacy Policy
                    </a>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
