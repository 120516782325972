import userEvent from "@testing-library/user-event";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import immutableLogo from "../images/immutable.svg";

import facebook from "../images/facebook.svg";
import twitter from "../images/twitter.svg";
import linkedin from "../images/linkedin.svg";
import medium from "../images/medium.svg";
import youtube from "../images/youtube.svg";

export default function SideBar(props) {
  const history = useHistory();
  const { path, openSideBar, user } = props;

  const activeBar = `border-2 border-blue-500 text-blue-500 hover:bg-gray-100 my-2 group flex items-center px-4 py-2 text-base rounded-md font-normal transition duration-200`;
  const nonActiveBar1 = `text-black hover:bg-gray-100 my-2 group flex items-center px-4 py-2 text-base rounded-md font-light transition duration-200`;
  const nonActiveBar2 = `group flex hover:bg-gray-100 items-center px-4 my-2 py-2 text-base rounded-md text-black font-light transition duration-200`;

  const homeClass = path === "/dashboard" ? activeBar : nonActiveBar1;
  const portfolioClass = path === "/portfolio" ? activeBar : nonActiveBar1;
  const historyClass = path === "/history" ? activeBar : nonActiveBar1;
  const reportClass = path === "/reports" ? activeBar : nonActiveBar1;

  // settings subclass
  const settingClass =
    path === "/settings" ||
    path === "/verification" ||
    path === "/password" ||
    path === "/statements" ||
    path === "/upload-form" ||
    path === "/tax" ||
    path === "/ats"
      ? activeBar
      : nonActiveBar2;

  const helpClass = path === "/help" ? activeBar : nonActiveBar2;
  const privacyClass = path === "/privacy" ? activeBar : nonActiveBar2;

  return (
    <React.Fragment>
      {openSideBar && (
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            <div className="fixed inset-0">
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                aria-hidden="true"
              ></div>
            </div>
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  onClick={() => props.setOpenSideBar(!openSideBar)}
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                >
                  <span className="sr-only">Close sidebar</span>
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  style={{ marginLeft: "0.5rem" }}
                  className="h-8 w-auto"
                  src={immutableLogo}
                  alt="Immutable Holdings Logo"
                />
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/dashboard")}
                    className={homeClass}
                    aria-current="page"
                  >
                    Home
                  </div>

                  {user.accountType !== "admin" && (
                    <React.Fragment>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/portfolio")}
                        className={portfolioClass}
                      >
                        Portfolio
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/history")}
                        className={historyClass}
                      >
                        History
                      </div>
                    </React.Fragment>
                  )}

                  {/* <div
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/reports")}
                    className={reportClass}
                  >
                    Reports
                  </div> */}
                </div>
                <div className="">
                  <div className="px-2 space-y-1">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/settings")}
                      className={settingClass}
                    >
                      Settings
                    </div>
                    {/* {user.accountType !== "admin" && (
                      <React.Fragment>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/help")}
                          className={helpClass}
                        >
                          Help
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/privacy")}
                          className={privacyClass}
                        >
                          Privacy
                        </div>
                      </React.Fragment>
                    )} */}
                  </div>
                </div>
              </nav>
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </div>
        </div>
      )}

      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col flex-grow bg-white pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                style={{ marginLeft: "0.5rem" }}
                className="h-8 w-auto"
                src={immutableLogo}
                alt="Immutable Holdings Logo"
              />
            </div>
            <nav
              className="mt-5 flex-1 flex flex-col overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="px-2 space-y-1">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/dashboard")}
                  className={homeClass}
                  aria-current="page"
                >
                  Home
                </div>

                {user.accountType !== "admin" && (
                  <React.Fragment>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/portfolio")}
                      className={portfolioClass}
                    >
                      Portfolio
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/history")}
                      className={historyClass}
                    >
                      History
                    </div>
                  </React.Fragment>
                )}
                {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/reports")}
                  className={reportClass}
                >
                  Reports
                </div> */}
              </div>
              <div className="py2">
                <div className="px-2">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/settings")}
                    className={settingClass}
                  >
                    Settings
                  </div>

                  {/* {user.accountType !== "admin" && (
                    <React.Fragment>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/help")}
                        className={helpClass}
                      >
                        Help
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/privacy")}
                        className={privacyClass}
                      >
                        Privacy
                      </div>
                    </React.Fragment>
                  )} */}
                </div>
              </div>
            </nav>
          </div>
          <div className="bg-white flex-shrink-0 flex border-t border-gray-200 p-4">
            <div
              style={{ paddingLeft: "0.5rem" }}
              className="text-sm font-light"
            >
              <div
                onClick={() => {
                  window.open(`/#/disclaimer`, "_blank");
                }}
                style={{ cursor: "pointer" }}
                className="hover:text-blue-500 transition duration-200 pt-2"
              >
                Disclaimer
              </div>
              <div
                onClick={() => {
                  window.open(`/#/privacy`, "_blank");
                }}
                style={{ cursor: "pointer" }}
                className="hover:text-blue-500 transition duration-200 pt-2"
              >
                Privacy Policy
              </div>
              <div
                onClick={() => {
                  window.open(`/#/terms`, "_blank");
                }}
                style={{ cursor: "pointer" }}
                className="hover:text-blue-500 transition duration-200 pt-2"
              >
                Terms &amp; Conditions
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
