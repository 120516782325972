import React, { useEffect, useState, useRef } from "react";
import SelectMenu from "./selectMenu";
import { useHistory } from "react-router-dom";
import BinarySelect from "./binarySelect";
import HelloSign from "hellosign-embedded";
import FileUpload from "./fileUpload";
import config from "../config";
import { countries } from "../geography";
import { funds } from "../funds";
import { Document, Page, pdfjs } from "react-pdf";
import fileIcon from "../images/fileIcon.svg";
import pdfIcon from "../images/pdfIcon.svg";
import excelIcon from "../images/excelIcon.svg";
import wordIcon from "../images/wordIcon.svg";
import Notification from "./notification";
import axios from "axios";
import delay from "delay";
import { isMobile } from "react-device-detect";
import { StatefulTooltip } from "baseui/tooltip";
import { getFundHelper } from "../global";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled } from "baseui";
import { PhoneInput, SIZE } from "baseui/phone-input";

const engine = new Styletron();

export default function IndividualApp(props) {
  const {
    user,
    location,
    fund,
    form,
    addedTX,
    setForm,
    loadingJSX,
    canSubmit,
    handleForm,
    portfolio,
    fundOverride,
    fundOverview,
    scrollTop,
  } = props;

  const history = useHistory();
  const jwt = localStorage.getItem("immutable-jwt");

  const [innerCheck, setInnerCheck] = useState(false);
  const [openFundLinks, setOpenFundLinks] = useState(false);

  const [notificationArray, setNotificationArray] = useState([]);
  const [optionalState, setOptionalState] = useState([]);

  const [fiveYearAccreditation, setFiveYearAccreditation] = useState(undefined);
  const [showApplication, setShowApplication] = useState(false);
  const [showPreviousPage, setShowPreviousPage] = useState(false);

  const [loadingEmbeddedURL, setLoadingEmbeddedURL] = useState("");
  const [embeddedURL, setEmbeddedURL] = useState("");

  const [executedSubDocURL, setExecutedSubDocURL] = useState("");
  const [submitting, setSubmit] = useState(false);

  const [signed, setSigned] = useState(false);

  const [uploadObject, setUploadObject] = useState({});
  const [fileNameObject, setFileNameObject] = useState({});
  const [fileObject, setFileObject] = useState({});
  const [fileUrlObject, setFileUrlObject] = useState({});

  const [index, setIndex] = useState(0);

  const client = new HelloSign({ hideHeader: true });

  const getFileIcon = (extension) => {
    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "docx":
      case "doc":
        return wordIcon;
      case "xls":
      case "xlsx":
        return excelIcon;
      default:
        return fileIcon;
    }
  };

  let debug = config.debug;
  let base = debug ? config.debugURL : config.productionURL;

  const getSignatureRequest = async (signatureID) => {
    try {
      if (executedSubDocURL === "") {
        let getSignatureRequestURL = `${base}/user/getSignatureRequest`;

        const response = await axios.post(
          getSignatureRequestURL,
          {
            signatureID: signatureID,
            type: "individual",
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const data = response.data;
        setExecutedSubDocURL(data.url);
      }
    } catch (err) {
      console.log("error while getting signature request: ", err);
    }
  };

  const setSignedPost = async () => {
    try {
      let setSignURL = `${base}/user/setSigned`;

      const response = await axios.post(
        setSignURL,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      console.log("signed doc response: ", response.data);
    } catch (err) {
      console.log("error while setting doc as signed: ", err);
    }
  };

  // gets embedded URL by selection of fund
  const getEmbeddedURL = async () => {
    try {
      setLoadingEmbeddedURL(true);
      let appUrlSecond = `${base}/user/createSignatureRequest`;

      const response = await axios.post(
        appUrlSecond,
        {
          signerEmail: user.email,
          signerName: user.firstName,
          institutional: user.institutional,
          form: form,
          fundOverride: fundOverride,
          subject: `Your ${form?.["3a"]} Subscription Document`,
          message: "Please sign!",
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      setEmbeddedURL(response?.data?.helloSignUrl);
      setLoadingEmbeddedURL(false);

      return response?.data?.helloSignUrl;
    } catch (err) {
      setLoadingEmbeddedURL(false);
      console.log("error while retrieving hellosign: ", err);
    }
  };

  const accreditationJSX = () => {
    return (
      <React.Fragment>
        <div className="">
          <div role="group" aria-labelledby="label-email">
            <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-baseline">
              <div className="sm:mt-0 sm:col-span-2">
                <div className="text-xs text-gray-700 font-light pb-2">
                  Please indicate which of the following categories, if any, you
                  satisfy:
                </div>

                <div className="max-w-lg space-y-4">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        checked={form?.["2a"]}
                        disabled={showApplication}
                        onChange={(e) => {
                          handleForm("2a", e.target.checked);
                          handleForm("2b", false);
                          handleForm("2c", false);
                        }}
                        id="comments"
                        name="comments"
                        type="radio"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        for="comments"
                        className="font-medium text-gray-700"
                      >
                        I earned income over US $200K (US $300K with spouse) in
                        each of the last 2 years and have a reasonable
                        expectation of earning at least US $200K (US $300K with
                        spouse) in the curernt fiscal year.
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          checked={form?.["2b"]}
                          disabled={showApplication}
                          onChange={(e) => {
                            handleForm("2b", e.target.checked);
                            handleForm("2a", false);
                            handleForm("2c", false);
                          }}
                          id="candidates"
                          name="candidates"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          for="candidates"
                          className="font-medium text-gray-700"
                        >
                          I have a net worth (excluding primary residence) of
                          over US $1,000,000.
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          checked={form?.["2c"]}
                          disabled={showApplication}
                          onChange={(e) => {
                            handleForm("2c", e.target.checked);

                            handleForm("2a", false);
                            handleForm("2b", false);
                          }}
                          id="offers"
                          name="offers"
                          type="radio"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          for="offers"
                          className="font-medium text-gray-700"
                        >
                          None of the above
                        </label>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          defaultChecked={form?.["2d"]}
                          onChange={(e) => handleForm("2d", e.target.checked)}
                          id="offers"
                          name="offers"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          for="offers"
                          className="font-medium text-gray-700"
                        >
                          I certify that the information provided by me is true
                          and accurate. I understand that I may be permanently
                          banned from this platform if I provide false
                          information regarding my status as an accredited
                          investor.
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* upload accreditation status */}
                  {form && form?.["2d"] === true && !form?.["2c"] && (
                    <div style={{ paddingTop: "50px" }}>
                      <section id="authorized-representatives">
                        <div
                          style={{
                            fontSize: isMobile ? "20px" : "30px",
                            fontWeight: "700",
                            lineHeight: "1em",
                          }}
                        >
                          Accreditation Verification
                        </div>
                        <div
                          style={{
                            content: "",
                            display: "block",
                            width: "75px",
                            height: "5px",
                            backgroundColor: "#3C81F6",
                            marginTop: "20px",
                            borderRadius: "4px",
                            marginBottom: "20px",
                          }}
                        />

                        <div className="text-xs text-gray-700 font-light pb-2">
                          {form?.["2a"] === true
                            ? `Please upload documents for the past 2 years such as tax filings, (W-2, 1099, 1040), or a letter from a licensed CPA attorney, investment advisor, or broker dated within the last 90 days certifying your accredited investor status.`
                            : `Please upload a copy of a bank statement, brokerage account balance, asset appraisals, or letter from an licensed CPA, attorney, investment advisor, or broker. All must be dated within the last 90 days demonstrating that you are an accredited investor.`}
                        </div>

                        <div className="form-grid">
                          {form?.["2a"] === true ? (
                            <div>
                              {fileUploadJSX(
                                `2020 proof of income`,
                                "accreditation-income-1"
                              )}
                              {fileUploadJSX(
                                `2019 proof of income`,
                                "accreditation-income-2"
                              )}
                            </div>
                          ) : (
                            <div>
                              {fileUploadJSX(
                                `Upload proof of net worth`,
                                "accreditation-networth"
                              )}
                            </div>
                          )}
                        </div>
                      </section>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getHelloSignJSX = () => {
    return !form?.["signedDoc"] ||
      !form?.["onboardingSubDoc"] ||
      (addedTX && portfolio) ? (
      <div
        onClick={async () => {
          let embeddedURL = await getEmbeddedURL();

          client.open(embeddedURL, {
            clientId: "c02728275347a4c89bd0b78e44d8cbfd",
            skipDomainVerification: true,
          });
        }}
        style={{ cursor: "pointer", width: "180px" }}
        className={`inline-flex items-center justify-center px-8 py-2 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-green-600 bg-green-500`}
      >
        {loadingEmbeddedURL ? "opening..." : "Sign Subscription"}
      </div>
    ) : (
      <div className="text-sm font-light">
        Executed Document ID: {form?.["onboardingSubDoc"]}&nbsp;
        <span
          className="text-blue-500 hover:underline"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (!(form?.["executedSubDocURL"] || executedSubDocURL)) {
              getSignatureRequest(form?.["onboardingRequestId"]);
            } else {
              window.open(
                form?.["executedSubDocURL"] || executedSubDocURL,
                "_blank"
              );
            }
          }}
        >
          {!(form?.["executedSubDocURL"] || executedSubDocURL)
            ? "(load)"
            : "(download)"}
        </span>
      </div>
    );
  };

  const addNotification = (subject, text, seconds) => {
    notificationArray.push(
      <Notification subject={subject} text={text} seconds={seconds} />
    );
  };

  useEffect(() => {
    if (form?.["onboardingRequestId"]) {
      getSignatureRequest(form?.["onboardingRequestId"]);
    }
  }, [form]);

  const scrollBottom = () => {
    const divToScrollTo = document.getElementById("applicationBottom");
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView(true);
    }
  };

  // helloSign listener
  client.on("sign", async (data) => {
    await setSignedPost();
    await delay(1000);
    // handleForm("signedDoc", true);
    // setSigned(true);
    if (Number(form?.["status"]) === 3) {
      await delay(3000);
      alert("Success! Redirecting you to wiring instructions");
      history.push("/history");
    } else {
      alert("Successfully signed!");
      scrollBottom();
    }
  });

  function truncate(input) {
    if (input.length > 5 && isMobile) {
      return input.substring(0, 5) + "...";
    }
    return input;
  }

  const fileUploadJSX = (description, formID, multiple = false) => {
    return (
      <div className="form-grid">
        {form?.[formID] &&
        form?.[formID] !== "" &&
        JSON.parse(form?.[formID])?.url !== "" &&
        !uploadObject[formID] ? (
          <div className="flex flex-col">
            <div className="text-xs font-light">{description}:&nbsp;</div>
            <div className="flex items-center text-sm py-2">
              <img
                src={getFileIcon(
                  form?.["formID"]?.name
                    ? JSON.parse(form?.["formID"])
                        ?.name.split(".")
                        .pop()
                        .toLowerCase()
                    : ""
                )}
                alt={JSON.parse(form?.[formID])?.name}
                style={{ marginRight: "10px", height: "20px" }}
              />
              <div
                onClick={() =>
                  window.open(JSON.parse(form?.[formID])?.url, "_blank")
                }
                style={{ cursor: "pointer" }}
                className="hover:underline hover:text-blue-600 text-blue-400"
              >
                {truncate(JSON.parse(form?.[formID])?.name) || "Uploaded File"}
              </div>
              {showApplication ? (
                <span />
              ) : (
                <div
                  style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    fileObject[formID] = "";
                    uploadObject[formID] = true;
                    setIndex(index + 1);
                  }}
                  className="text-black hover:text-yellow-600"
                >
                  (change)
                </div>
              )}
            </div>
          </div>
        ) : (
          <React.Fragment>
            {form?.[formID] && JSON.parse(form?.[formID])?.url && (
              <div
                style={{
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  uploadObject[formID] = false;
                  setIndex(index + 1);
                }}
                className="text-red-500 hover:text-red-600 text-sm"
              >
                (cancel)
              </div>
            )}
            <FileUpload
              label={description}
              required={true}
              jwt={jwt}
              multiple={multiple}
              setFile={(e) => {
                fileObject[formID] = e;
                setIndex(index + 1);
              }}
              file={fileObject[formID]}
              setFileName={(e) => {
                fileNameObject[formID] = e;
                setIndex(index + 1);
              }}
              setFileURL={(e) => {
                fileUrlObject[formID] = e;
                const fileObject = { name: fileNameObject[formID], url: e };
                handleForm(formID, JSON.stringify(fileObject));
                setIndex(index + 1);
              }}
              fileURL={fileUrlObject[formID]}
              fileName={fileNameObject[formID]}
            />
          </React.Fragment>
        )}
      </div>
    );
  };

  const getAmlForms = () => {
    return (
      <div>
        {fileUploadJSX(
          `Upload a current valid passport or national ID card, driver's license or government issued ID with photograph`,
          "individual-ID"
        )}
        {fileUploadJSX(
          `Upload proof of residence dated within the last 12 months (must match address provided above) such as a copy of a recent bank statement or utility bill (other than a mobile phone bill)`,
          "individual-residential"
        )}
      </div>
    );
  };

  const format_ssn = (text) => {
    if (text) {
      var numbers = text?.replace(/[^0-9]/g, "");
      if (numbers.length == 9) {
        text =
          numbers.substr(0, 3) +
          "-" +
          numbers.substr(3, 2) +
          "-" +
          numbers.substr(5, 4);
      }
    }

    handleForm("1e", text);
  };

  // "defi-index-fund"
  // "equity-index-fund"
  // "hbar-trust"
  // "btc-trust"
  // "ethereum-trust"

  const getFund = (fund) => {
    switch (fund) {
      case "BTC-Trust 1":
        return "btc-trust";
      case "HBAR-Trust 1":
        return "hbar-trust";
      default:
        return "n/a";
    }
  };

  const fundInfo = getFundHelper(getFund(form?.["3a"]));

  const getFundInfoText = () => {
    return fundInfo.info.map((item, index) => {
      return (
        <li className="col-span-1 flex shadow-sm rounded-md">
          <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
              <a
                href="#"
                className="text-gray-900 font-medium hover:text-gray-600"
              >
                {item.first}
              </a>
              <p className="text-gray-500">{item.second}</p>
            </div>
            {item.third !== "" && (
              <StatefulTooltip
                content={() => <div>{item.third}</div>}
                returnFocus
                autoFocus
              >
                <div className="flex-shrink-0 pr-2">
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">Open options</span>
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </StatefulTooltip>
            )}
          </div>
        </li>
      );
    });
  };

  const getFundLinks = () => {
    return fundInfo.links
      .filter((i, index) => {
        if (openFundLinks || !portfolio) return true;
        else {
          if (index < 2) return true;
          else return false;
        }
      })
      .map((item, index) => {
        return (
          <li>
            <div className="block">
              <div className="p-4 flex items-center">
                <div className="min-w-0 flex-1 flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className={`flex ${
                        isMobile ? "text-xs" : "text-sm"
                      } font-medium text-indigo-600`}
                    >
                      <p>{item.first}</p>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="mt-4 flex-shrink-0 sm:mt-0"
                  >
                    <div
                      onClick={() => {
                        window.open(item.third, "_blank");
                      }}
                      style={{ cursor: "pointer" }}
                      className={`ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm ${
                        isMobile ? "text-xs" : "text-sm"
                      } font-medium text-green-600 bg-green-100 hover:bg-green-200 duration-200`}
                    >
                      {item.second !== "" ? item.second : "Download"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      });
  };

  const sufficientAmount = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed >= 100000;
  };

  const insufficientAmountText = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed < 100000 ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        Minimum investment amount not met
      </p>
    ) : (
      ""
    );
  };

  const insufficientAmount = (input) => {
    let parsed = Number(input?.replace(/,/g, ""));

    return parsed < 100000 ? "border-red-500 text-red-500" : "";
  };

  useEffect(() => {
    const optionStates = countries.filter((option, i) => {
      return option.name === form?.["1k"];
    });

    const optionsProp = optionStates?.[0]?.states;

    const optionalState = optionsProp?.length > 0 ? form?.["1l"] : true;
    setOptionalState(optionalState);
  }, [form?.["1i"]]);

  const mainApp = (full = true) => {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <section id="basic-info" className={`${!full ? "mt-5" : ""}`}>
            <div
              style={{
                fontSize: isMobile ? "20px" : "30px",
                fontWeight: "700",
                lineHeight: "1em",
              }}
            >
              Investor Information
            </div>
            <div
              style={{
                content: "",
                display: "block",
                width: "75px",
                height: "5px",
                backgroundColor: "#3C81F6",
                marginTop: "20px",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
            />

            <div className="form-grid">
              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-3 md:gap-4"
                style={{ width: "100%" }}
              >
                <div>
                  <div className="form-question">First Name</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1a"]}
                      onChange={(e) => handleForm("1a", e)}
                      disabled={showApplication}
                      type="text"
                      name="text"
                      id="text"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">Middle Name</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1b"]}
                      onChange={(e) => handleForm("1b", e)}
                      disabled={showApplication}
                      type="text"
                      name="text"
                      id="text"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">Last Name</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1c"]}
                      onChange={(e) => handleForm("1c", e)}
                      disabled={showApplication}
                      type="text"
                      name="text"
                      id="text"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-3 md:gap-4"
                style={{ width: "100%" }}
              >
                <div>
                  <div className="form-question">Date of Birth</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1d"]}
                      onChange={(e) => handleForm("1d", e)}
                      disabled={showApplication}
                      type="date"
                      name="number"
                      id="number"
                      style={{ height: "35px", width: isMobile && "100%" }}
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">SSN / Tax ID</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1e"]}
                      onBlur={() => format_ssn(form?.["1e"])}
                      onChange={(e) => handleForm("1e", e)}
                      disabled={showApplication}
                      type="text"
                      name="number"
                      id="number"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">Phone Number</div>
                  <div className="form-space" />
                  <div className="">
                    <PhoneInput
                      country={form?.["1f_country"]}
                      disabled={showApplication}
                      onCountryChange={({ option }) =>
                        handleForm("1f_country", option)
                      }
                      size={SIZE.mini}
                      text={form?.["1f"]}
                      onTextChange={(e) => {
                        handleForm("1f", e);

                        if (form?.["1f_country"] === undefined) {
                          handleForm("1f_country", {
                            label: "United States",
                            id: "US",
                            dialCode: "+1",
                          });
                        }
                      }}
                      overrides={{
                        CountrySelect: {
                          props: {
                            overrides: {
                              Root: {
                                border: "none",
                              },
                              ControlContainer: {
                                border: "none",
                              },
                            },
                          },
                        },
                        Input: {
                          props: {
                            overrides: {
                              InputContainer: {
                                style: {
                                  backgroundColor: "white",
                                  border: "none",
                                  borderRadius: "6px",
                                  border: "1px solid #D1D5DB",
                                  height: "35px",
                                },
                              },
                              Input: {
                                style: {
                                  fontSize: "14px",
                                  color: "#222222",
                                  border: "none",
                                  border: "1px solid #D1D5DB",
                                },
                              },
                              Root: {
                                style: {
                                  border: "none",
                                  boxShadow: "none",
                                  borderRadius: "6px",
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                style={{ width: "100%" }}
              >
                <div>
                  <div className="form-question">Street Address</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1g"]}
                      disabled={showApplication}
                      onChange={(e) => handleForm("1g", e)}
                      type="text"
                      name="number"
                      id="number"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">City</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1h"]}
                      disabled={showApplication}
                      onChange={(e) => handleForm("1h", e)}
                      type="text"
                      name="number"
                      id="number"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                style={{ width: "100%" }}
              >
                <div>
                  <div className="form-question">State/Region</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1i"]}
                      disabled={showApplication}
                      onChange={(e) => handleForm("1i", e)}
                      type="text"
                      name="number"
                      id="number"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="form-question">Zip Code</div>
                  <div className="form-space" />
                  <div>
                    <input
                      value={form?.["1j"]}
                      disabled={showApplication}
                      onChange={(e) => handleForm("1j", e)}
                      type="text"
                      name="number"
                      id="number"
                      className="hover:border-blue-500 border-2 form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div
                className="max-w-6xl mx-auto md:grid md:grid-cols-2 md:gap-4"
                style={{ width: "100%" }}
              >
                <SelectMenu
                  label={"Country"}
                  disabled={showApplication}
                  placeholder={"Select an option"}
                  currentSelection={form?.["1k"]}
                  setSelection={(e) => {
                    handleForm("1k", e);
                  }}
                  options={countries}
                />

                <SelectMenu
                  disabled={showApplication}
                  label={"Country of Citizenship"}
                  placeholder={"Select an option"}
                  currentSelection={form?.["1l"]}
                  setSelection={(e) => {
                    handleForm("1l", e);
                  }}
                  options={countries}
                />
              </div>

              {form &&
                form?.["1a"] &&
                form?.["1c"] &&
                form?.["1d"] &&
                form?.["1e"] &&
                form?.["1f"] &&
                form?.["1g"] &&
                form?.["1h"] &&
                form?.["1i"] &&
                form?.["1j"] &&
                form?.["1k"] &&
                optionalState && (
                  <div style={{ paddingTop: "50px" }}>
                    <section id="accreditation">
                      <div
                        style={{
                          fontSize: isMobile ? "20px" : "30px",
                          fontWeight: "700",
                          lineHeight: "1em",
                        }}
                      >
                        Accredited Investor Certification
                      </div>
                      <div
                        style={{
                          content: "",
                          display: "block",
                          width: "75px",
                          height: "5px",
                          backgroundColor: "#3C81F6",
                          marginTop: "20px",
                          borderRadius: "4px",
                          marginBottom: "20px",
                        }}
                      />

                      <div className="form-grid">
                        <React.Fragment>
                          {accreditationJSX()}

                          {form?.["2d"] === true &&
                            ((form?.["accreditation-income-1"] &&
                              form?.["accreditation-income-2"]) ||
                              form?.["accreditation-networth"]) && (
                              <div style={{ paddingTop: "50px" }}>
                                <section id="aml-document">
                                  <div
                                    style={{
                                      fontSize: isMobile ? "20px" : "30px",
                                      fontWeight: "700",
                                      lineHeight: "1em",
                                    }}
                                  >
                                    Required Anti-Money Laundering Documentation
                                  </div>
                                  <div
                                    style={{
                                      content: "",
                                      display: "block",
                                      width: "75px",
                                      height: "5px",
                                      backgroundColor: "#3C81F6",
                                      marginTop: "20px",
                                      borderRadius: "4px",
                                      marginBottom: "20px",
                                    }}
                                  />{" "}
                                  <div
                                    className="max-w-6xl pb-2 mx-auto md:grid md:grid-cols-1 md:gap-4"
                                    style={{ width: "100%" }}
                                  >
                                    {getAmlForms()}
                                  </div>
                                </section>
                              </div>
                            )}

                          {!showApplication &&
                            full &&
                            form?.["individual-ID"] &&
                            form?.["individual-residential"] && (
                              <React.Fragment>
                                <div
                                  style={{ paddingTop: "50px" }}
                                  className=""
                                >
                                  <section id="subscription-document">
                                    <div
                                      style={{
                                        fontSize: isMobile ? "20px" : "30px",
                                        fontWeight: "700",
                                        lineHeight: "1em",
                                      }}
                                    >
                                      Subscription Document
                                    </div>
                                    <div
                                      style={{
                                        content: "",
                                        display: "block",
                                        width: "75px",
                                        height: "5px",
                                        backgroundColor: "#3C81F6",
                                        marginTop: "20px",
                                        borderRadius: "4px",
                                        marginBottom: "20px",
                                      }}
                                    />
                                    {!portfolio && (
                                      <div
                                        className="max-w-6xl mx-auto md:grid md:grid-cols-1 md:gap-4"
                                        style={{ width: "100%" }}
                                      >
                                        <SelectMenu
                                          label={
                                            "Which trust are you interested in investing in?"
                                          }
                                          placeholder={"Select an option"}
                                          currentSelection={form?.["3a"]}
                                          disabled={
                                            !(
                                              form?.["executedSubDocURL"] ===
                                                undefined &&
                                              executedSubDocURL === ""
                                            )
                                          }
                                          setSelection={(e) => {
                                            handleForm("3a", e);
                                          }}
                                          options={funds}
                                        />
                                      </div>
                                    )}

                                    {form?.["3a"] && (
                                      <div>
                                        <div className="form-question">
                                          How much would you like to invest?
                                        </div>
                                        <div className="form-space" />
                                        <div style={{ marginBottom: "15px" }}>
                                          <div className="mt-1 relative rounded-md shadow-sm">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                              <span className="text-gray-500 sm:text-sm">
                                                $
                                              </span>
                                            </div>
                                            <input
                                              value={form?.["investmentAmount"]}
                                              onChange={(e) =>
                                                handleForm(
                                                  "investmentAmount",
                                                  e
                                                )
                                              }
                                              type="text"
                                              disabled={
                                                !(
                                                  form?.[
                                                    "executedSubDocURL"
                                                  ] === undefined &&
                                                  executedSubDocURL === ""
                                                )
                                              }
                                              name="price"
                                              id="price"
                                              className={`${
                                                !(
                                                  form?.[
                                                    "executedSubDocURL"
                                                  ] === undefined &&
                                                  executedSubDocURL === ""
                                                )
                                                  ? "bg-gray-200"
                                                  : "bg-white hover:border-blue-500"
                                              } border-2 ${insufficientAmount(
                                                form?.["investmentAmount"]
                                              )} form-input shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                                              placeholder="0.00"
                                              aria-describedby="price-currency"
                                              style={{
                                                paddingLeft: "1.75rem",
                                                paddingRight: "3rem",
                                              }}
                                            />
                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                              <span
                                                className="text-gray-500 sm:text-sm"
                                                id="price-currency"
                                              >
                                                USD
                                              </span>
                                            </div>
                                          </div>
                                          {insufficientAmountText(
                                            form?.["investmentAmount"]
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {form?.["3a"] && (
                                      <div className="mt-5 mb-10">
                                        <div className="mb-5">
                                          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                                            Trust Info
                                          </h2>
                                          <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                                            {getFundInfoText()}
                                          </ul>
                                        </div>

                                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                          <ul className="divide-y divide-gray-200">
                                            {getFundLinks()}
                                          </ul>
                                        </div>

                                        {portfolio && (
                                          <div
                                            onClick={() =>
                                              setOpenFundLinks(!openFundLinks)
                                            }
                                            className="hover:underline text-center text-xs mt-2 text-blue-500"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {openFundLinks ? "hide" : "see all"}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </section>
                                </div>

                                {sufficientAmount(
                                  form?.["investmentAmount"]
                                ) && (
                                  <div
                                    className="flex mb-6"
                                    id="applicationBottom"
                                  >
                                    <label className="flex items-center">
                                      <input
                                        onChange={(e) => {
                                          handleForm("3b", e.target.checked);
                                        }}
                                        disabled={showApplication}
                                        defaultChecked={form?.["3b"]}
                                        type="checkbox"
                                        className="form-checkbox mr-3"
                                      />
                                      <span className="ml-2 text-xs text-gray-700">
                                        I certify that I have reviewed all of
                                        the documentation related to my
                                        potential investment and am aware of the
                                        risks associated with an investment in
                                        the trust.
                                      </span>
                                    </label>
                                  </div>
                                )}

                                {sufficientAmount(form?.["investmentAmount"]) &&
                                  full &&
                                  getHelloSignJSX()}
                              </React.Fragment>
                            )}

                          {portfolio &&
                            full &&
                            form?.["3b"] &&
                            (form?.["executedSubDocURL"] ||
                              (form?.["2c"] && form?.["2d"])) && (
                              <div
                                onClick={async () => {
                                  if (canSubmit) {
                                    if (
                                      window.confirm(
                                        `Please confirm all the information is correct. After you submit, you cannot modify the application. Continue?`
                                      )
                                    ) {
                                      setSubmit(true);
                                      handleForm("status", 1);
                                      await delay(1500);
                                      scrollTop();
                                      setSubmit(false);
                                    }
                                  } else {
                                    alert("Form is not complete");
                                  }
                                }}
                                style={{ cursor: "pointer", width: "140px" }}
                                className={`inline-flex items-center justify-center px-8 py-2 mt-10 border border-transparent rounded-md shadow-sm font-normal text-sm text-white hover:bg-blue-600 ${
                                  submitting ? "bg-gray-400" : "bg-blue-500"
                                }`}
                              >
                                {submitting ? loadingJSX() : "Submit All"}
                              </div>
                            )}
                        </React.Fragment>
                      </div>
                    </section>
                  </div>
                )}
            </div>
          </section>
        </BaseProvider>
      </StyletronProvider>
    );
  };

  return (
    <form>
      {notificationArray}
      <div style={{ paddingBottom: "50px" }}>
        {portfolio && (showPreviousPage || fiveYearAccreditation !== 0) ? (
          <section>
            <div
              style={{
                fontSize: isMobile ? "20px" : "30px",
                fontWeight: "700",
                lineHeight: "1em",
              }}
            >
              Application
            </div>
            <div
              style={{
                content: "",
                display: "block",
                width: "75px",
                height: "5px",
                backgroundColor: "#3C81F6",
                marginTop: "20px",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
            />

            <BinarySelect
              label={
                "Within the last year, I previously invested with Immutable.co and submitted documents attesting to my Accredited Investor Status as part of my Subscription Agreement. I represent that my Accredited Investor Status has not changed."
              }
              required={true}
              yesOverride={"Yes"}
              noOverride={"No, it has changed"}
              selected={fiveYearAccreditation}
              // form?.["accreditation-five-years"]
              setSelect={(e) => {
                setFiveYearAccreditation(e);
                setShowPreviousPage(false);
                // handleForm("accreditation-five-years", e);
              }}
            />

            {fiveYearAccreditation === 1 && (
              <div className="text-xs" style={{ marginTop: "50px" }}>
                <div className="mt-10">
                  <section id="subscription-document">
                    <div
                      style={{
                        fontSize: isMobile ? "20px" : "30px",
                        fontWeight: "700",
                        lineHeight: "1em",
                      }}
                    >
                      Subscription Agreement
                    </div>
                    <div
                      style={{
                        content: "",
                        display: "block",
                        width: "75px",
                        height: "5px",
                        backgroundColor: "#3C81F6",
                        marginTop: "20px",
                        borderRadius: "4px",
                        marginBottom: "20px",
                      }}
                    />
                  </section>

                  {form?.["3a"] && (
                    <div className="mt-5 mb-10">
                      <div className="mb-5">
                        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
                          Trust Info
                        </h2>
                        <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                          {getFundInfoText()}
                        </ul>
                      </div>

                      <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {getFundLinks()}
                        </ul>
                      </div>

                      <div
                        onClick={() => setOpenFundLinks(!openFundLinks)}
                        className="hover:underline text-center text-xs mt-2 text-blue-500"
                        style={{ cursor: "pointer" }}
                      >
                        {openFundLinks ? "hide" : "see all"}
                      </div>
                    </div>
                  )}

                  <div className="mb-5">
                    <div className="form-question">
                      How much would you like to invest?
                    </div>
                    <div className="form-space" />
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          value={form?.["investmentAmount"]}
                          onChange={(e) => handleForm("investmentAmount", e)}
                          type="text"
                          name="price"
                          id="price"
                          className={`form-input focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${insufficientAmount(
                            form?.["investmentAmount"]
                          )}`}
                          placeholder="0.00"
                          aria-describedby="price-currency"
                          style={{
                            paddingLeft: "1.75rem",
                            paddingRight: "3rem",
                          }}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span
                            className="text-gray-500 sm:text-sm"
                            id="price-currency"
                          >
                            USD
                          </span>
                        </div>
                      </div>
                      {insufficientAmountText(form?.["investmentAmount"])}
                    </div>
                  </div>

                  <div className="flex mb-6">
                    <label className="flex items-center">
                      <input
                        onChange={(e) => {
                          setInnerCheck(e.target.checked);
                        }}
                        defaultChecked={innerCheck}
                        type="checkbox"
                        className="form-checkbox mr-3"
                      />
                      <span className="ml-2 text-xs text-gray-700">
                        I certify that I have reviewed all of the documentation
                        related to my potential investment and am aware of the
                        risks associated with an investment in the trust.
                      </span>
                    </label>
                  </div>

                  {Number(form?.["investmentAmount"]) > 0 &&
                    innerCheck &&
                    getHelloSignJSX()}
                </div>

                <div
                  onClick={() => setShowApplication(!showApplication)}
                  className="hover:underline text-blue-500 py-5"
                  style={{ cursor: "pointer" }}
                >
                  {showApplication ? "hide application" : "show application"}
                </div>

                {showApplication && mainApp(false)}
              </div>
            )}
          </section>
        ) : (
          <div>
            {portfolio && fiveYearAccreditation === 0 && (
              <div
                onClick={() => setShowPreviousPage(true)}
                className="text-sm text-blue-500 hover:underline transition duration-200 mb-5"
                style={{ cursor: "pointer" }}
              >
                ←&nbsp;go back
              </div>
            )}
            {mainApp(true)}
          </div>
        )}
      </div>
    </form>
  );
}
