import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import delay from "delay";
import moment from "moment";
import Dropzone from "react-dropzone";
import * as Sentry from "@sentry/browser";
import { Document, Page, pdfjs } from "react-pdf";
import PasswordStrengthBar from "react-password-strength-bar";
import Avatar from "./avatar";
import { isMobile } from "react-device-detect";
import config from "../config";
import Notification from "./notification";
import { FilePond, File, registerPlugin } from "react-filepond";
import * as FilePond2 from "filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

let debug = config.debug;
let debugURL = config.debugURL;
let productionURL = config.productionURL;

// Register the plugin
FilePond2.registerPlugin(FilePondPluginFileValidateType);

export default function MainSettings(props) {
  const { user, tab } = props;
  const history = useHistory();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const jwt = localStorage.getItem("immutable-jwt");
  let base = debug ? debugURL : productionURL;

  const [score, setScore] = useState("");
  const [updatePic, setUpdatingPic] = useState(false);
  const [uploading, setUpload] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [updatingInstitution, updateInstitution] = useState(false);
  const [savingInstitution, saveInstitution] = useState(false);
  const [institutionName, setInstitutionName] = useState("");

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileURL, setFileURL] = useState();

  const [notificationArray, setNotificationArray] = useState([]);

  const [updatingEmail, updateEmail] = useState(false);
  const [savingEmail, saveEmail] = useState(false);
  const [emailName, setEmailName] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [openFund, setOpenFund] = useState(false);
  const [fund, setFund] = useState("btc-1");
  const [openSettings, setOpenSettings] = useState(false);

  const [investorPic, setInvestorPic] = useState();
  const [investorPicName, setInvestorPicName] = useState();

  const [pdfURL, setPdfURL] = useState("");
  const [viewAttachment, setViewAttachment] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pagePdfNumber, setPagePdfNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPagePdfNumber(1);
  }

  const getJWT = () => {
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  };

  // for filepond
  const getJWT2 = () => {
    return {
      Authorization: `Bearer ${jwt}`,
    };
  };

  const viewImageRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        viewImageRef.current &&
        !viewImageRef.current.contains(event.target)
      ) {
        setViewAttachment(false);
        setPagePdfNumber(1);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [viewImageRef]);

  const getFund = (fund) => {
    switch (fund) {
      case "btc-1":
        return "Bitcoin 1 Fund";
      case "hbar-1":
        return "HBAR 1 Fund";
      default:
        return "n/a";
    }
  };

  const onSuccess = useCallback(async (token, metadata) => {
    try {
      console.log("onSuccess", token, metadata);

      const header = getJWT();
      const data = {
        plaidSuccessObject: metadata,
      };

      // link plaid and run data analysis
      await axios.post(`${base}/user/link-plaid`, data, header);
    } catch (err) {
      console.log("error on linking plaid success: ", err);
    }
  }, []);

  const onEvent = useCallback(
    (eventName, metadata) => console.log("onEvent", eventName, metadata),
    []
  );

  const openSettingsRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        openSettingsRef.current &&
        !openSettingsRef.current.contains(event.target)
      ) {
        setOpenSettings(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSettingsRef]);

  const fundRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (fundRef.current && !fundRef.current.contains(event.target)) {
        setOpenFund(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fundRef]);

  useEffect(() => {
    if (user.institutionName && !institutionName) {
      setInstitutionName(user.institutionName);
    }
  }, [user]);

  useEffect(() => {
    if (user.email && !emailName) {
      setEmailName(user.email);
    }
  }, [user]);

  const addNotification = (subject, text, seconds) => {
    notificationArray.push(
      <Notification subject={subject} text={text} seconds={seconds} />
    );
  };

  const handleRemovePicture = async () => {
    try {
      const header = getJWT();
      await axios.post(`${base}/user/removePicture`, {}, header);
      addNotification("Successfully removed!", undefined, 3);
      window.location.reload();
    } catch (e) {
      console.log("error: ", e);
      const error = e?.response?.data ? e?.response?.data : e;
      Sentry.captureException(new Error(JSON.stringify(error)));
      window.location.reload();
    }
  };

  const handleChangePassword = async () => {
    try {
      if (canSubmit) {
        setChangingPassword(true);

        const header = getJWT();

        const body = {
          currentPassword,
          newPassword: newPassword1,
        };

        await axios.post(`${base}/user/change-password`, body, header);

        await delay(1000);

        setChangingPassword(false);

        addNotification("Successfully changed!", undefined, 3);
        setNewPassword1("");
        setNewPassword2("");
        setCurrentPassword("");
      }
    } catch (e) {
      setChangingPassword(false);

      alert(e.response.data?.message || e.response.data);
      console.log("error: ", e.response);
    }
  };

  const handleUploadNewPic = async () => {
    try {
      setUpload(true);
      const header = getJWT();

      const formData = new FormData();
      formData.append("inlineImage", investorPic.raw);

      const data = await axios.post(`${base}/user/upload`, formData, header);
      setUpload(false);
      setUpdatingPic(false);
      setInvestorPic();
      setInvestorPicName();

      window.location.reload();
      return data;
    } catch (e) {
      setUpload(false);
      setUpdatingPic(false);
      setInvestorPic();
      setInvestorPicName();
      console.log("error: ", e);
      const error = e?.response?.data ? e?.response?.data : e;
      Sentry.captureException(new Error(JSON.stringify(error)));
      window.location.reload();
      throw error;
    }
  };

  const onDropPicture = (picture) => {
    if (picture.length > 1) {
      alert("only 1 picture is allowed at a time");
    } else {
      setInvestorPic({
        preview: URL.createObjectURL(picture[0]),
        raw: picture[0],
      });
      setInvestorPicName(picture[0].name);
    }
  };

  const updateProfilePicture = () => {
    return updatePic ? (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            style={{ width: isMobile ? "100%" : "700px" }}
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div
                className="flex items-center justify-center"
                style={{ marginBottom: "25px" }}
              >
                {investorPic?.preview ? (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      className=""
                      src={investorPic.preview}
                      alt={investorPicName}
                      style={{
                        display: "inline",
                        margin: "0 auto",
                        height: "auto",
                        width: "100%",
                      }}
                    />
                  </div>
                ) : user.profilePictureUrl ? (
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                    src={user.profilePictureUrl}
                    alt={user.profilePictureUrl}
                  />
                ) : (
                  <Avatar user={user} />
                )}
              </div>
              <div className="text-center">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {user?.profilePictureUrl ? "Change" : "Upload"} Profile
                  Picture
                </h3>
                <div className="mt-2">
                  <Dropzone
                    accept="image/*"
                    onDrop={(files) => onDropPicture(files)}
                    className="dropzone-box"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} style={{ outline: "none" }}>
                          <input {...getInputProps()} />
                          {investorPicName ? (
                            <div className="text-center text-black">
                              {investorPicName} |{" "}
                              <span
                                className="underline"
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                Change
                              </span>
                            </div>
                          ) : (
                            <div
                              className="rounded-sm"
                              style={{
                                border: "1px dashed blue",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="flex items-center justify-center w-full"
                                style={{ height: "80px" }}
                              >
                                Upload
                              </span>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                onClick={() => {
                  handleUploadNewPic();
                }}
                type="button"
                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:col-start-2 sm:text-sm  ${
                  uploading ? "bg-gray-300" : "bg-blue-600"
                }`}
              >
                {uploading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
              <button
                onClick={() => {
                  setInvestorPic();
                  setInvestorPicName();
                  setUpdatingPic(false);
                }}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  };

  const canSubmit =
    Number(score) == 4 &&
    currentPassword !== "" &&
    newPassword1 != "" &&
    newPassword2 != "" &&
    newPassword1 === newPassword2;

  const generalClass =
    tab === "home"
      ? "border-purple-500 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm";
  const atsClass =
    tab === "ats"
      ? "border-purple-500 ml-8 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm";
  const taxClass =
    tab === "tax"
      ? "border-purple-500 ml-8 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm";
  const passwordClass =
    tab === "password"
      ? "border-purple-500 ml-8 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm";
  const uploadFormClass =
    tab === "upload-form"
      ? "border-purple-500 ml-8 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm";
  const statementsClass =
    tab === "statements"
      ? "border-purple-500 ml-8 text-blue-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap ml-8 py-4 px-1 border-b-2 font-medium text-sm";

  const handleSave = async () => {
    try {
      saveInstitution(true);

      await axios.post(
        `${base}/user/institution`,
        {
          institutionName,
        },
        getJWT()
      );

      await delay(1000);

      saveInstitution(false);
      updateInstitution(false);
      window.location.reload();
    } catch (err) {
      console.log("error while saving: ", err);
      saveInstitution(false);
      updateInstitution(false);
      window.location.reload();
    }
  };

  const onDropFile = (files) => {
    setFile(files.map((fileItem) => fileItem.file));
  };

  const handleSaveEmail = async () => {
    try {
      if (
        window.confirm(
          `We will now send a confirmation link to the current email. Continue?`
        )
      ) {
        saveEmail(true);

        await axios.post(
          `${base}/user/changeEmail`,
          {
            newEmail: emailName,
          },
          getJWT()
        );

        await delay(1000);

        saveEmail(false);
        updateEmail(false);

        addNotification("Successfully sent email link!", undefined, 3);
      }
    } catch (err) {
      alert(`Error: ${err.response.data.error}`);
      saveEmail(false);
      updateEmail(false);
    }
  };

  const loadingJSX = (color = "white") => {
    return (
      <svg
        className={`animate-spin h-5 w-5 text-${color}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  };

  const viewAttachmentJSX = () => {
    return (
      <div className="fixed z-20 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            ref={viewImageRef}
            className="inline-block align-center bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 px-4 py-2 text-center flex items-center justify-center sm:mt-5">
                <div className="px-4 py-2">
                  <Document
                    loading={
                      <div className="flex item-center">
                        Loading...&nbsp;{loadingJSX("blue")}
                      </div>
                    }
                    file={pdfURL}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page width={440} pageNumber={pagePdfNumber} />
                  </Document>
                  <div className="flex items-center justify-center py-2 mt-5 text-gray-600">
                    <div
                      style={{ cursor: "pointer", fontSize: 16 }}
                      className="mr-4 hover:text-blue-500"
                      onClick={() => {
                        if (pagePdfNumber > 1) {
                          setPagePdfNumber(pagePdfNumber - 1);
                        }
                      }}
                    >
                      &nbsp;←&nbsp;
                    </div>
                    <div>
                      Page {pagePdfNumber} of {numPages}
                    </div>
                    <div
                      style={{ cursor: "pointer", fontSize: 16 }}
                      className="ml-4 hover:text-blue-500"
                      onClick={() => {
                        if (pagePdfNumber < numPages) {
                          setPagePdfNumber(pagePdfNumber + 1);
                        }
                      }}
                    >
                      &nbsp;→&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 flex items-center justify-between">
              <button
                onClick={() => {
                  setViewAttachment(false);
                }}
                type="button"
                className="mt-3 mr-5 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Close
              </button>
              <button
                onClick={() => {
                  window.open(pdfURL, "_blank");
                }}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getSettingJSX = (tabName) => {
    switch (tabName) {
      case "home":
        return (
          <React.Fragment>
            {updateProfilePicture()}
            <div className="py-5 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3
                  className={`${
                    isMobile ? "text-sm text-center" : "text-lg text-left"
                  } leading-6 font-medium text-gray-900`}
                >
                  Profile
                </h3>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">
                        {user.firstName} {user.lastName}
                      </span>
                    </dd>
                  </div>
                  {user.institutional && (
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Institution
                      </dt>
                      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {updatingInstitution ? (
                          <span className="flex-grow">
                            <input
                              onChange={(e) =>
                                setInstitutionName(e.target.value)
                              }
                              disabled={savingInstitution}
                              value={institutionName}
                              style={{
                                width: isMobile ? "100%" : "500px",
                                margin: isMobile && "0 auto",
                                borderWidth: "1px",
                                padding: "10px",
                              }}
                              type="text"
                              name="Institution Name"
                              id="Institution Name"
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Institution Name"
                            />
                          </span>
                        ) : (
                          <span className="flex-grow">
                            {user.institutionName}
                          </span>
                        )}
                        <span
                          className={`ml-4 flex-shrink-0 flex items-start ${
                            updatingInstitution ? "space-x-4" : ""
                          }`}
                        >
                          {updatingInstitution ? (
                            <React.Fragment>
                              <button
                                onClick={() => {
                                  updateInstitution(false);
                                }}
                                type="button"
                                className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                              >
                                Cancel
                              </button>

                              <span
                                className="text-gray-300"
                                aria-hidden="true"
                              >
                                |
                              </span>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          <button
                            onClick={() => {
                              if (updatingInstitution) {
                                handleSave();
                              } else {
                                updateInstitution(true);
                              }
                            }}
                            type="button"
                            className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                          >
                            {updatingInstitution
                              ? savingInstitution
                                ? "Saving..."
                                : "Save"
                              : !user.institutionName
                              ? "Set"
                              : "Update"}
                          </button>
                        </span>
                      </dd>
                    </div>
                  )}
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Photo</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">
                        {user.profilePictureUrl ? (
                          <img
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                            src={user.profilePictureUrl}
                            alt={user.profilePictureUrl}
                          />
                        ) : (
                          <Avatar
                            width={"35px"}
                            height={"35px"}
                            fontSize={"25px"}
                            user={user}
                          />
                        )}
                      </span>
                      <span
                        className={`ml-4 flex-shrink-0 flex items-start ${
                          user?.profilePictureUrl ? "space-x-4" : ""
                        }`}
                      >
                        <button
                          onClick={() => setUpdatingPic(true)}
                          type="button"
                          className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                        >
                          {user?.profilePictureUrl ? "Update" : "Upload"}
                        </button>
                        {user.profilePictureUrl ? (
                          <React.Fragment>
                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                            <button
                              onClick={() => handleRemovePicture()}
                              type="button"
                              className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                            >
                              Remove
                            </button>
                          </React.Fragment>
                        ) : (
                          <span />
                        )}
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {updatingEmail ? (
                        <span className="flex-grow">
                          <input
                            onChange={(e) => setEmailName(e.target.value)}
                            disabled={savingEmail}
                            value={emailName}
                            style={{
                              width: isMobile ? "100%" : "500px",
                              margin: isMobile && "0 auto",
                              borderWidth: "1px",
                              padding: "10px",
                            }}
                            type="text"
                            name="Email Name"
                            id="Email Name"
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Email Name"
                          />
                        </span>
                      ) : (
                        <span className="flex-grow">{user.email}</span>
                      )}
                      <span
                        className={`ml-4 flex-shrink-0 flex items-start ${
                          updatingEmail ? "space-x-4" : ""
                        }`}
                      >
                        {updatingEmail ? (
                          <React.Fragment>
                            <button
                              onClick={() => {
                                updateEmail(false);
                              }}
                              type="button"
                              className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                            >
                              Cancel
                            </button>

                            <span className="text-gray-300" aria-hidden="true">
                              |
                            </span>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                        <button
                          onClick={() => {
                            if (updatingEmail) {
                              handleSaveEmail();
                            } else {
                              updateEmail(true);
                            }
                          }}
                          type="button"
                          className="rounded-md font-medium text-blue-500 hover:text-purple-500 focus:outline-none"
                        >
                          {updatingEmail
                            ? savingEmail
                              ? "Updating..."
                              : "Update"
                            : !user.email
                            ? "Set"
                            : "Update"}
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </React.Fragment>
        );
      case "statements":
        return (
          <React.Fragment>
            {!isMobile && (
              <div className={`mt-3 divide-y divide-gray-200`}>
                <div className="space-y-1 flex items-center justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Statements
                  </h3>

                  <div>
                    <div ref={fundRef} className="relative">
                      <button
                        onClick={() => {
                          setOpenFund(!openFund);
                        }}
                        type="button"
                        className={`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        aria-haspopup="listbox"
                        aria-expanded="true"
                        aria-labelledby="listbox-label"
                      >
                        <span className="block truncate">{getFund(fund)}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <svg
                            className="h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>

                      {openFund && (
                        <ul
                          className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          tabindex="-1"
                          role="listbox"
                          aria-labelledby="listbox-label"
                          aria-activedescendant="listbox-option-3"
                        >
                          <li
                            onClick={() => {
                              setOpenFund(false);
                              setFund("btc-1");
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                            id="listbox-option-0"
                            role="option"
                          >
                            <span className="font-normal block truncate">
                              BTC Fund
                            </span>
                          </li>

                          <li
                            onClick={() => {
                              setOpenFund(false);
                              setFund("hbar-1");
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                            id="listbox-option-0"
                            role="option"
                          >
                            <span className="font-normal block truncate">
                              HBAR Fund
                            </span>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {viewAttachment && viewAttachmentJSX()}

            <div className={`flex flex-col ${isMobile ? "" : "py-5"}`}>
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Size
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Download or Save</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            March 1, 2021
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Statement
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            2 pages
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <div className="text-indigo-600 flex items-center justify-end">
                              <div
                                onClick={() => {
                                  setPdfURL(
                                    "http://www.pdf995.com/samples/pdf.pdf"
                                  );
                                  setViewAttachment(true);
                                }}
                                className="hover:text-indigo-900"
                                style={{ cursor: "pointer" }}
                              >
                                View
                              </div>
                              &nbsp;/&nbsp;
                              <div
                                onClick={() => {
                                  window.open(
                                    "http://www.pdf995.com/samples/pdf.pdf",
                                    "_blank"
                                  );
                                }}
                                className="hover:text-indigo-900"
                                style={{ cursor: "pointer" }}
                              >
                                Download
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr className="bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            Feb, 1, 2021
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Statement
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            3 pages
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <div className="text-indigo-600 flex items-center justify-end">
                              <div
                                onClick={() => {
                                  setPdfURL(
                                    "http://www.pdf995.com/samples/pdf.pdf"
                                  );
                                  setViewAttachment(true);
                                }}
                                className="hover:text-indigo-900"
                                style={{ cursor: "pointer" }}
                              >
                                View
                              </div>
                              &nbsp;/&nbsp;
                              <div
                                onClick={() => {
                                  window.open(
                                    "http://www.pdf995.com/samples/pdf.pdf",
                                    "_blank"
                                  );
                                }}
                                className="hover:text-indigo-900"
                                style={{ cursor: "pointer" }}
                              >
                                Download
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "ats":
        return (
          <React.Fragment>
            <div className="mt-5 pb-5 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3
                  className={`${
                    isMobile ? "text-sm py-5 text-center" : "text-lg text-left"
                  } leading-6 font-medium text-gray-900`}
                >
                  ATS Details
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  ATS Account: -
                </p>
              </div>
            </div>
          </React.Fragment>
        );
      case "tax":
        return (
          <React.Fragment>
            <div className="mt-5 pb-5 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3
                  className={`${
                    isMobile ? "text-sm py-5 text-center" : "text-lg text-left"
                  } leading-6 font-medium text-gray-900`}
                >
                  Important Tax Information &amp; K-1
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  K-1 Details...
                </p>
              </div>
            </div>
          </React.Fragment>
        );
      case "upload-form":
        return (
          <React.Fragment>
            <div className="mt-5 pb-5 flex items-center justify-center">
              <div style={{ width: isMobile && "100%" }}>
                <div className="space-y-1 mb-8">
                  <h3
                    className={`${
                      isMobile ? "text-sm mt-5" : "text-lg"
                    } leading-6 font-medium text-center text-gray-900`}
                  >
                    Upload Form
                  </h3>
                </div>
                <div className="rounded-md bg-blue-50 p-4 shadow-sm">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">
                        Upload CSV from Theorem
                      </p>
                      {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">ok</p> */}
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <dl className="" style={{ width: "500px" }}>
                    <FilePond
                      server={{
                        url: base,
                        process: {
                          url: "/user/upload-csv-theorem",
                          method: "POST",
                          headers: getJWT2(),
                          withCredentials: false,
                          onload: (response) => {
                            const fileURL = JSON.parse(response).url;
                            setFileURL(fileURL);
                          },
                          onerror: (response) => {
                            console.log("error: ", response.data);
                          },
                          ondata: (formData) => {
                            return formData;
                          },
                        },
                      }}
                      allowFileTypeValidation={true}
                      labelFileTypeNotAllowed="Invalid File"
                      acceptedFileTypes={["text/csv"]}
                      files={file}
                      allowMultiple={false}
                      allowReorder={false}
                      maxFiles={1}
                      name="files"
                      dropOnPage
                      onupdatefiles={(files) => {
                        if (files.length === 0) {
                          setFileURL("");
                          setFileName("");
                        } else {
                          setFileName(files[0].filename);
                        }
                        onDropFile(files);
                      }}
                    />
                  </dl>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "password":
        return (
          <React.Fragment>
            <div className="mt-5 pb-5 flex items-center justify-center">
              <div style={{ width: isMobile && "100%" }}>
                <div className="space-y-1">
                  <h3
                    className={`${
                      isMobile ? "text-sm mt-5" : "text-lg"
                    } leading-6 font-medium text-center text-gray-900`}
                  >
                    Change Password
                  </h3>
                </div>
                <div className="mt-6">
                  <dl className="">
                    <input
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      value={currentPassword}
                      style={{
                        width: isMobile ? "100%" : "500px",
                        margin: isMobile && "0 auto",
                        borderWidth: "1px",
                        padding: "10px",
                        fontSize: isMobile && "12px",
                        marginBottom: "10px",
                      }}
                      type="password"
                      name="current password"
                      id="current password"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Current Password"
                    />
                    <input
                      onChange={(e) => setNewPassword1(e.target.value)}
                      value={newPassword1}
                      style={{
                        width: isMobile ? "100%" : "500px",
                        margin: isMobile && "0 auto",
                        borderWidth: "1px",
                        padding: "10px",
                        fontSize: isMobile && "12px",
                        marginBottom: "10px",
                      }}
                      type="password"
                      name="new password"
                      id="new password"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="New Password"
                    />
                    <div
                      style={{
                        width: isMobile ? "100%" : "500px",
                        margin: isMobile && "0 auto",
                        marginBottom: "10px",
                      }}
                    >
                      <PasswordStrengthBar
                        onChangeScore={(score) => setScore(score)}
                        password={newPassword1}
                      />
                    </div>
                    <input
                      onChange={(e) => setNewPassword2(e.target.value)}
                      value={newPassword2}
                      style={{
                        width: isMobile ? "100%" : "500px",
                        margin: isMobile && "0 auto",
                        borderWidth: "1px",
                        padding: "10px",
                        fontSize: isMobile && "12px",
                      }}
                      type="password"
                      name="confirm password"
                      id="confirm password"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Confirm Password"
                    />
                  </dl>
                  {newPassword1 !== "" &&
                    newPassword2 !== "" &&
                    newPassword1 !== newPassword2 && (
                      <div
                        style={{
                          width: isMobile ? "100%" : "500px",
                          margin: isMobile && "0 auto",
                        }}
                        className="flex items-center justify-between"
                      >
                        <span />
                        <span
                          style={{
                            color: "red",
                            margin: "5px 0px 0px",
                            fontSize: "14px",
                            textAlign: "right",
                          }}
                        >
                          passwords must match
                        </span>
                      </div>
                    )}

                  <div
                    className="flex items-center justify-between"
                    style={{ width: isMobile ? "100%" : "500px" }}
                  >
                    <span />
                    <button
                      onClick={() => {
                        handleChangePassword();
                      }}
                      disabled={!canSubmit || changingPassword}
                      type="button"
                      style={{
                        cursor: canSubmit ? "pointer" : "inherit",
                        fontSize: "12px",
                      }}
                      className={`ml-8 mt-4 whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r ${
                        !canSubmit
                          ? "bg-gray-300"
                          : "from-blue-600 to-blue-600 hover:from-blue-700 hover:to-blue-700"
                      }`}
                    >
                      {changingPassword ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        "Change"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return <React.Fragment>Not Found 404</React.Fragment>;
    }
  };

  const mobileSettingSelect = (
    <div
      ref={openSettingsRef}
      style={{ width: "150px", marginTop: "0px" }}
      className="relative"
    >
      <button
        onClick={() => {
          setOpenSettings(!openSettings);
        }}
        type="button"
        className={`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <span className="block truncate text-xs capitalize">
          {tab === "ats" ? "ATS Details" : tab === "tax" ? "Tax Details" : tab}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>

      {openSettings && (
        <ul
          className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          <li
            onClick={() => {
              setOpenSettings(false);
              history.push("settings");
            }}
            style={{ cursor: "pointer" }}
            className="text-gray-900 text-xs cursor-default select-none relative py-2 pl-3 pr-9"
            id="listbox-option-0"
            role="option"
          >
            <span className="font-normal block truncate">Settings</span>
          </li>

          <li
            onClick={() => {
              setOpenSettings(false);
              history.push("statements");
            }}
            style={{ cursor: "pointer" }}
            className="text-gray-900 text-xs cursor-default select-none relative py-2 pl-3 pr-9"
            id="listbox-option-0"
            role="option"
          >
            <span className="font-normal block truncate">Statements</span>
          </li>

          <li
            onClick={() => {
              setOpenSettings(false);
              history.push("password");
            }}
            style={{ cursor: "pointer" }}
            className="text-gray-900 text-xs cursor-default select-none relative py-2 pl-3 pr-9"
            id="listbox-option-0"
            role="option"
          >
            <span className="font-normal block truncate">Password</span>
          </li>

          <li
            onClick={() => {
              setOpenSettings(false);
              history.push("ats");
            }}
            style={{ cursor: "pointer" }}
            className="text-gray-900 text-xs cursor-default select-none relative py-2 pl-3 pr-9"
            id="listbox-option-0"
            role="option"
          >
            <span className="font-normal block truncate">ATS Details</span>
          </li>

          <li
            onClick={() => {
              setOpenSettings(false);
              history.push("tax");
            }}
            style={{ cursor: "pointer" }}
            className="text-gray-900 text-xs cursor-default select-none relative py-2 pl-3 pr-9"
            id="listbox-option-0"
            role="option"
          >
            <span className="font-normal block truncate">
              Tax Info &amp; K-1
            </span>
          </li>
        </ul>
      )}
    </div>
  );

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      {notificationArray}
      <div className="pt-10 pb-16">
        <div className="px-0 sm:px-6 md:px-0">
          <div className="lg:hidden">
            {!isMobile ? (
              <h1 className="text-3xl font-extrabold text-gray-900">
                Settings
              </h1>
            ) : (
              tab !== "statements" && (
                <div className="space-y-1 flex items-center justify-between">
                  <span />
                  {mobileSettingSelect}
                </div>
              )
            )}
          </div>
        </div>

        {isMobile && tab === "statements" && (
          <div className={`mb-5`}>
            <div className="space-y-1 flex items-center justify-between">
              <div style={{ marginTop: "0px" }}>
                <div ref={fundRef} className="relative">
                  <button
                    onClick={() => {
                      setOpenFund(!openFund);
                    }}
                    type="button"
                    className={`bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                  >
                    <span className="block truncate text-xs">
                      {getFund(fund)}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </button>

                  {openFund && (
                    <ul
                      className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                      tabindex="-1"
                      role="listbox"
                      aria-labelledby="listbox-label"
                      aria-activedescendant="listbox-option-3"
                    >
                      <li
                        onClick={() => {
                          setOpenFund(false);
                          setFund("btc-1");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                        id="listbox-option-0"
                        role="option"
                      >
                        <span className="font-normal block truncate">
                          BTC Fund
                        </span>
                      </li>

                      <li
                        onClick={() => {
                          setOpenFund(false);
                          setFund("hbar-1");
                        }}
                        style={{ cursor: "pointer" }}
                        className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
                        id="listbox-option-0"
                        role="option"
                      >
                        <span className="font-normal block truncate">
                          HBAR Fund
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              {mobileSettingSelect}
            </div>
          </div>
        )}

        <div
          className={`bg-white rounded-sm shadow ${
            isMobile && tab === "statements" ? "" : "px-4"
          } sm:px-6 md:px-0 mt-5`}
        >
          <div className={isMobile ? "" : "px-5"}>
            <div className="hidden lg:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex">
                  <div
                    onClick={() => history.push("settings")}
                    className={generalClass}
                    style={{ cursor: "pointer" }}
                  >
                    General
                  </div>
                  {user.accountType !== "admin" && (
                    <div
                      onClick={() => history.push("statements")}
                      className={statementsClass}
                      style={{ cursor: "pointer" }}
                    >
                      Statements
                    </div>
                  )}
                  <div
                    onClick={() => history.push("password")}
                    className={passwordClass}
                    style={{ cursor: "pointer" }}
                  >
                    Password
                  </div>
                  {/* only admin, not theorem */}
                  {user.accountType === "admin" && (
                    <div
                      onClick={() => history.push("upload-form")}
                      className={uploadFormClass}
                      style={{ cursor: "pointer" }}
                    >
                      Upload Form
                    </div>
                  )}
                  {user.accountType !== "admin" && (
                    <React.Fragment>
                      <div
                        onClick={() => history.push("ats")}
                        className={atsClass}
                        style={{ cursor: "pointer" }}
                      >
                        ATS Details
                      </div>
                      <div
                        onClick={() => history.push("tax")}
                        className={taxClass}
                        style={{ cursor: "pointer" }}
                      >
                        Tax Info &amp; K-1
                      </div>
                    </React.Fragment>
                  )}
                </nav>
              </div>
            </div>

            {getSettingJSX(tab)}
          </div>
        </div>
      </div>
    </div>
  );
}
