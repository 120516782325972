import React, { useState, useRef, useEffect } from "react";

export default function BinarySelect(props) {
  const {
    label,
    required,
    selected,
    setSelect,
    yesOverride,
    noOverride,
    list,
  } = props;

  const listView = list || [];

  const yesBool = selected === 1;
  const noBool = selected === 0;

  const createListView = () => {
    return listView.map((l, i) => {
      return (
        <li style={{ color: "black" }} key={i}>
          {l}
        </li>
      );
    });
  };

  return (
    <div>
      <div>
        <div className="form-question">{label}</div>
        {required ? (
          ""
        ) : (
          <div
            style={{ fontSize: "14px", fontStyle: "italic", color: "#3C81F6" }}
          >
            optional
          </div>
        )}
      </div>
      {listView.length > 0 && (
        <div
          style={{
            marginTop: "8px",
            paddingBottom: "12px",
            fontSize: "14px",
            color: "rgb(94, 95, 100)",
          }}
        >
          <ul style={{ paddingLeft: "40px" }} className="list-disc list-inside">
            {createListView()}
          </ul>
        </div>
      )}
      <div className="form-space" />
      <div className="flex items-center">
        <div
          onClick={() => setSelect(1)}
          className={`hover:bg-blue-100 ${
            yesBool ? "bg-blue-100" : "bg-white"
          } flex items-center px-3 text-center text-sm justify-center transition duration-275 rounded-sm`}
          style={{
            border: yesBool
              ? "1px solid #3C81F6"
              : `2px solid rgb(240, 242, 255)`,
            height: "100px",
            color: yesBool && "#3C81F6",
            fontStyle: yesBool && "bold",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {yesOverride || "Yes"}
        </div>
        <div
          onClick={() => setSelect(0)}
          className={`ml-4 hover:bg-blue-100 ${
            noBool ? "bg-blue-100" : "bg-white"
          } flex items-center px-3 text-center text-sm justify-center transition duration-275 rounded-sm`}
          style={{
            border: noBool
              ? "1px solid #3C81F6"
              : `2px solid rgb(240, 242, 255)`,
            height: "100px",
            color: noBool && "#3C81F6",
            fontStyle: noBool && "bold",
            width: "100%",
            cursor: "pointer",
          }}
        >
          {noOverride || "No"}
        </div>
      </div>
    </div>
  );
}
