import React from "react";

export default function Avatar(props) {
  const { user, height, width, fontSize } = props;

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  return user ? (
    <div
      className="flex items-center justify-center"
      style={{
        color: "white",
        textAlign: "center",
        fontSize: fontSize || "40px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: "50%",
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        width: width || "70px",
        height: height || "70px",
        background: stringToHslColor(user?.firstName + user?.lastName, 80, 45),
      }}
    >
      {user?.firstName?.[0]}
    </div>
  ) : (
    ""
  );
}
