import React, { useState, useRef, useEffect } from "react";
import config from "../config";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

const debug = config.debug;

let base = debug ? config.debugURL : config.productionURL;

export default function FileUpload(props) {
  const {
    label,
    required,
    setFile,
    setFileName,
    multiple,
    file,
    jwt,
    fileName,
    setFileURL,
  } = props;

  const getJWT = () => {
    return {
      Authorization: `Bearer ${jwt}`,
    };
  };

  const onDropFile = (files) => {
    setFile(files.map((fileItem) => fileItem.file));
  };

  // Un-used for now
  const allowMultiple = multiple || false;

  return (
    <div>
      <div>
        <div className="form-question">{label}</div>
        {required ? (
          ""
        ) : (
          <div
            style={{ fontSize: "14px", fontStyle: "italic", color: "#3C81F6" }}
          >
            optional
          </div>
        )}
      </div>
      <div className="form-space" />
      <div>
        <FilePond
          server={{
            url: base,
            process: {
              url: "/user/upload-form-file",
              method: "POST",
              headers: getJWT(),
              withCredentials: false,
              onload: (response) => {
                const fileURL = JSON.parse(response).url;
                setFileURL(fileURL);
              },
              onerror: (response) => {
                console.log("error: ", response.data);
              },
              ondata: (formData) => {
                return formData;
              },
            },
          }}
          files={file}
          allowMultiple={false}
          allowReorder={false}
          maxFiles={1}
          name="files"
          dropOnPage
          onupdatefiles={(files) => {
            if (files.length === 0) {
              setFileURL("");
              setFileName("");
            } else {
              setFileName(files[0].filename);
            }
            onDropFile(files);
          }}
        />
      </div>
    </div>
  );
}
