import React, { useState, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import immutableLogo from "../images/immutable.svg";
import bg from "../images/bg.jpg";
import MobileMenu from "./mobileMenu";
import defi from "../images/defi.png";
import equity from "../images/equity.jpeg";
import hbar from "../images/hbar.png";
import btc from "../images/btc.png";
import eth from "../images/eth.png";

export default function Home() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [product, toggleProduct] = useState(false);
  const [resource, toggleResource] = useState(false);
  const [about, toggleAbout] = useState(false);
  const [showCookie, setShowCookie] = useState(false);

  const aboutRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (aboutRef.current && !aboutRef.current.contains(event.target)) {
        toggleAbout(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [aboutRef]);

  const resourceRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (resourceRef.current && !resourceRef.current.contains(event.target)) {
        toggleResource(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resourceRef]);

  const productRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (productRef.current && !productRef.current.contains(event.target)) {
        toggleProduct(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [productRef]);

  const mobileMenuRef = useRef(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setMobileMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuRef]);

  useEffect(() => {
    let cookieBool = localStorage.getItem("immutable-cookies");

    if (cookieBool) {
      setShowCookie(false);
    } else {
      setShowCookie(true);
    }
  }, []);

  return (
    <div className="bg-white">
      {showCookie && (
        <header>
          <div className="relative bg-blue-100">
            <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-3 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
              <nav
                className="text-xs md:flex space-x-10"
                style={{
                  fontSize: isMobile && "8px",
                  lineHeight: isMobile && "normal",
                }}
              >
                We use cookies to collect data to improve your experience. By
                continuing, you agree to our Cookie Policy.
              </nav>
              <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowCookie(false);
                    localStorage.setItem("immutable-cookies", true);
                  }}
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-black"
                >
                  Accept All
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
      <header>
        <div className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">immutable</span>
                <img className="h-8 w-auto sm:h-8" src={immutableLogo} alt="" />
              </a>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                onClick={() => setMobileMenu(!mobileMenu)}
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            {mobileMenu && <MobileMenu ref={mobileMenuRef} />}
            <nav className="hidden md:flex space-x-10">
              <div ref={productRef} className="relative">
                <button
                  onClick={() => toggleProduct(!product)}
                  type="button"
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-sm font-light uppercase hover:text-gray-900 focus:outline-none"
                >
                  <span>Investment Products</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                {product && (
                  <div class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div class="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <a
                        href="/#/bitcoin"
                        onClick={() => {
                          toggleProduct(false);
                        }}
                        className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                        role="menuitem"
                      >
                        <img
                          className="h-6 w-6 rounded-full mr-3"
                          src={btc}
                          alt=""
                        />
                        BTC Trust
                      </a>
                      <a
                        href="/#/hedera"
                        onClick={() => toggleProduct(false)}
                        className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                        role="menuitem"
                      >
                        <img
                          className="h-6 w-6 rounded-full mr-3"
                          src={hbar}
                          alt=""
                        />
                        HBAR Trust
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {!isMobile && (
                <React.Fragment>
                  <div ref={resourceRef} className="relative">
                    <button
                      onClick={() => toggleResource(!resource)}
                      type="button"
                      className="group bg-white rounded-md text-gray-500 inline-flex items-center text-sm font-light uppercase hover:text-gray-900 focus:outline-none"
                    >
                      <span>Resources</span>
                      <svg
                        className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    {resource && (
                      <div class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div class="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <a
                            href="/#/terms"
                            onClick={() => {
                              toggleResource(false);
                            }}
                            className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                            role="menuitem"
                          >
                            Terms and Conditions
                          </a>
                          <a
                            href="/#/privacy"
                            onClick={() => {
                              toggleResource(false);
                            }}
                            className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                            role="menuitem"
                          >
                            Privacy Policy
                          </a>
                          <a
                            href="/#/disclaimer"
                            onClick={() => {
                              toggleResource(false);
                            }}
                            className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                            role="menuitem"
                          >
                            Disclaimer
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div ref={aboutRef} className="relative">
                    <button
                      onClick={() => toggleAbout(!about)}
                      type="button"
                      className="group bg-white rounded-md text-gray-500 inline-flex items-center text-sm font-light uppercase hover:text-gray-900 focus:outline-none"
                    >
                      <span>About</span>
                      <svg
                        className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    {about && (
                      <div class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div class="rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <a
                            href="/#/faq"
                            onClick={() => {
                              toggleAbout(false);
                            }}
                            className="block px-4 py-2 text-sm font-light uppercase text-gray-700 hover:bg-gray-100 flex items-center justify-center"
                            role="menuitem"
                          >
                            FAQs
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="/#/sign-in"
                className="whitespace-nowrap text-sm font-light uppercase text-gray-500 hover:text-gray-900"
              >
                Sign in
              </a>
              <a
                href="/#/onboard"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-light uppercase text-white bg-gradient-to-r from-indigo-500 to-blue-500 hover:from-indigo-700 hover:to-blue-700"
              >
                Start Investing
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-white"></div>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={bg}
                  style={{ height: "100vh" }}
                  alt="People working on laptops"
                />
                <div
                  className="absolute inset-0 bg-gradient-to-r from-white to-blue-200"
                  style={{ mixBlendMode: "multiply" }}
                ></div>
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1
                  className="text-center py-28 text-4xl font-light sm:text-5xl lg:text-6xl"
                  style={{ letterSpacing: "0.3em" }}
                >
                  <span className="block text-black font-extrabold uppercase">
                    Immutable
                  </span>
                  <span
                    style={{ letterSpacing: "5px" }}
                    className="mt-2 block text-blue-400 uppercase"
                  >
                    Asset Management
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-white" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto pt-4 pb-8 px-4 sm:px-6 lg:pt-0 lg:px-8">
          <div className="mt-12 pt-8 md:flex md:items-center md:justify-center lg:mt-16">
            <div className="flex space-x-6 md:order-2">
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>

              <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>

              <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">Twitter</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>

              <a href="#" className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">GitHub</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {/* <div
          style={{ fontSize: "11px" }}
          className="text-gray-400 max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-24 lg:px-8"
        >
          <div style={{ fontSize: "18px" }}>
            <b>Important Information</b>
          </div>
          <br />
          Carefully consider each Product’s investment objectives, risk factors,
          fees and expenses before investing. This and other information can be
          found in each Product’s private placement memorandum, which may be
          obtained from Immutable Holdings and, for each Product registered with
          the SEC and/or listed on the OTC Markets, such Product’s annual report
          or information statement, which may be obtained by visiting the SEC’s
          website for Immutable Holdings Bitcoin Trust (Symbol: GBTC) and
          Immutable Holdings Ethereum Trust (Symbol: ETHE) or the OTC Markets
          website for Immutable Holdings Bitcoin Cash Trust (Symbol: BCHG),
          Immutable Holdings Ethereum Classic Trust (Symbol: ETCG), Immutable
          Holdings Litecoin Trust (Symbol: LTCN) and Immutable Holdings Digital
          Large Cap Fund (Symbol: GDLC). Reports on OTC Markets are not prepared
          in accordance with SEC requirements and may not contain all
          information that is useful for an informed investment decision. Read
          these documents carefully before investing.
          <br />
          <br />
          Investments in the Products are speculative investments that involve
          high degrees of risk, including a partial or total loss of invested
          funds. Immutable Holdings Products are not suitable for any investor
          that cannot afford loss of the entire investment. The shares of each
          Product are intended to reflect the price of the digital asset(s) held
          by such Product (based on digital asset(s) per share), less such
          Product’s expenses and other liabilities. Because each Product does
          not currently operate a redemption program, there can be no assurance
          that the value of such Product’s shares will reflect the value of the
          assets held by such Product, less such Product’s expenses and other
          liabilities, and the shares of such Product, if traded on any
          secondary market, may trade at a substantial premium over, or a
          substantial discount to, the value of the assets held by such Product,
          less such Product’s expenses and other liabilities, and such Product
          may be unable to meet its investment objective.
          <br />
          <br />
          This information should not be relied upon as research, investment
          advice, or a recommendation regarding any products, strategies, or any
          security in particular. This material is strictly for illustrative,
          educational, or informational purposes and is subject to change.
          <br />
          <br />
          The shares of each Product are not registered under the Securities Act
          of 1933 (the “Securities Act”), the Securities Exchange Act of 1934
          (except for Immutable Holdings Bitcoin Trust and Immutable Holdings
          Ethereum Trust), the Investment Company Act of 1940, or any state
          securities laws. The Products are offered in private placements
          pursuant to the exemption from registration provided by Rule 506(c)
          under Regulation D of the Securities Act and are only available to
          accredited investors. As a result, the shares of each Product are
          restricted and subject to significant limitations on resales and
          transfers. Potential investors in any Product should carefully
          consider the long-term nature of an investment in that Product prior
          to making an investment decision. The shares of certain Products are
          also publicly quoted on OTC Markets and shares that have become
          unrestricted in accordance with the rules and regulations of the SEC
          may be bought and sold throughout the day through any brokerage
          account.
          <br />
          <br />
          The Products are distributed by Genesis Global Trading, Inc. (Member
          FINRA/SIPC, MSRB Registered).
          <br />
          <br />© 2020 Immutable Holdings LLC. All rights reserved. The
          Immutable Holdings and Immutable Holdings INVESTMENTS logos, graphics,
          icons, trademarks, service marks and headers are registered and
          unregistered trademarks of Immutable Holdings LLC in the United
          States.
        </div> */}
      </footer>
    </div>
  );
}
